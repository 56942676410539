import { combineReducers } from 'redux'
import { CREDIT_SET_CREDIT_INFO, CREDIT_SET_BEFORE } from './actions'

const noOfTransactions = (state = null, action) => {
  switch (action.type) {
    case CREDIT_SET_CREDIT_INFO:
      return action.n
    default:
      return state
  }
}

const credit = (state = null, action) => {
  switch (action.type) {
    case CREDIT_SET_CREDIT_INFO:
      return action.credit
    default:
      return state
  }
}

const before = (state = null, action) => {
  switch (action.type) {
    case CREDIT_SET_BEFORE:
      return action.before
    default:
      return state
  }
}

export default combineReducers({
  noOfTransactions,
  credit,
  before
})
