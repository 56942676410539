/* global IntersectionObserver */
import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'

const debouncedUpdateIndex = debounce((index, callback) => callback(index), 200)

const elementIndices = {}

const useSwipeable = ({ wrapperRef, entriesRef }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // find the entry with the largest intersection ratio
        const activated = entries.reduce((max, entry) =>
          entry.intersectionRatio > max.intersectionRatio ? entry : max
        )
        if (activated.intersectionRatio > 0) {
          debouncedUpdateIndex(elementIndices[activated.target.getAttribute('id')], setActiveIndex)
        }
      },
      {
        root: wrapperRef.current,
        threshold: 0.5
      }
    )

    Object.values(entriesRef.current).forEach((entry, i) => {
      elementIndices[entry.getAttribute('id')] = i
      observer.observe(entry)
    })

    return () => {
      observer.disconnect()
    }
  }, [wrapperRef, entriesRef])

  const handleScrollClick = useCallback((element) => {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }, [])

  return { activeIndex, handleScrollClick }
}

export default useSwipeable
