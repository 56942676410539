import React, { useState, useEffect, createContext } from 'react'
const Context = createContext({})

// https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Testing_media_queries
const BreakpointProvider = ({ children, mediaQueries }) => {
  const [queryMatch, setQueryMatch] = useState(() => {
    const matches = {}
    if (!window || !window.matchMedia) return matches

    Object.keys(mediaQueries).forEach((media) => {
      matches[media] = window.matchMedia(mediaQueries[media]).matches
    })
    return matches
  })

  useEffect(() => {
    const mediaQueryLists = {}
    const queryKeys = Object.keys(mediaQueries)
    let isAttached = false

    const handleQueryListener = () => {
      const updatedMatches = queryKeys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches)
        return acc
      }, {})
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      isAttached = true
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
      queryKeys.forEach((media) => {
        mediaQueryLists[media] = window.matchMedia(mediaQueries[media])
        mediaQueryLists[media].addListener(handleQueryListener)
      })
    }

    return () => {
      if (isAttached) {
        queryKeys.forEach((media) => {
          mediaQueryLists[media].removeListener(handleQueryListener)
        })
      }
    }
  }, [mediaQueries])

  return <Context.Provider value={{ queryMatch }}>{children}</Context.Provider>
}

export { Context, BreakpointProvider }
