import { createSelector } from 'reselect'
import { getEntity } from '../entities/selectors/base'

export const currentUserIdentificationInformation = createSelector(
  [getEntity('UserIdentificationInformation')],
  (userIdentificaionInformation) =>
    userIdentificaionInformation
      .filter((information) => information.get('current'))
      .sortBy((information) => information.get('updatedAt'))
      .last()
)

export const blockPayout = createSelector(
  [currentUserIdentificationInformation],
  (currentUserIdentificationInformation) => {
    if (!currentUserIdentificationInformation) return false
    if (!currentUserIdentificationInformation.get('information')) return true
    return false
  }
)
