import i18n from 'i18next'
import { sizes, country as countryCommons, money, marketplace } from '@sellpy/commons'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { useCountry } from '../../../../common/hooks/useCountry'

export const SINGLE_CLICK_FILTERS = ['storageSite', 'saleType']

export const getRefinedItemLabels = ({ items, t }) =>
  items
    .map((item) =>
      i18n.exists(`search:${item}`) ? t(item) : sizes.getSimpleOutputSize(item) || item
    )
    .join(', ')

export const useFastDeliveryAvailable = () => {
  const userAddressCountry = useSelector((state) => state.user.parseUser?.get('address')?.country)
  const country = useCountry()

  const fastDeliveryStorageSitesForCountry = countryCommons.getFastDeliveryStorageSitesForCountry(
    userAddressCountry || country
  )
  const fastDeliveryStorageSitesForRegion = countryCommons.getFastDeliveryStorageSitesForCountry(
    process.env.REGION
  )
  return Boolean(
    fastDeliveryStorageSitesForCountry.length || fastDeliveryStorageSitesForRegion.length
  )
}

export const useFastDeliveryStorageSites = () => {
  const userAddressCountry = useSelector((state) => state.user.parseUser?.get('address')?.country)
  const country = useCountry()
  const fastDeliveryCountry =
    process.env.REGION === 'EU' ? userAddressCountry || country : process.env.REGION

  return countryCommons.getFastDeliveryStorageSitesForCountry(fastDeliveryCountry)
}

export const getActiveSizeProfileFromSizes = ({ sizeProfiles, sizes }) => {
  if (!sizeProfiles.isEmpty()) {
    return sizeProfiles.find((sizeProfile) =>
      isEqual(
        sortBy(sizeProfile.getIn(['searchState', 'refinementList', 'sizes']).toJS()),
        sortBy(sizes.toJS())
      )
    )
  }
}

export const getActiveSizeProfilesFromSizes = ({ sizeProfiles, sizes }) =>
  sizes &&
  sizeProfiles
    .filter((profile) =>
      profile
        .getIn(['searchState', 'refinementList', 'sizes'])
        .toArray()
        .every((profileSize) => !!sizes.toArray().includes(profileSize))
    )
    .map((profile) => profile.get('objectId'))

export const getFormattedCount = (count) => {
  if (count == null) return null
  const countLength = count.toString().length
  const formattedCount = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: countLength <= 4 ? 4 : countLength - 3
  }).format(count)

  return formattedCount + (countLength > 4 ? '+' : '')
}

export const priceRangeProps = {
  toDisplayValue: (value) =>
    money.toBaseUnit({
      amount: value,
      currency: marketplace.CURRENCY[process.env.REGION]
    }).amount,
  fromDisplayValue: (value) =>
    money.toSubUnit({
      amount: value,
      currency: marketplace.CURRENCY[process.env.REGION]
    }).amount,
  shouldShowRangeChips: true
}

export const metricLengthRangeProps = {
  toDisplayValue: (value) => value,
  fromDisplayValue: (value) => value
}
