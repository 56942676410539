import { DateTime } from 'luxon'
import qs from 'qs'
import { LOCALIZED_ELASTIC_ITEM_INDEX } from '../../common/region/config'
import runRequest from './runRequest'

// If we pass an empty array to elastic we get all items.
// So instead we pass a string that we now will not match any of the field values.
export const NO_RESULT = 'NO_RESULT'

export const getFilterByUserElastic = (filters) => {
  return filters?.filter(
    (filter) =>
      ![
        'user',
        'objectId',
        'order.facet',
        'order.user',
        'order.status',
        'hasSucceeding',
        'itemClaim.reason',
        'itemClaim.status',
        'p2p',
        'itemStatus',
        'sellerShareReduction'
      ].includes(filter.field)
  )
}

export const pathToEntity = (location) => {
  const path = location.pathname.split('/')
  return !path[3] ? { path: path[2] } : { path: path[2], id: path[3] }
}

export const dateFromId = (id) => {
  if (!id) return
  const timeStamp = id.split('-')[1]
  if (!timeStamp) return
  return DateTime.fromSeconds(Number(timeStamp)).toFormat('dd LLLL yyyy')
}

export const stringifySearchState = (state) => {
  return (
    '?' +
    qs.stringify({ resultsPerPage: 100, ...state, filters: getFilterByUserElastic(state.filters) })
  )
}

const _pushUrl = ({ state, navigate }) => {
  if (stringifySearchState(state) !== window.location.search) {
    navigate(window.location.pathname + stringifySearchState(state), { replace: true })
  }
}

export const search = ({ state, globalFilters, searchConfig, navigate }) => {
  _pushUrl({ state, navigate })
  return runRequest({
    index: LOCALIZED_ELASTIC_ITEM_INDEX,
    state: {
      ...state,
      filters: [...state.filters, ...globalFilters]
    },
    searchConfig
  }).catch((e) => console.warn(e))
}

export const parseFilters = () => qs.parse(window.location.search).filters || []
