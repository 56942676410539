import config from 'config'
import { locale } from '../region/locale'
import { activeAbTests, getABTestFraction } from '../analytics/abTest'
import { getAlgoliaClient } from '../lib/algolia'
import { getApolloClient } from '../../client/apollo/apolloClientSingleton'
import GET_USER_PERSONALISATION_VALUES from '../../client/landing/queries/getUserPersonalisationValues.gql'
import { hashStringSHA256, normalizePhoneNumber } from './utils'

const createDataLayerEvent = (event) => {
  const personalisationQuery = getApolloClient().readQuery({
    query: GET_USER_PERSONALISATION_VALUES,
    variables: { locale }
  })

  const personalisedBaseCategory = personalisationQuery
    ? personalisationQuery.getUserPersonalisationValues.baseCategoryScores[0]?.baseCategory ??
      'not assigned'
    : undefined

  const eventWithData = Object.assign(event, {
    ab_tests: activeAbTests(),
    ga4_id: config.ga4Id[process.env.REGION],
    region: process.env.REGION,
    clientAbTestFractionGroup: parseFloat(getABTestFraction()).toFixed(4),
    aw_id: config.googleAdwordsId[process.env.REGION],
    algolia_app_id: getAlgoliaClient().appId,
    client_current_commit: process.env.COMMIT_HASH?.slice(0, 8),
    user_base_category: personalisedBaseCategory?.toLowerCase()
  })
  return eventWithData
}

const dataLayerPush = (event) => {
  if (typeof window === 'undefined') {
    return
  }

  const dataLayerName = 'gtmDataLayer'

  const eventWithData = createDataLayerEvent(event)

  window[dataLayerName] && window[dataLayerName].push(eventWithData)
}

const trackPermissionAnalytics = ({ permissionValue }) => {
  dataLayerPush({
    event: 'permission_analytics',
    permission_analytics: permissionValue
  })
}

const trackPermissionExternalMarketing = ({ permissionValue }) => {
  dataLayerPush({
    event: 'permission_external_marketing',
    permission_external_marketing: permissionValue,
    allow_ad_personalization_signals: permissionValue
  })
}

const trackSetUserId = ({ id }) => {
  hashStringSHA256(id).then((hashedId) => {
    dataLayerPush({
      user_id: id,
      user_id_hsh: hashedId
    })
  })
}

const trackUnsetUserId = () => {
  dataLayerPush({
    user_id: undefined
  })
}

const trackSetUserEmail = ({ email }) => {
  hashStringSHA256(email).then((hashedEmail) => {
    dataLayerPush({
      email_hsh: hashedEmail,
      sha256_hash_hex_user_email: hashedEmail
    })
  })
}

const trackUnsetUserEmail = () => {
  dataLayerPush({
    email_hsh: undefined,
    sha256_hash_hex_user_email: undefined
  })
}

const trackSetUserPhone = ({ phone }) => {
  Promise.all([hashStringSHA256(normalizePhoneNumber(phone)), hashStringSHA256(phone)]).then(
    (hashedPhones) => {
      dataLayerPush({
        phone_1_hsh: hashedPhones[0],
        phone_2_hsh: hashedPhones[1]
      })
    }
  )
}

const trackUnsetUserPhone = () => {
  dataLayerPush({
    phone_1_hsh: undefined,
    phone_2_hsh: undefined
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4?hl=en#measure_viewsimpressions_of_productitem_details
const trackItemView = ({ name, id, price, currency, brand, type }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: name,
          item_id: id,
          price: price,
          currency: currency,
          item_brand: brand,
          item_category: type
        }
      ]
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4?hl=en#measure_viewsimpressions_of_productitem_details
const trackCartView = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_cart',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4?hl=en#measure_productitem_list_clicks
const trackItemClick = ({ id, price, currency, brand, type, pdpType }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          item_id: id,
          item_brand: brand,
          item_category: type,
          price: price,
          currency: currency,
          pdpType: pdpType
        }
      ]
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_promotion_clicks
const trackPromotionClick = ({ promotionName, segment, location, position, target, ...data }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'select_promotion',
    ecommerce: {
      items: [
        {
          promotion_name: promotionName,
          creative_slot: segment,
          location_id: location
        }
      ]
    },
    position,
    target,
    ...data
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_additions_or_removals_from_a_shopping_cart
const trackAddToCart = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_to_cart',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_additions_or_removals_from_a_shopping_cart
const trackRemoveFromCart = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'remove_from_cart',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_additions_or_removals_from_a_shopping_cart
const trackGiftCardAddToCart = ({ amount, currency }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: 'gift_card',
          price: amount,
          currency: currency
        }
      ]
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_additions_or_removals_from_a_shopping_cart
const trackGiftCardRemoveFromCart = ({ amount, currency }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          item_name: 'gift_card',
          price: amount,
          currency: currency
        }
      ]
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_a_checkout
const trackBeginCheckout = ({ items, promoCode }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'begin_checkout',
    ecommerce: {
      items: items.map((item) => ({
        item_name: item.name,
        item_id: item.id,
        price: item.price,
        currency: item.currency,
        item_category: item.type,
        coupon: promoCode
      }))
    }
  })
}

const trackBeginCheckoutV2 = ({ checkoutType, loginCheck, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'begin_checkout',
    checkout_type: checkoutType,
    login_check: loginCheck,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutViewAddEmail = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_add_email_info',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutAddEmailInfo = ({ checkoutType, method, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_email_info',
    checkout_type: checkoutType,
    email_method: method,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutAddPromoCode = ({ checkoutType, promoCode, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_coupon',
    checkout_type: checkoutType,
    ecommerce: {
      user_input: promoCode,
      input_accepted: true,
      items
    }
  })
}

const trackCheckoutViewAddAddress = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_add_shipping_address',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutAddShippingAddress = ({ checkoutType, addressMethod, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_shipping_address',
    checkout_type: checkoutType,
    ecommerce: {
      address_method: addressMethod,
      items
    }
  })
}

const trackCheckoutViewReviewOrder = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_review_order',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutReviewOrder = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'review_order',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutViewAddShipping = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_add_shipping_info',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

const trackCheckoutAddShippingInfo = ({
  checkoutType,
  items,
  shippingCompany,
  shippingTier,
  shippingPrice,
  currency
}) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_shipping_info',
    checkout_type: checkoutType,
    ecommerce: {
      items,
      shipping_company: shippingCompany,
      shipping_tier: shippingTier,
      shipping: shippingPrice,
      currency
    }
  })
}

const trackCheckoutAddP2PShippingInfo = ({ checkoutType, shippingTier }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_p2p_shipping_info',
    checkout_type: checkoutType,
    ecommerce: {
      shipping_tier: shippingTier
    }
  })
}

const trackCheckoutViewPaymentInfo = ({ checkoutType, items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'view_add_payment_info',
    checkout_type: checkoutType,
    ecommerce: {
      items
    }
  })
}

// https://developers.google.com/analytics/devguides/collection/protocol/ga4/reference/events#add_payment_info
const trackCheckoutAddPaymentInfo = ({ checkoutType, items, paymentMethod }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_payment_info',
    checkout_type: checkoutType,
    ecommerce: {
      items,
      payment_type: paymentMethod
    }
  })
}

// https://developers.google.com/tag-manager/ecommerce-ga4#measure_purchases
const trackPurchase = ({
  items,
  paymentId,
  price,
  currency,
  tax,
  shipping,
  coupon,
  marketOrderCount,
  purchaseType,
  stringValue,
  itemIds,
  orderId
}) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'purchase',
    ecommerce: {
      transaction_id: paymentId,
      value: price,
      tax,
      shipping,
      currency,
      coupon,
      market_order_count: marketOrderCount,
      items: items?.map((item) => ({
        item_name: item.name,
        item_id: item.id,
        price: item.price,
        item_brand: item.brand,
        item_category: item.type,
        quantity: 1
      })),
      quantity: items?.length
    },
    purchase_type: purchaseType,
    string_value: stringValue,
    line_items: itemIds?.map((itemId) => ({ product_id: itemId })), // This is for the pinterst pixel
    order_id: orderId
  })
}

const trackPurchaseV2 = ({
  checkoutType,
  currency,
  value,
  coupon,
  items,
  shipping,
  marketOrderCount,
  latestPurchaseDate,
  purchaseType,
  itemIds,
  paymentId
}) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'purchase',
    checkout_type: checkoutType,
    ecommerce: {
      currency,
      value,
      ...(coupon && { coupon }),
      shipping,
      items,
      market_order_count: marketOrderCount.toString(),
      latest_purchase_date: latestPurchaseDate?.toISOString()
    },
    purchase_type: purchaseType,
    string_value: value.toString(),
    line_items: itemIds?.map((itemId) => ({ product_id: itemId })), // This is for the pinterst pixel
    item_ids: itemIds,
    transaction_id: paymentId
  })
}

const trackRefund = ({ itemId, paymentId }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'refund',
    ecommerce: {
      transation_id: paymentId,
      items: [
        {
          item_id: itemId
        }
      ]
    }
  })
}

const trackFakeDoorClick = ({ userId, type }) => {
  dataLayerPush({
    event: 'fake_door_click',
    type,
    user_id: userId
  })
}

// https://developers.google.com/gtagjs/reference/ga4-events#login
const trackLogin = ({ method }) => {
  dataLayerPush({
    event: 'login',
    method
  })
}

// https://developers.google.com/gtagjs/reference/ga4-events#login
const trackSignup = ({ method }) => {
  dataLayerPush({
    event: 'sign_up',
    method
  })
}

const trackGiftCardRedeemed = ({ id }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'gift_card_redeemed',
    ecommerce: {
      items: [
        {
          item_id: id
        }
      ]
    }
  })
}

const trackMenuNavigation = ({ label, action }) => {
  dataLayerPush({
    event: 'menu_navigation',
    navigation_value: label,
    navigation_action: action
  })
}

const trackHomeTabNavigation = ({ tab }) => {
  dataLayerPush({
    event: 'homeTabClick',
    tab
  })
}

const trackMapDrawer = ({ action, pickupAvailable }) => {
  dataLayerPush({
    event: 'map_drawer',
    action,
    pickupAvailable
  })
}

const trackOrderPickupButton = ({ location }) => {
  dataLayerPush({
    event: 'order_pickup_button',
    location
  })
}

const trackDropoffButton = () => {
  dataLayerPush({
    event: 'dropoff_button'
  })
}

const trackCommencePreparedItemSale = () => {
  dataLayerPush({
    event: 'commence_prepared_item_sale'
  })
}

const trackExtendPricingPeriodModalOpen = () => {
  dataLayerPush({
    event: 'extend_pricing_period_modal_open'
  })
}

const trackSetNewPricingModalOpen = () => {
  dataLayerPush({
    event: 'set_new_pricing_modal_open'
  })
}

const trackBagOrderButton = ({ location }) => {
  dataLayerPush({
    event: 'bag_order_button',
    location
  })
}

const trackContainerOrderHistoryButton = ({ location }) => {
  dataLayerPush({
    event: 'container_order_history_button',
    location
  })
}

/**
 * Tracking for the autocomplete suggestions and serachbox
 *
 * @param {string} searchTerm The query that the customer has wrote
 * @param {string} searchSource There are four type of search facets / indexs depending which recommendation the customer clicked on. Either category, brand, suggestedText (querysuggestions), text (plain search).
 */
const trackSearch = ({ searchTerm, searchSource }) => {
  dataLayerPush({
    event: 'search',
    search_term: searchTerm,
    search_source: searchSource
  })
}

/**
 * Tracking for the CLIP search
 * @param {string} searchTerm The query that the customer has wrote
 */
const trackCLIPSearch = ({ searchTerm }) => {
  dataLayerPush({
    event: 'search_clip',
    search_term: searchTerm
  })
}

const trackPickupOrder = ({ nrOfBags, page, title }) => {
  dataLayerPush({
    event: 'pickup_order',
    number_of_bags: nrOfBags,
    page,
    title
  })
}

const trackBagRating = ({ score, comment, improvementArea }) => {
  dataLayerPush({
    event: 'rate_bag',
    score,
    comment,
    improvement_area: improvementArea
  })
}

const trackP2pSaleInitiated = ({ itemId, value }) => {
  dataLayerPush({
    event: 'p2p_sale_start_initiated',
    item_id: itemId,
    p2p_listings: value
  })
}

const trackP2pSaleCompleted = ({ itemId, value }) => {
  dataLayerPush({
    event: 'p2p_sale_start_completed',
    item_id: itemId,
    p2p_listings: value
  })
}

const trackAddToWishList = ({ items }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'add_to_wishlist',
    ecommerce: {
      items
    }
  })
}

const trackAddTrackedSearch = ({ name }) => {
  dataLayerPush({
    event: 'add_tracked_search',
    tracked_search_name: name
  })
}

const trackReevaluationRequestStart = ({ itemId }) => {
  dataLayerPush({
    event: 'reevaluation_request_started',
    item_id: itemId
  })
}

const trackReevaluationRequestComplete = ({ itemId, motivation }) => {
  dataLayerPush({
    event: 'reevaluation_request_completed',
    item_id: itemId,
    motivation
  })
}

const trackReferralLinkCopied = ({ referrer }) => {
  dataLayerPush({
    event: 'referral_link_copied',
    referrer
  })
}

const trackAccordionClick = ({ accordionId }) => {
  dataLayerPush({
    event: 'accordion_click',
    accordion_id: accordionId
  })
}

const trackClickTile = ({ baseCategory, sliderType, tileName, sliderTitle, position, ...data }) => {
  dataLayerPush({
    event: 'tile_click',
    baseCategory,
    slider_type: sliderType,
    tileName,
    slider_title: sliderTitle,
    position,
    ...data
  })
}

const trackClickSliderItem = ({ index, itemId, sliderId, sliderType, totalCount, position }) => {
  dataLayerPush({
    event: 'slider_item_click',
    item_index: index,
    item_id: itemId,
    slider_id: sliderId,
    slider_type: sliderType,
    total_count: totalCount,
    position
  })
}

const trackEstimateSellabilityCalculation = ({ brand, type }) => {
  dataLayerPush({
    event: 'estimate_sellability_calculation',
    brand,
    type
  })
}

const trackItemPricingOpenModal = () => {
  dataLayerPush({
    event: 'item_pricing_open_modal'
  })
}

const trackSellpyFirstCheckoutSignup = () => {
  dataLayerPush({
    event: 'first_in_shipping_page'
  })
}

const trackItemPricingSelectedLength = ({ days, daysRemaining, price }) => {
  dataLayerPush({
    event: 'item_pricing_length_selected',
    days,
    daysRemaining,
    price
  })
}

const trackCreateSearchProfile = () => {
  dataLayerPush({
    event: 'create_search_profile'
  })
}

const trackCompleteSearchProfile = () => {
  dataLayerPush({
    event: 'complete_search_profile'
  })
}

const trackApplySearchProfile = () => {
  dataLayerPush({
    event: 'apply_search_profile'
  })
}

const trackNotificationGroupClick = ({ type, count }) => {
  dataLayerPush({
    event: 'notification_group_click',
    type,
    count
  })
}

const trackNotificationClick = ({ type, inGroup }) => {
  dataLayerPush({
    event: 'notification_click',
    type,
    in_group: Boolean(inGroup)
  })
}

const trackImageZoomClick = () => {
  dataLayerPush({
    event: 'imageZoom_click'
  })
}

const trackFreeBagInCart = ({ available, checked }) => {
  dataLayerPush({
    event: 'free_bag_in_cart',
    available,
    checked
  })
}

const trackPlpPromotionalTile = ({ action, name }) => {
  dataLayerPush({
    event: 'plp_promotional_tile',
    name,
    action
  })
}

const trackNoStreetNumberPopup = () => {
  dataLayerPush({
    event: 'street_number_modal_opened'
  })
}

const trackHitsViewed = ({
  algoliaIndex,
  queryId,
  algoliaSearchHits,
  abTestID,
  abTestVariantID
}) => {
  dataLayerPush({
    event: 'hits_viewed',
    algolia_index: algoliaIndex,
    query_id: queryId,
    algolia_search_hits: algoliaSearchHits,
    algolia_test_id: abTestID,
    algolia_test_variant: abTestVariantID
  })
}

const trackFulfillmentP2p = ({ action }) => {
  dataLayerPush({
    event: 'fulfillment_p2p',
    action
  })
}

const trackShareMyStore = ({ pathname }) => {
  dataLayerPush({
    event: 'share_my_store_click',
    path_name: pathname
  })
}

const trackSimilarBrandsBannerClick = ({ brand }) => {
  dataLayerPush({
    event: 'add_similar_brands',
    brand
  })
}

const trackApproveAll = ({ action }) => {
  dataLayerPush({
    event: 'approve_all',
    action
  })
}

const trackRateSaleContactCHTClick = ({ type, improvementArea, score, comment }) => {
  dataLayerPush({
    event: 'rate_sale_contact_cht_click',
    rating_type: type,
    rating_improvement_area: improvementArea,
    rating_score: score,
    rating_comment: comment
  })
}

const trackItemEditSubmit = ({ itemId, changes }) => {
  const previousEvents = window.gtmDataLayer?.filter(({ event }) => event === 'item_edit_submit')
  const dataLayerFlush = {}
  if (previousEvents.length > 0) {
    const lastEvent = previousEvents.pop()
    Object.keys(lastEvent).forEach((key) => {
      if (key.includes('_change')) {
        dataLayerFlush[key] = undefined
      }
    })
  }
  dataLayerPush(dataLayerFlush)
  dataLayerPush({
    event: 'item_edit_submit',
    item_id: itemId,
    ...changes
  })
}

const trackRateClosedSaleButtonClickFromSalesOverview = ({ saleId }) => {
  dataLayerPush({
    event: 'rate_closed_sale_button_click_from_sales_overview',
    saleId
  })
}

const trackSimilarImageSearchClick = () => {
  dataLayerPush({
    event: 'click_similar_image_searchclick'
  })
}

const trackAwaitingApprovalListButtonClickFromSalesOverview = () => {
  dataLayerPush({
    event: 'awaiting_approval_list_button_click_from_sales_overview'
  })
}

const trackEndCheckoutClick = (data) => {
  dataLayerPush({
    event: 'end_checkout_click',
    ...data
  })
}

const trackViewsSustainability = () => {
  dataLayerPush({
    event: 'view_sustainability'
  })
}

const trackRequestChangesOpenModalClick = ({ location }) => {
  dataLayerPush({
    event: 'request_changes_open_modal_click',
    location
  })
}

const trackViewFavoriteModal = () => {
  dataLayerPush({
    event: 'view_favorite_modal'
  })
}

const trackSalesScheduleModal = () => {
  dataLayerPush({
    event: 'open_modal_click',
    modal_name: 'seller_item_sales_schedule'
  })
}

const trackDAC7OpenInfo = () => {
  dataLayerPush({
    event: 'dac7_openInfo'
  })
}
const trackDAC7OpenForm = () => {
  dataLayerPush({
    event: 'dac7_openForm'
  })
}
const trackDAC7Completed = () => {
  dataLayerPush({
    event: 'dac7_completed'
  })
}

const trackDiscoverMoreClicks = ({ type }) => {
  dataLayerPush({
    event: 'discovermore_click',
    type
  })
}

const trackImageBrightnessAdjustment = ({ brightness, itemId }) => {
  dataLayerPush({ ecommerce: undefined })
  dataLayerPush({
    event: 'click_image_brightness',
    brightness,
    ecommerce: {
      items: [
        {
          item_id: itemId
        }
      ]
    }
  })
}

const trackShowMoreCategoryBasedRecommendations = ({
  itemListId,
  itemListName,
  visibleItemIds
}) => {
  dataLayerPush({
    event: 'show_more',
    item_list_id: itemListId,
    item_list_name: itemListName,
    visible_item_ids: visibleItemIds
  })
}

const trackClickMoreButton = ({ buttonType }) => {
  dataLayerPush({
    event: 'click_more_button',
    button_type: buttonType
  })
}

const trackToggleNegativeFilter = ({ attribute }) => {
  dataLayerPush({
    event: 'click_negative_filter',
    attribute: attribute
  })
}

const trackPDPConditionChip = () => {
  dataLayerPush({
    event: 'chip_click',
    chip_name: 'pdp_condition'
  })
}

const trackPDPDefectImageChip = () => {
  dataLayerPush({
    event: 'chip_click',
    chip_name: 'defect_image'
  })
}

export const DataLayer = {
  trackPermissionAnalytics,
  trackPermissionExternalMarketing,
  trackSetUserId,
  trackUnsetUserId,
  trackItemView,
  trackCartView,
  trackItemClick,
  trackAddToCart,
  trackRemoveFromCart,
  trackPromotionClick,
  trackMenuNavigation,
  trackHomeTabNavigation,
  trackMapDrawer,
  trackGiftCardAddToCart,
  trackGiftCardRemoveFromCart,
  trackBeginCheckout,
  trackBeginCheckoutV2,
  trackCheckoutViewAddEmail,
  trackCheckoutAddEmailInfo,
  trackCheckoutAddPromoCode,
  trackCheckoutViewAddAddress,
  trackCheckoutAddShippingAddress,
  trackCheckoutViewReviewOrder,
  trackCheckoutReviewOrder,
  trackCheckoutViewAddShipping,
  trackCheckoutAddShippingInfo,
  trackCheckoutAddP2PShippingInfo,
  trackCheckoutViewPaymentInfo,
  trackCheckoutAddPaymentInfo,
  trackPurchase,
  trackPurchaseV2,
  trackRefund,
  trackFakeDoorClick,
  trackGiftCardRedeemed,
  trackLogin,
  trackSignup,
  trackSearch,
  trackCLIPSearch,
  trackPickupOrder,
  trackBagRating,
  trackP2pSaleInitiated,
  trackP2pSaleCompleted,
  trackAddToWishList,
  trackAddTrackedSearch,
  trackReevaluationRequestStart,
  trackReevaluationRequestComplete,
  trackReferralLinkCopied,
  trackClickTile,
  trackSetUserEmail,
  trackUnsetUserEmail,
  trackSetUserPhone,
  trackUnsetUserPhone,
  trackClickSliderItem,
  trackEstimateSellabilityCalculation,
  trackItemPricingOpenModal,
  trackSellpyFirstCheckoutSignup,
  trackItemPricingSelectedLength,
  trackCreateSearchProfile,
  trackCompleteSearchProfile,
  trackApplySearchProfile,
  trackNotificationGroupClick,
  trackNotificationClick,
  trackImageZoomClick,
  trackFreeBagInCart,
  trackPlpPromotionalTile,
  trackNoStreetNumberPopup,
  trackHitsViewed,
  trackOrderPickupButton,
  trackDropoffButton,
  trackBagOrderButton,
  trackContainerOrderHistoryButton,
  trackFulfillmentP2p,
  trackAccordionClick,
  trackSimilarBrandsBannerClick,
  trackCommencePreparedItemSale,
  trackExtendPricingPeriodModalOpen,
  trackSetNewPricingModalOpen,
  trackShareMyStore,
  trackApproveAll,
  trackRateSaleContactCHTClick,
  trackItemEditSubmit,
  trackRateClosedSaleButtonClickFromSalesOverview,
  trackSimilarImageSearchClick,
  trackEndCheckoutClick,
  trackAwaitingApprovalListButtonClickFromSalesOverview,
  trackViewsSustainability,
  trackRequestChangesOpenModalClick,
  trackViewFavoriteModal,
  trackSalesScheduleModal,
  trackDAC7OpenInfo,
  trackDAC7OpenForm,
  trackDAC7Completed,
  trackDiscoverMoreClicks,
  trackImageBrightnessAdjustment,
  trackClickMoreButton,
  createDataLayerEvent,
  trackToggleNegativeFilter,
  trackPDPConditionChip,
  trackPDPDefectImageChip,
  trackShowMoreCategoryBasedRecommendations
}
