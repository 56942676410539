import React, { memo } from 'react'
import PropTypes from 'prop-types'

const VARIANT_WIDTHS = {
  narrow: '80rem',
  normal: '100rem'
}

export const CENTERED_COLUMN_VARIANTS = {
  NARROW: 'narrow',
  NORMAL: 'normal'
}

const LayoutCenteredColumn = ({ variant = CENTERED_COLUMN_VARIANTS.NORMAL, children }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%', maxWidth: VARIANT_WIDTHS[variant] }}>{children}</div>
    </div>
  )
}

LayoutCenteredColumn.propTypes = {
  variant: PropTypes.oneOf(Object.values(CENTERED_COLUMN_VARIANTS))
}

export default memo(LayoutCenteredColumn)
