import Parse from 'parse'
import { cacheEntities } from '../entities/actions'

export const getNotifications = () => async (dispatch) => {
  const notifications = await Parse.Cloud.run('getActiveNotificationsForUser')
  dispatch(cacheEntities(notifications))
}

export const setInteractedAtForNotification = ({ notificationId }) => async (dispatch) => {
  return Parse.Cloud.run('setInteractedAtForNotification', { notificationId }).then(
    (notification) => dispatch(cacheEntities(notification))
  )
}

export const setInteractedAtForNotificationsInGroup = ({ notificationIds }) => async (dispatch) => {
  return Parse.Cloud.run('setInteractedAtForNotificationsInGroup', { notificationIds }).then(
    (notifications) => dispatch(cacheEntities(notifications))
  )
}

export const createTestNotification = ({ type, localeOverride }) => (dispatch) => {
  return Parse.Cloud.run('createTestNotification', { type, localeOverride }).then((notification) =>
    dispatch(cacheEntities(notification))
  )
}
