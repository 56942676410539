import { useLocation } from 'react-router-dom'
import { parse } from 'qs'

/**
 * This is taken from an example in the react router docs
 * https://reactrouter.com/web/example/query-parameters
 */
const useQuery = () => parse(useLocation().search, { ignoreQueryPrefix: true })

export default useQuery
