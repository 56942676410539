import { marketplace } from '@sellpy/commons'
import i18n from 'i18next'

let lang = process.env.LOCALE_OVERRIDE || marketplace.LOCALE[process.env.REGION]

const toggleTranslationKeys = () => {
  lang =
    lang === marketplace.LOCALE[process.env.REGION]
      ? 'cimode'
      : marketplace.LOCALE[process.env.REGION]

  i18n.changeLanguage(lang)
}

const keysDown = new Set()

const makeKeylogger = (length) => {
  let keylog = ''
  return {
    add: (code) => {
      if (code.startsWith('Key') && code.length === 4) {
        keylog += code[3]
        keylog = keylog.slice(-length)
      }
      return keylog
    },
    reset: () => (keylog = '')
  }
}

const changeLanguageIfValid = (latestKeys) => {
  const validLocale = Object.values(marketplace.LOCALE).find(
    (locale) => locale === latestKeys.toLowerCase()
  )
  if (validLocale) {
    console.log('Switching locale to:', validLocale)
    i18n.changeLanguage(validLocale)
  }
}

export const initializeToggleTranslationShortcut = () => {
  const keylogger = makeKeylogger(2)
  const handleKeyDown = (event) => {
    keysDown.add(event.code)
    // Alt + T to toggle translation keys
    if (keysDown.has('AltLeft') && keysDown.has('KeyT')) toggleTranslationKeys()

    // Alt + 2 letter key locale combination to switch language
    if (keysDown.has('AltLeft')) changeLanguageIfValid(keylogger.add(event.code))
  }

  const handleKeyUp = (event) => {
    keysDown.delete(event.code)
    if (event.code === 'AltLeft') keylogger.reset()
  }

  window.addEventListener('keydown', handleKeyDown)
  window.addEventListener('keyup', handleKeyUp)
}
