import React from 'react'
import styled from 'styled-components'
import { Chip, H7, ICONS, SlabHeader } from '@sellpy/design-system-react-web'
import { Link } from 'react-router-dom'
import { mediaQueries } from '../../../../uiComponents/mediaQueries'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 32px;
  ${mediaQueries.tablet} {
    margin: 0 0 0 16px;
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  flex: 1;
  ${mediaQueries.tablet} {
    flex: 0;
  }
`

const ChipsColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`

const MultiColumnChipBanner = ({ title, subTitle, columns, readMoreLink, readMoreText }) => {
  if (!columns?.length) return null
  return (
    <section>
      <SlabHeader
        headline={title}
        body={subTitle}
        readMoreLink={readMoreLink}
        readMoreText={readMoreText}
      />
      <ContentWrapper>
        {columns.map((column, index) => (
          <Column key={column.title + index}>
            {columns.length > 1 && (
              <H7 noMargin design='h7' as='h3'>
                {column.title}
              </H7>
            )}
            <ChipsColumn>
              {column.chips.map((chip, index) => (
                <Link key={chip.title + index} to={chip.link}>
                  <Chip
                    title={chip.title}
                    variant={'outlined'}
                    rightIcon={ICONS.CHEVRON_RIGHT}
                    onClick={() => chip.onClick(chip.title)}
                  />
                </Link>
              ))}
            </ChipsColumn>
          </Column>
        ))}
      </ContentWrapper>
    </section>
  )
}

export default MultiColumnChipBanner
