import { useQuery } from '@apollo/client'
import Parse from 'parse'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Set } from 'immutable'
import { fromConnection } from '../../../apollo/utils'
import { getAlgoliaItems } from '../../../../common/items/actions'
import { mapToAlgoliaItems } from '../../../../common/marketLanding/selectors'
import getItemsPreviouslyInCart from './previouslyInCart.gql'

const MAX_AGE_IN_MONTHS = 4

export const getOldestDateForPreviouslyInCart = () => {
  const oldestDate = new Date()
  oldestDate.setMonth(oldestDate.getMonth() - MAX_AGE_IN_MONTHS)
  oldestDate.setHours(0, 0, 0, 0)
  return oldestDate
}

export const getPreviouslyInCartItemIds = (data) => {
  const itemReservations = fromConnection(data?.itemReservations)
  if (itemReservations.length === 0) return []

  const previouslyInCartCurrentlyFreshIds = itemReservations
    .filter(({ status }) => status === 'fresh')
    .map(({ item }) => item?.objectId)

  const previouslyInCartItemIds = itemReservations
    .map(({ item }) => item?.objectId)
    .filter(
      (objectId, index, array) =>
        array.indexOf(objectId) === index && !previouslyInCartCurrentlyFreshIds.includes(objectId)
    )

  return previouslyInCartItemIds
}

export const usePreviouslyInCart = () => {
  const user = Parse.User.current()
  const dispatch = useDispatch()
  const { data } = useQuery(getItemsPreviouslyInCart, {
    variables: {
      userId: user?.id,
      oldestDate: getOldestDateForPreviouslyInCart()
    },
    skip: !user
  })

  const itemIds = getPreviouslyInCartItemIds(data)

  const items = useSelector((state) => mapToAlgoliaItems(itemIds, state.items.algoliaItems))

  useEffect(() => {
    if (itemIds.length > 0) dispatch(getAlgoliaItems(Set(itemIds)))
  }, [dispatch, itemIds])

  return {
    itemIds,
    items
  }
}
