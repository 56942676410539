import React, { useEffect, useState } from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ICONS, Icon, P } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../mediaQueries.js'

export const TOAST_TYPE = Object.freeze({
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  CUSTOM: 'custom'
})

let translateXAxis = '40rem'
let touchDistance = 0
let touchStart = 0
let startWidth = 0

const fadeIn = keyframes`
  0% {
    transform: translateX(40rem);
  }
  100% {
    transform: translateX(0);
  }
`

const fadeOut = (direction = '40rem') => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(${direction})
  }
`

const fadeAnimation = css`
  animation: ${({ show }) => (show ? fadeIn : fadeOut(translateXAxis))} 0.5s;
`

const slideBack = (originalPos) => keyframes`
0% {
  transform: translateX(${originalPos}px);
}
100% {
  transform: translateX(0);
}
`

const fadeReset = css`
  &.fadeReset {
    animation: ${({ originalPos }) => slideBack(originalPos)} 0.5s;
    left: 0px;
  }
`

const StyledFade = styled.div`
  min-height: ${({ minHeight }) => `${minHeight}px`};
  ${fadeAnimation}
`

const Fade = ({ show, children, minHeight }) => {
  const [shouldRender, setRender] = useState(show)
  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) setRender(false)
  }

  return (
    shouldRender && (
      <StyledFade show={show} onAnimationEnd={onAnimationEnd} minHeight={minHeight}>
        {children}
      </StyledFade>
    )
  )
}

const ToastWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  position: fixed;
  right: 1rem;
  top: 6rem;
  min-width: 28rem;
  max-width: 28rem;
  ${mediaQueries.phone} {
    min-width: calc(100% - 2rem);
    left: 1rem;
  }
  z-index: 1201;
`

const SingleToast = styled.div`
  background-color: ${({ type, theme, customColor }) =>
    ({
      success: theme.color.green.shade10,
      info: theme.color.blue.shade10,
      error: theme.color.red.shade10,
      custom: theme.color[customColor].shade10
    }[type] || theme.color.red.shade10)};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ type, theme, customColor }) =>
    ({
      success: theme.color.green.shade8,
      info: theme.color.blue.shade8,
      error: theme.color.red.shade8,
      custom: theme.color[customColor].shade8
    }[type] || theme.color.red.shade8)};
  border-left-width: 10px;
  border-left-color: ${({ type, theme, customColor }) =>
    ({
      success: theme.color.green.shade5,
      info: theme.color.blue.shade3,
      error: theme.color.red.shade4,
      custom: theme.color[customColor].shade4
    }[type] || theme.color.red.shade4)};
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: ${({ theme }) => `2px 2px 8px ${theme.color.grey.shade6}`};
  ${fadeReset}
`

const Centered = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Toasts = () => {
  const toasts = useSelector((state) => state.ui.toasts)

  if (toasts.size === 0) return null
  return (
    <ToastWrapper>
      {toasts.toList().map((toast) => {
        const { key, options } = toast
        return <Toast key={key} options={options} />
      })}
    </ToastWrapper>
  )
}

const handleSwipeStart = (e, target) => {
  const style = window.getComputedStyle(target)
  const paddingXTotal = parseInt(style.paddingLeft) + parseInt(style.paddingRight)
  const marginXTotal = parseInt(style.marginLeft) + parseInt(style.marginRight)
  const paddingAndMargin = paddingXTotal + marginXTotal

  touchStart = e.targetTouches[0].clientX
  startWidth = target.clientWidth
  target.style.position = 'absolute'
  target.style.width = `${startWidth - paddingAndMargin}px`
}

const handleSwipeMove = (e, target) => {
  touchDistance = e.targetTouches[0].clientX - touchStart
  target.style.left = `${touchDistance}px`
}

const handleSwipeEnd = (target) => {
  if (touchDistance >= 100) {
    translateXAxis = '40rem'
    return false
  }
  if (touchDistance <= -100) {
    translateXAxis = '-100rem'
    return false
  }
  target.style.left = `0px`
  target.style.position = 'static'
  return true
}

const IconNameForType = (type) =>
  ({
    [TOAST_TYPE.SUCCESS]: ICONS.CHECKMARK_CIRCLE,
    [TOAST_TYPE.INFO]: ICONS.INFORMATION_CIRCLE,
    [TOAST_TYPE.ERROR]: ICONS.WARNING_CIRCLE
  }[type] || ICONS.INFORMATION_CIRCLE)

const CloseButton = styled.button`
  background: transparent;
  border: none;
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

export const Toast = ({ options, noFade }) => {
  const { header, body, color, icon, type } = options
  const theme = useTheme()
  const [show, setShow] = useState(true)
  const [resetPos, setResetPos] = useState(false)
  const [originalPos, setOriginalPos] = useState(0)
  const [minHeight, setMinHeight] = useState(0)
  useEffect(() => {
    setTimeout(() => {
      translateXAxis = '40rem'
      if (!noFade) setShow(false)
    }, 6500)
  }, [noFade])
  return (
    <Fade show={show} minHeight={minHeight}>
      <SingleToast
        customColor={color || 'red'}
        className={resetPos ? 'fadeReset' : ''}
        originalPos={originalPos}
        type={type}
        onTouchStart={(e) => {
          handleSwipeStart(e, e.currentTarget)
          setMinHeight(e.currentTarget.offsetHeight)
        }}
        onTouchMove={(e) => {
          handleSwipeMove(e, e.currentTarget)
        }}
        onTouchEnd={(e) => {
          const isShown = handleSwipeEnd(e.currentTarget)
          setOriginalPos(touchDistance)
          setResetPos(isShown)
          setShow(isShown)
        }}
        onAnimationEnd={() => setResetPos(false)}
      >
        <Centered>
          <div style={{ height: '2rem', paddingRight: '1rem' }}>
            {type === TOAST_TYPE.CUSTOM && icon ? (
              icon
            ) : (
              <Icon name={IconNameForType(type)} style={{ fontSize: '2rem' }} />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <P design='h7' noMargin>
              {header}
            </P>
            <P design='body1' noMargin>
              {body}
            </P>
          </div>
        </Centered>
        <div>
          <CloseButton
            onClick={() => {
              translateXAxis = '40rem'
              setShow(false)
            }}
          >
            <Icon name={ICONS.CLOSE} style={{ fontSize: '2rem', color: theme.color.grey.shade2 }} />
          </CloseButton>
        </div>
      </SingleToast>
    </Fade>
  )
}

Toast.propTypes = {
  options: PropTypes.object.isRequired
}

export default Toasts
