import Parse from 'parse'
import { payout } from '@sellpy/commons'
import { cacheEntities } from '../entities/actions'
import { REFETCH_BALANCE_DETAILS } from '../balance/actions'
const { PAYOUT_STATUS } = payout

export const requestCreditPayout = ({
  amount,
  currency,
  region = process.env.REGION,
  origin = window.location.origin
}) => async (dispatch) => {
  const creditPayout = await Parse.Cloud.run('requestCreditPayout', {
    amount,
    currency,
    region,
    origin,
    verifyDac7: true
  })
  dispatch({
    type: REFETCH_BALANCE_DETAILS
  })
  return creditPayout
}

export const getPayoutForUser = ({ payoutId }) => (dispatch) => {
  return new Parse.Query('PayOut').get(payoutId).then((payout) => {
    dispatch(cacheEntities(payout))
    return payout
  })
}

export const getPayoutAwaitingVerification = () => (dispatch) => {
  return new Parse.Query('PayOut')
    .equalTo('status', PAYOUT_STATUS.AWAITING_VERIFICATION)
    .first()
    .then((payout) => {
      dispatch(cacheEntities(payout))
      return payout
    })
}
