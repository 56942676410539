import React from 'react'
import styled from 'styled-components'
import { useResponsive } from '../../hooks'
import { mediaQueries } from '../../uiComponents/mediaQueries'
import Bone from './components/Bone.jsx'

const PDPGrid = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 12px 36px 48px;
  display: grid;
  column-gap: 24px;
  grid-template-columns: repeat(12, [col-start] 1fr);

  ${mediaQueries.tablet} {
    padding: 12px 16px 48px;
    & > div {
      grid-column: span 12;
    }
  }

  ${mediaQueries.phone} {
    padding: 0px 16px 48px;
  }
`

const BreadcrumbsBone = styled(Bone)`
  grid-column: span 12;
  ${mediaQueries.phone} {
    margin-bottom: 8px;
  }
`

const ImageCarouselBone = styled(Bone)`
  aspect-ratio: 1/1;
  width: 100%;
  max-height: calc((1200px / 12) * 7 - 72px - 36px - 12px);
  justify-self: center;
  grid-column: span 7;

  ${mediaQueries.tablet} {
    width: calc(100% + 32px);
    grid-column: span 12;
  }
`

const CarouselBreadcrumbsBone = styled(Bone)`
  width: 100px;
  justify-self: center;
`

const SidebarContainer = styled.div`
  grid-column: span 5;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  & > * {
    width: 100%;
  }
`

const BuyerPDPV2Skeleton = () => {
  const { isTablet } = useResponsive()
  return (
    <PDPGrid>
      <BreadcrumbsBone height={32} bottomMargin={16} maxWidth={368} />
      <ImageCarouselBone bottomMargin={12} />
      {isTablet && <CarouselBreadcrumbsBone height={10} bottomMargin={12} />}
      <SidebarContainer>
        <TitleContainer>
          <div>
            <Bone height={28} bottomMargin={2} maxWidth={128} />
            <Bone height={22} bottomMargin={2} maxWidth={200} />
            <Bone height={26} bottomMargin={0} maxWidth={72} />
          </div>
          <Bone height={40} maxWidth={40} />
        </TitleContainer>
        <Bone height={78} bottomMargin={24} />
        <Bone height={48} bottomMargin={8} />
        <Bone height={48} bottomMargin={12} />
        <Bone height={20} bottomMargin={8} maxWidth={280} />
        <Bone height={32} bottomMargin={16} maxWidth={96} />
        <Bone height={18} bottomMargin={24} maxWidth={280} />
        <Bone height={22} bottomMargin={16} maxWidth={96} />
        <Bone height={156} bottomMargin={24} />
        <Bone height={72} bottomMargin={24} />
        <Bone height={22} bottomMargin={16} maxWidth={96} />
        <Bone height={72} bottomMargin={24} />
        <Bone height={111} bottomMargin={24} />
        <Bone height={127} bottomMargin={24} />
        <Bone height={48} bottomMargin={24} />
      </SidebarContainer>
    </PDPGrid>
  )
}

export default BuyerPDPV2Skeleton
