import config from 'config'
import { createQueryPreloader } from '@apollo/client'
import { initializeApolloClient } from './client'

let apolloClientInstance = null
let preloadQueryInstance = null

/**
 * @returns {ReturnType<typeof initializeApolloClient>}
 */
export const getApolloClient = () => {
  if (!apolloClientInstance) {
    apolloClientInstance = initializeApolloClient({
      sanityConfig: config.sanity,
      apolloConfig: config.apollo
    })
  }
  return apolloClientInstance
}

/**
 * @returns {import('@apollo/client').PreloadQueryFunction}
 */
export const getCreateQueryPreloader = () => {
  if (!preloadQueryInstance) {
    preloadQueryInstance = createQueryPreloader(getApolloClient())
  }
  return preloadQueryInstance
}
