import { createSelector } from 'reselect'
import { money, marketplace } from '@sellpy/commons'
import { pricesForItemsInCartSelector } from '../entities/selectors/checkout.js'

const zero = { amount: 0, currency: marketplace.CURRENCY[process.env.REGION] }

export const totalPriceOfItemsInCart = createSelector(
  [pricesForItemsInCartSelector],
  (itemCosts) => {
    if (itemCosts.size === 0) return zero
    return money.add(...itemCosts.toArray())
  }
)
