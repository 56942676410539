import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dot = styled.span`
  padding: 6px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ invertedColors, active, theme }) =>
    invertedColors
      ? active
        ? theme.color.white.default
        : theme.color.grey.shade4
      : active
      ? theme.color.grey.shade2
      : theme.color.grey.shade6};
  :hover {
    background-color: ${({ theme, active }) => !active && theme.color.grey.shade4};
  }
`

const Dots = ({ slides, activeIndex, className, invertedColors }) => (
  <Wrapper className={className}>
    {slides.map(({ slide, onClick }, i) => (
      <Dot
        onClick={onClick}
        invertedColors={invertedColors}
        key={slide}
        active={activeIndex === i}
      />
    ))}
  </Wrapper>
)

export default React.memo(Dots)
