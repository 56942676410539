import { marketplace } from '@sellpy/commons'
import config from 'config'
import i18n from 'i18next'
import backend from 'i18next-locize-backend'
import { initReactI18next } from 'react-i18next'
import { initializeToggleTranslationShortcut } from './toggleTranslationKeys'
import { DEFAULT_CONFIG } from './config'

export const initializeI18n = (additionalConfig) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(backend)
    .init({
      ...DEFAULT_CONFIG,
      backend: {
        private: false,
        projectId: config.locize.projectId,
        referenceLng: marketplace.LOCALE['SE'],
        version: 'latest',
        allowedAddOrUpdateHosts: ['']
      },
      react: {
        useSuspense: false
      },
      ...additionalConfig
    })
    .then(() => {
      // initializeToggleTranslationShortcut is emitted (and therefore undefined) by webpack in production builds. This shortcut only works while developing.
      initializeToggleTranslationShortcut && initializeToggleTranslationShortcut()
    })
}
