import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Breadcrumbs, Button, H4, Icon } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import { marketplace, region as regionFunctions } from '@sellpy/commons'
import { P, SIZES } from '../uiComponents'
import LayoutCenteredColumn, {
  CENTERED_COLUMN_VARIANTS
} from '../components/LayoutCenteredColumn.jsx'
import { mediaQueries } from '../uiComponents/mediaQueries'
import FormWrapper from '../components/ui/form/FormWrapper.jsx'
import BankPayoutInput from '../payOuts/bank/BankPayoutInput.jsx'
import { showToast } from '../../common/ui/actions.js'
import { TOAST_TYPE } from '../uiComponents/notifications/Toasts.jsx'
import { region } from '../../common/region/region.js'
import { getLatestBankAccount } from '../payOuts/bank/bankPayout.gql'

const ViewBackground = styled.div`
  background-color: ${({ theme }) => theme.color.grey.shade10};
`

const ViewContainer = styled.div`
  padding: 16px 0;
  ${mediaQueries.phone} {
    width: 100%;
  }
  max-width: 664px;
  background-color: ${({ theme }) => theme.color.white.default};
  margin: 16px auto;
`

const BreadCrumbContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 16px;
`

const ContentContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  ${mediaQueries.tablet} {
    flex-direction: column;
    margin-top: 273px;
  }
`

const AutomaticPayoutConfigQuery = gql`
  query automaticPayoutConfigs($currency: String!) {
    automaticPayoutConfigs(
      where: { active: { equalTo: true }, currency: { equalTo: $currency } }
      first: 1
    ) {
      edges {
        node {
          bankAccount {
            objectId
            BIC
            IBAN
            accountNumber
            clearingNumber
          }
        }
      }
    }
  }
`

const setAutomaticPayoutMethodMutation = gql`
  mutation setAutomaticPayoutMethod(
    $method: String
    $accountNumber: String
    $clearingNumber: String
    $BIC: String
    $IBAN: String
    $region: String!
  ) {
    setAutomaticPayoutMethod(
      method: $method
      accountNumber: $accountNumber
      clearingNumber: $clearingNumber
      BIC: $BIC
      IBAN: $IBAN
      region: $region
    ) {
      bankAccount {
        validTo
      }
    }
  }
`

const BankAccountSetup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['payoutSetup', 'balance', 'common'])

  const { data: existingAutomaticPayoutConfig } = useQuery(AutomaticPayoutConfigQuery, {
    variables: { currency: marketplace.CURRENCY[region()] }
  })

  const { data: latestBankAccount } = useQuery(getLatestBankAccount)
  const [setAutomaticPayoutMethod] = useMutation(setAutomaticPayoutMethodMutation, {
    onError: (e) => {
      const error = e.graphQLErrors[0]?.extensions?.details?._error

      dispatch(
        showToast({
          header: t('common:errorToast.header'),
          body:
            error === 'rejectedAccount'
              ? t(`balance:errorToast.body.rejectedAccount`)
              : t('common:errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
    }
  })

  const { BIC, IBAN, accountNumber, clearingNumber } =
    existingAutomaticPayoutConfig?.automaticPayoutConfigs?.edges[0]?.node?.bankAccount ||
    latestBankAccount?.bankAccounts?.edges[0]?.node ||
    {}

  const requiresInternationalBankDetails = regionFunctions.internationalBankAccountFields(region())
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      BIC,
      IBAN,
      accountNumber,
      clearingNumber
    }
  })
  const {
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = methods

  useEffect(() => {
    reset({
      BIC,
      IBAN,
      accountNumber,
      clearingNumber
    })
  }, [BIC, IBAN, accountNumber, clearingNumber])

  const onSubmit = useCallback(
    async (data) => {
      const details = requiresInternationalBankDetails
        ? { BIC: data.BIC, IBAN: data.IBAN }
        : {
            accountNumber: data.accountNumber,
            clearingNumber: data.clearingNumber
          }
      const response = await setAutomaticPayoutMethod({
        variables: {
          method: 'bankAccount',
          region: region(),
          ...details
        }
      })
      const automaticPayout = response?.data?.setAutomaticPayoutMethod
      if (automaticPayout?.bankAccount?.validTo) {
        navigate('/payout-setup/confirmation?method=bankAccount')
      } else if (automaticPayout && !automaticPayout.bankAccount.validTo) {
        dispatch(
          showToast({
            header: t('bankAccount.verification.toast.header'),
            body: t('bankAccount.verification.toast.body'),
            type: TOAST_TYPE.INFO
          })
        )
        navigate('/payoutVerificationInfo')
      }
    },
    [dispatch, navigate, t]
  )

  return (
    <>
      <ViewBackground>
        <LayoutCenteredColumn variant={CENTERED_COLUMN_VARIANTS.NARROW}>
          <BreadCrumbContainer>
            <Breadcrumbs
              options={[
                {
                  link: '/payout-setup',
                  label: (
                    <P design='body2' noMargin={true}>
                      <Icon
                        name={'ARROW_LEFT'}
                        style={{
                          marginRight: '8px'
                        }}
                      />
                      {t('mainPage.description.header')}
                    </P>
                  )
                }
              ]}
            />
          </BreadCrumbContainer>
          <ViewContainer>
            <ContentContainer>
              <H4>{t('choice.bankAccount.header')}</H4>
              <P design='body3' noMargin>
                {t('bankAccount.pageDescription.body')}
              </P>
              <FormProvider {...methods}>
                <FormWrapper style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
                  <BankPayoutInput />
                  <ButtonContainer>
                    <Button
                      variant={'filled'}
                      size={SIZES.SMALL}
                      fullWidth={false}
                      label={t('completeButton.label')}
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                    />
                  </ButtonContainer>
                </FormWrapper>
              </FormProvider>
            </ContentContainer>
          </ViewContainer>
        </LayoutCenteredColumn>
      </ViewBackground>
    </>
  )
}

export default BankAccountSetup
