import React from 'react'
import styled from 'styled-components'
import { mediaQueries } from '../../uiComponents/mediaQueries.js'
import useResponsive from '../../hooks/useResponsive.js'
import { HeaderDiv, LeftColumnDiv, ResultsDiv, StoreGrid } from '../../store/styles.js'
import Bone from './components/Bone.jsx'
import { StoreHeaderSkeleton } from './StoreHeaderSkeleton.jsx'

const ItemBone = styled.div`
  grid-column: span 6;

  ${mediaQueries.largerThanTablet} {
    grid-column: span 3;
  }
  ${mediaQueries.largeDesktop} {
    grid-column: span 2;
  }
`

const ItemImageBone = styled(Bone)`
  aspect-ratio: 1 / 1;
  width: 100%;
  height: auto;
`

const TextContentPlaceholder = styled.div`
  width: 100%;
  height: 114px;
`

export const SellpyHitsSkeleton = () => {
  const itemCount = 60
  const ItemBones = []
  for (let i = 0; i < itemCount; i++) {
    ItemBones.push(
      <ItemBone key={`sellpy-hits-bone-${i}`}>
        <ItemImageBone />
        <TextContentPlaceholder />
      </ItemBone>
    )
  }
  return <>{ItemBones}</>
}

const CategoryBone = styled.div`
  list-style: none;
  text-align: unset;
`

const CategoryTextPlaceholder = styled(Bone)`
  width: 100%;
  height: 15px;
  margin-bottom: 5px;
`

const CategoriesHeader = styled(Bone)`
  width: 100%;
  height: 22px;
  margin-bottom: 8px;
`

export const CategoriesHierarchicalMenuSkeleton = () => {
  const categoriesCount = 5
  const CategoryBones = []
  for (let i = 0; i < categoriesCount; i++) {
    CategoryBones.push(
      <CategoryBone key={`search-categories-${i}`}>
        <CategoryTextPlaceholder />
      </CategoryBone>
    )
  }
  return (
    <>
      <CategoriesHeader />
      {CategoryBones}
    </>
  )
}

export const SortingBone = styled.div`
  height: 32px;
`

export const SortingSkeleton = () => (
  <SortingWrapper>
    <SortingBone />
  </SortingWrapper>
)

const SortingWrapper = styled.div`
  grid-area: id;
  height: 53px;

  ${mediaQueries.mobile} {
    height: 32px;
  }
  ${mediaQueries.tablet} {
    height: 90px;
  }
`

export const MobileCategoryChipBone = styled(Bone)`
  height: 32px;
  width: 80px;
`

const MobileCategoriesWrapper = styled.div`
  grid-area: id;
  margin-bottom: 8px;
  padding: 0px 12px;
`

export const MobileCategoriesSkeleton = () => (
  <MobileCategoriesWrapper>
    <MobileCategoryChipBone />
  </MobileCategoriesWrapper>
)

const Filters = styled.div`
  grid-area: hf;
  height: 46px;
`

export const StoreSkeleton = () => {
  const { isTablet } = useResponsive()
  return (
    <StoreGrid>
      <HeaderDiv>
        <StoreHeaderSkeleton />
      </HeaderDiv>
      <Filters />
      <SortingWrapper>
        <SortingBone />
      </SortingWrapper>
      {!isTablet && (
        <LeftColumnDiv>
          <CategoriesHierarchicalMenuSkeleton />
        </LeftColumnDiv>
      )}
      <ResultsDiv>
        <SellpyHitsSkeleton />
      </ResultsDiv>
    </StoreGrid>
  )
}
