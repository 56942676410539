import { P } from '@sellpy/design-system-react-web'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncError } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`

const AppPaymentErrorElement = () => {
  const { t } = useTranslation('adyenSessionPayment')
  const asyncError = useAsyncError()
  return (
    <Wrapper>
      <P design='h3'>{t('appPaymentErrors.headline')}</P>
      <P design='body1'>{t('appPaymentErrors.subHeadline')}</P>
      <P design='body4'>{JSON.stringify(asyncError)}</P>
    </Wrapper>
  )
}

export default AppPaymentErrorElement
