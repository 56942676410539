import { P } from '@sellpy/design-system-react-web'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { money } from '@sellpy/commons'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const BalanceCard = styled.div`
  padding: 8px 18px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.green.shade10};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BalanceInfo = ({ balance }) => {
  const theme = useTheme()
  const { t } = useTranslation('balance')
  if (!balance) return null
  return (
    <Wrapper>
      <BalanceCard>
        <P design='body3' noMargin style={{ color: theme.color.green.shade1, flex: 1 }}>
          {t('total.balance.info')}:
        </P>
        <P
          design='h6'
          noMargin
          style={{ color: theme.color.green.shade1, flex: 2, textAlign: 'end' }}
        >
          {money.formatBaseUnit(balance)}
        </P>
      </BalanceCard>
    </Wrapper>
  )
}

export default BalanceInfo
