import { currency } from '@sellpy/commons'
import React from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Chip } from '@sellpy/design-system-react-web'
const { chipCurrencyConfig } = currency

const Container = styled.div`
  display: flex;
  gap: 8px;
`

const PayoutChips = ({ balance, handleChange = false }) => {
  const { setValue, getValues, watch } = useFormContext()
  watch('amount')
  const { t } = useTranslation('balance')
  const chipBrackets = chipCurrencyConfig(balance.currency)
  if (balance.amount <= 0) return null
  return (
    <Container>
      {chipBrackets.map(
        (chipBracket, i) =>
          chipBracket < balance.amount && (
            <Chip
              key={i}
              title={chipBracket}
              type={'button'}
              onClick={() => {
                setValue('amount', chipBracket, { shouldValidate: true })
                if (handleChange) handleChange(chipBracket)
              }}
              color={Number(getValues('amount')) === chipBracket ? 'black' : 'grey'}
            />
          )
      )}
      <Chip
        title={`${t('fullAmount')} (${balance.amount})`}
        color={Number(getValues('amount')) === balance.amount ? 'black' : 'grey'}
        type={'button'}
        onClick={() => {
          setValue('amount', balance.amount, { shouldValidate: true })
          if (handleChange) handleChange(balance.amount)
        }}
      />
    </Container>
  )
}

export default PayoutChips
