import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icon, ICONS, P } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../uiComponents/mediaQueries'

const Container = styled.div`
  display: flex;
  padding: 32px 16px;
  gap: 24px 72px;
  justify-content: center;
  margin: 0 auto;
  ${mediaQueries.tablet} {
    flex-direction: column;
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const LargeIcon = styled(Icon)`
  font-size: 32px;
  text-align: center;
  line-height: 1;
  height: 32px;
  width: 32px;
`

const ICON_LIST = [ICONS.QUALITY_ASSURED, ICONS.RETURN, ICONS.SUSTAINABLE_SHOPPING]

export const LandingUSP = () => {
  const { t, ready } = useTranslation('landing')

  const content = t(`slabs.USP.content`, { returnObjects: true })

  if (!ready || typeof content !== 'object') return null

  return (
    <Container>
      {content?.map((category, index) => (
        <ItemContainer key={category.headline}>
          <LargeIcon name={ICON_LIST[index]} />
          <P design='body3' noMargin>
            {category.headline}
          </P>
        </ItemContainer>
      ))}
    </Container>
  )
}
