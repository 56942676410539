import Parse from '../../../client/lib/parse'
import { analyticsEstimateSellabilityCalculation } from '../../analytics/actions'
import { locale } from '../../region/locale'

export const GET_ITEM_SELLABILITY_ESTIMATE_LOADING = 'GET_ITEM_SELLABILITY_ESTIMATE_LOADING'
export const GET_ITEM_SELLABILITY_ESTIMATE_SUCCESS = 'GET_ITEM_SELLABILITY_ESTIMATE_SUCCESS'
export const GET_ITEM_SELLABILITY_ESTIMATE_ERROR = 'GET_ITEM_SELLABILITY_ESTIMATE_ERROR'

export const estimateItemSellability = (metadata) => {
  return async (dispatch) => {
    analyticsEstimateSellabilityCalculation({ brand: metadata.brand, type: metadata.type })
    return Parse.Cloud.run('estimateItemSellability', {
      sourceLanguage: locale,
      metadata: { brand: metadata.brand, type: metadata.type }
    })
      .then((estimate) => {
        dispatch({
          type: GET_ITEM_SELLABILITY_ESTIMATE_SUCCESS,
          payload: estimate
        })
        return estimate
      })
      .catch((error) => {
        dispatch({ type: GET_ITEM_SELLABILITY_ESTIMATE_ERROR, payload: error })
      })
  }
}

export const CACHE_MAP_DRAWER_RESULT = 'CACHE_MAP_DRAWER_RESULT'

export const cacheMapDrawerResult = (result) => {
  return { type: CACHE_MAP_DRAWER_RESULT, result }
}
