import { combineReducers } from 'redux'
import { List, Map } from 'immutable'
import {
  REFETCH_BALANCE_DETAILS,
  BALANCE_DETAILS_FETCHED,
  SALE_BALANCES_FETCHED,
  RESERVED_AD_FEES_FETCHED,
  TOTAL_BALANCE_FETCHED,
  TOTAL_BALANCE_WITHOUT_RESERVED_AD_FEES_FETCHED
} from './actions'

const saleBalances = (state = Map(), action) => {
  switch (action.type) {
    case SALE_BALANCES_FETCHED:
      return action.saleBalances
    default:
      return state
  }
}

const reservedAdFees = (state = Map(), action) => {
  switch (action.type) {
    case RESERVED_AD_FEES_FETCHED:
      return action.reservedAdFees
    default:
      return state
  }
}

const totalBalance = (state = null, action) => {
  switch (action.type) {
    case TOTAL_BALANCE_FETCHED:
      return action.totalBalance
    default:
      return state
  }
}

const totalBalanceWithoutReservedAdFees = (state = null, action) => {
  switch (action.type) {
    case TOTAL_BALANCE_WITHOUT_RESERVED_AD_FEES_FETCHED:
      return action.totalBalanceWithoutReservedAdFees
    default:
      return state
  }
}

const balanceDetails = (state = List(), action) => {
  switch (action.type) {
    case BALANCE_DETAILS_FETCHED:
      const { events } = action
      return state.push(events)
    case REFETCH_BALANCE_DETAILS:
      return List()
    default:
      return state
  }
}

export default combineReducers({
  saleBalances,
  totalBalance,
  totalBalanceWithoutReservedAdFees,
  balanceDetails,
  reservedAdFees
})
