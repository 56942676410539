// MOV (Minimum order value) is the minimum value of the cart required to get free freight.
import { useEffect, useState, useCallback } from 'react'
import { runCloudCached } from '../../common/lib/parseTools'

export const useMOV = () => {
  const [mov, setMov] = useState()
  const getMOV = useCallback(async () => {
    const movByRegion = await runCloudCached()('getMOV')
    setMov(movByRegion[process.env.REGION])
  }, [setMov])
  useEffect(() => {
    getMOV()
  }, [getMOV])
  return { mov, refetchMOV: getMOV }
}
