import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, ICONS } from '@sellpy/design-system-react-web'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { QuickLinkContent, QuickLinkItem } from '../Styles.jsx'

const SELL_LINK_SIZE_BREAKPOINT = '370px'

const SmallSellLink = styled(QuickLinkItem)`
  @media (min-width: calc(${SELL_LINK_SIZE_BREAKPOINT} + 1px)) {
    display: none;
  }
`

const LargeSellLink = styled(Link).attrs({ reloadDocument: window.appUpdateAvailable })`
  align-self: center;
  @media (max-width: ${SELL_LINK_SIZE_BREAKPOINT}) {
    display: none;
  }
`

const SellButton = ({ navIconStyle }) => {
  const { t } = useTranslation('common')

  const [isScreenUnder400px, setIsScreenUnder400px] = useState(window.innerWidth < 400)
  useEffect(() => {
    const onChange = ({ matches }) => {
      setIsScreenUnder400px(!matches)
    }
    const mediaQueryList = window.matchMedia('(min-width: 400px)')
    mediaQueryList.addEventListener('change', onChange)

    return () => {
      mediaQueryList.removeEventListener('change', onChange)
    }
  }, [])

  return (
    <>
      <SmallSellLink to={'/sell'}>
        <QuickLinkContent>
          <div>{<Icon name={ICONS.TAG} style={navIconStyle} />}</div>
        </QuickLinkContent>
      </SmallSellLink>
      <LargeSellLink to={'/sell'}>
        <Button
          key={`sellButton${isScreenUnder400px}`}
          label={t('header.sell')}
          rightIcon={!isScreenUnder400px && <Icon name={ICONS.TAG} />}
          color='grey'
          size='small'
        />
      </LargeSellLink>
    </>
  )
}

export default SellButton
