import React from 'react'
import config from 'config'
import styled, { css } from 'styled-components'
import { Grid, H7 } from '@sellpy/design-system-react-web'
import { Link } from 'react-router-dom'
import { NotificationBadge } from '../../uiComponents'
import { mediaQueries } from '../../uiComponents/mediaQueries'

const region = process.env.REGION

export const Wrapper = styled(Grid)`
  background-color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? theme.color.red.shade6 : config.environmentColor};
  font-size: 14px;
  grid-template-rows: 64px 1px;
  ${mediaQueries.tablet} {
    ${({ hideSearchBarForMobile }) =>
      css`
        grid-template-rows: 56px ${!hideSearchBarForMobile ? '60px' : 0} 1px;
      `}
  }
`

export const FlexContainer = styled.div`
  grid-column: 1 / span 1;
  display: flex;
`

export const HeaderPosition = styled.div`
  position: fixed;
  z-index: 1060;
  top: 0;
  width: 100%;
`

export const NavWrapper = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  margin: auto;
  grid-row: 1;
  grid-column: 2 / 5;
  ${mediaQueries.tablet} {
    grid-column: 2 / 7;
  }
`

export const NavEntries = styled.div`
  display: flex;
  grid-column: 11 / span 2;
  width: 100%;
  justify-content: flex-end;
  transform: translateX(1.25rem);
  ${mediaQueries.tablet} {
    transform: unset;
    grid-column: 6 / 13;
  }
`

export const SearchboxWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  margin: auto;
  grid-row: 1;
  grid-column: 5 / 11;
  flex-direction: column;
  justify-content: center;
  ${mediaQueries.desktop} {
    grid-column: 5 / 10;
  }
  ${mediaQueries.tablet} {
    padding-top: 0;
    height: 60px;
    grid-row: 2;
    grid-column: 1 / 13;
  }
`

export const QuickLinkItem = styled(Link).attrs({ reloadDocument: window.appUpdateAvailable })`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
`

export const CenterFlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const QuickLinkContent = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  flex-direction: ${region !== 'SE' ? 'column' : 'row'};
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  ${mediaQueries.phone} {
    padding: 0 8px;
  }
`

// props are being passed down to Link-component like this: {...props}, so we need to filter out active, e.g.
export const NavItem = styled(({ active, ...props }) => (
  <Link reloadDocument={window.appUpdateAvailable} {...props} />
))`
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-bottom: ${({ active, theme }) =>
    active ? `2px solid ${theme.color.grey.shade2}` : 'none'};
  padding: 0 16px;
  &:hover {
    text-decoration: none;
  }
`

export const NavFlexContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const NavContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// props are being passed down to P-component like this: {...props}, so we need to filter out active, e.g.
export const NavLabel = styled(({ active, ...props }) => <H7 {...props} />)`
  color: ${(props) =>
    props.active ? props.theme.color.grey.shade2 : props.theme.color.grey.shade6};
`

export const GreyBorder = styled.div`
  grid-row: 2;
  ${mediaQueries.tablet} {
    grid-row: 3;
  }
  grid-column: 1 / 13;
  margin: 0 -36px;
  background-color: ${({ theme }) => `${theme.color.grey.shade8}`};
  ${({ theme }) => theme.layout.contentPadding}
`

export const StyledNotificationBadge = styled(NotificationBadge)`
  right: 0;
  top: 4px;
  z-index: 1;
`
