import React from 'react'
import styled from 'styled-components'
import { Grid, H3, P } from '@sellpy/design-system-react-web'
import { Background } from '../styles.js'
import { mediaQueries } from '../../../mediaQueries.js'

export const StyledGrid = styled(Grid)`
  grid-template-rows: 1fr 5fr;
  ${mediaQueries.tablet} {
    grid-template-rows: auto auto auto;
  }
`
export const Input = styled.div`
  background: ${({ theme }) => theme.color.grey.shade10};
  grid-column: 6 / span 6;
  grid-row: 2 / span 1;
  padding: calc((100vw - 6rem - 22rem) / 24);
  ${mediaQueries.tablet} {
    grid-column: 1 / span 12;
    grid-row: 2 / span 2;
    padding: 2rem;
  }
`
export const Text = styled.div`
  grid-column: 1 / span 5;
  grid-row: 2 / span 1;
  padding: calc((100vw - 6rem - 22rem) / 24);
  ${mediaQueries.tablet} {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
    padding: 4rem 2rem;
  }
`

const InputSlab = ({ color = 'blue', headline, body, inputComponent }) => {
  return (
    <StyledGrid>
      <Background color={color} />
      <Text>
        <H3 noMargin={!body}>{headline}</H3>
        <P design='body1' noMargin>
          {body}
        </P>
      </Text>
      <Input>{inputComponent}</Input>
    </StyledGrid>
  )
}

export default InputSlab
