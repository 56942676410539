import styled, { keyframes } from 'styled-components'
import { Button } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../mediaQueries'

const buttonContainerHeight = 80

const fadeIn = keyframes`
  0% {
    transform: translateY(90vh);
  }
  100% {
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(90vh);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueries.largerThanTablet} {
    grid-column: 3 / 11;
    margin: auto;
    width: 100%;
  }
  ${mediaQueries.tablet} {
    height: 100%;
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
  }
  max-width: 800px;
  background: #fff;
  outline: none;
  font-family: ${({ theme }) =>
    theme.text.fonts.body}; // Global styles are bypassed when using appElement prop of ReactModal
  color: ${({ theme }) =>
    theme.color.grey.shade2}; // Global styles are bypassed when using appElement prop of ReactModal
  border-radius: 10px;
  border: 0px;
  box-sizing: border-box;
  z-index: 1200;
  ${mediaQueries.tablet} {
    border-radius: 10px 10px 0 0;
    width: 100%;
    bottom: 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.5s;
  animation-fill-mode: forwards;
`

const OverlayfadeIn = keyframes`
  0% {
    background-color: rgba(170, 170, 170, 0);
  }
  100% {
    background-color: rgba(170, 170, 170, 0.75);
  }
`

const OverlayfadeOut = keyframes`
  0% {
    background-color: rgba(170, 170, 170, 0.75);
  }
  100% {
    background-color: rgba(170, 170, 170, 0);
  }
`

export const Overlay = styled.div`
  animation: ${({ isOpen }) => (isOpen ? OverlayfadeIn : OverlayfadeOut)} 0.5s;
  animation-fill-mode: forwards;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(170, 170, 170, 0.75);
  z-index: 1100;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`

export const Content = styled.div`
  overflow: auto;
  max-height: 65vh;
  ${mediaQueries.tablet} {
    max-height: 100%;
    flex: 12;
  }
  padding: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  flex-direction: row;
  max-height: ${buttonContainerHeight};
  ${mediaQueries.tablet} {
    flex-direction: column;
  }
`

export const HR = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.grey.shade8};
`

export const DesktopOnlyCancelButton = styled(Button)`
  margin-right: 16px;
  ${mediaQueries.tablet} {
    display: none;
  }
`

export const BottomMargin = styled.div`
  margin-bottom: 16px;
`
