import { Record, List } from 'immutable'
import merge from 'lodash/merge'
import * as userActions from '../user/actions'
import * as actions from './actions'

const InitialState = Record({
  newOrderId: undefined,
  cartContentLoaded: false,
  cartContentLoading: false,
  deliveryIntervals: List(),
  pricingModels: {},
  syncReservationsLoading: false,
  credit: undefined,
  cartFooterVisible: false,
  addingItemToCart: false
})
const initialState = new InitialState()

export default function cartReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState

  switch (action.type) {
    case actions.GET_CART_CONTENT_LOADING:
      return state.set('cartContentLoading', true)
    case actions.GET_CART_CONTENT_SUCCESS:
      return state.set('cartContentLoaded', true).set('cartContentLoading', false)
    case actions.GET_CART_CONTENT_ERROR:
      return state.set('cartContentLoaded', false).set('cartContentLoading', false)
    case actions.SET_PRICING_MODEL_FOR_SELLER_RETURN:
      return state.set('pricingModels', merge(state.get('pricingModels'), action.pricingModel))
    case actions.SYNC_RESERVATIONS_LOADING:
      return state.set('syncReservationsLoading', true)
    case actions.SYNC_RESERVATIONS_SUCCESS:
      return state.set('syncReservationsLoading', false)
    case actions.SYNC_RESERVATIONS_ERROR:
      return state.set('syncReservationsLoading', false)
    case actions.CART_SET_CREDIT:
      return state.set('credit', action.credit)
    case actions.ADDING_TO_CART_PENDING:
      return state.set('addingItemToCart', true)
    case actions.ADDING_TO_CART_RESOLVED:
      return state.set('addingItemToCart', false)

    // The following are here for when a user logges in on the cart page.
    case userActions.USER_LOGGED_IN_WITH_SESSION_TOKEN:
    case userActions.USER_PASSWORDLESS_LOGIN_SUCCESS:
    case userActions.USER_ANONYMOUS_SIGNUP_SUCCESS:
    case userActions.USER_FACEBOOK_LOGIN_SUCCESS:
    case userActions.USER_PARSE_SIGNUP_SUCCESS:
    case userActions.USER_PARSE_LOGIN_SUCCESS: {
      return state.set('cartContentLoaded', false)
    }
  }
  return state
}
