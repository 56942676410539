import { combineReducers } from 'redux'
import { Map } from 'immutable'
import * as actions from './actions'

const loading = (state = false, { type }) => {
  switch (type) {
    case actions.FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_LOADING:
      return true
    case actions.FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_SUCCESS:
    case actions.FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_ERROR:
      return false
    default:
      return state
  }
}

const forItem = (state = Map(), { type, cacheKey, alternatives }) => {
  switch (type) {
    case actions.FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_SUCCESS:
      return state.set(cacheKey, alternatives)
    default:
      return state
  }
}

const remainingForPackaging = (state = Map(), { type, cacheKey, remaining }) => {
  switch (type) {
    case actions.GET_REMAINING_FOR_PACKAGING_IN_FREIGHT_SUCCESS:
      return state.set(cacheKey, remaining)
    default:
      return state
  }
}

export default combineReducers({
  loading,
  forItem,
  remainingForPackaging
})
