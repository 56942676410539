import React from 'react'
import styled, { useTheme } from 'styled-components'
import { H3, ICONS, Icon, Span } from '@sellpy/design-system-react-web'
import { useResponsive } from '../../../hooks'
import { getFormattedCount } from './utils.js'

export const Count = ({ count }) => {
  const style = {
    count: {
      margin: '0',
      padding: '0 0.5em',
      backgroundColor: 'white',
      color: '#888888',
      fontSize: '9px',
      lineHeight: '1.5rem'
    }
  }

  const formattedCount = getFormattedCount(count)

  return <Span style={style.count}>{formattedCount}</Span>
}

const StyledInput = styled.input`
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
  -webkit-appearance: none;
  font-family: ballinger, sans-serif;
  border: 0;
  outline: none;
  font-size: 14px;
  width: 100%;
  background-color: transparent;
  height: 40px;
`

const SearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  border-radius: 999px;
  margin: ${({ isTablet }) => (isTablet ? '0 0 8px 0' : '0 16px 8px 16px')};
`

const SearchIcon = styled.div`
  padding: 0 8px 0 16px;
`

const ClearButton = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0 16px 0 8px;
  align-items: center;
  background-color: transparent;
`

export const SearchBox = ({ placeholder, onChange, query }) => {
  const theme = useTheme()
  const { isTablet } = useResponsive()

  return (
    <SearchBoxContainer isTablet={isTablet}>
      <SearchIcon>
        <Icon name={ICONS.SEARCH} style={{ fontSize: '24px', color: theme.color.black.default }} />
      </SearchIcon>
      <StyledInput
        type='search'
        onChange={({ target }) => {
          onChange(target.value)
        }}
        onKeyPress={({ key, target }) => {
          if (key === 'Enter') {
            onChange(query)
            target.blur()
          }
        }}
        placeholder={placeholder}
        value={query}
      />
      {query.length > 0 && (
        <ClearButton
          onClick={() => {
            onChange('')
          }}
        >
          <Icon
            name={ICONS.CLOSE_CIRCLE}
            style={{ fontSize: '24px', color: theme.color.grey.shade3 }}
          />
        </ClearButton>
      )}
    </SearchBoxContainer>
  )
}

export const SidebarHeader = ({ title, style }) => {
  return (
    <H3 design={'body1'} style={style}>
      {title}
    </H3>
  )
}

const Fade = () => {
  return (
    <div
      style={{
        display: 'block',
        bottom: '20px',
        height: '25px',
        width: '90%',
        backgroundImage:
          '-webkit-linear-gradient(bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.1) 100%)',
        position: 'absolute'
      }}
    />
  )
}

export const HierarchicalItemList = ({ showMore, ...rest }) => {
  return (
    <ItemList
      {...rest}
      style={{
        maxHeight: showMore ? 'none' : '215px',
        overflowY: 'auto',
        paddingBottom: showMore ? '5px' : '15px'
      }}
    />
  )
}

export const ItemList = ({ children, showMore, scrollRef, showFade, style }) => {
  return (
    <>
      <div
        style={
          style
            ? { ...style }
            : {
                maxHeight: showMore ? '225px' : 'none',
                overflowY: 'auto',
                paddingBottom: '6px'
              }
        }
        ref={scrollRef}
      >
        {children}
        {showMore && <div style={{ height: '12px' }} />}
      </div>
      {showFade && <Fade />}
    </>
  )
}
