import { Record } from 'immutable'
import * as actions from './actions'

const InitialState = Record({
  ordersOverview: {}
})
const initialState = new InitialState()

export default function ordersReducer(state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState

  switch (action.type) {
    case actions.GET_ORDERS_OVERVIEW_SUCCESS: {
      const ordersOverview = action.payload
      return state.set('ordersOverview', { ...state.get('ordersOverview'), ...ordersOverview })
    }
  }
  return state
}
