import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import memoize from 'lodash/memoize'
import { SlabHeader } from '@sellpy/design-system-react-web'
import { useResponsive } from '../../../hooks'
import { ImageTile } from '../..'
import { analyticsSliderTileClicked } from '../../../../common/analytics/actions'
import { sanityTools } from '../../../lib/sanity/utils'

const getTileTrackFunction = memoize(
  (data) => () => analyticsSliderTileClicked(data),
  ({ sliderId, tileId }) => `${sliderId}_${tileId}`
)

const TwoTileBanner = ({
  headline,
  body,
  ctaText,
  ctaUrl,
  leftTile,
  rightTile,
  backgroundColor = 'white',
  textColor,
  textPlacement,
  position,
  demographyTrackingData = {}
}) => {
  const ctaUrlObj = ctaUrl ? new URL(ctaUrl) : null
  const leftUrl = leftTile.toUrl ? new URL(leftTile.toUrl) : null
  const rightUrl = rightTile.toUrl ? new URL(rightTile.toUrl) : null
  return (
    <Background color={backgroundColor}>
      <SlabHeader
        headline={headline}
        body={body}
        readMoreLink={ctaUrlObj && `${ctaUrlObj.pathname}${ctaUrlObj.search}${ctaUrlObj.hash}`}
        readMoreText={ctaText}
      />
      <ImagesWrapper>
        <ImageLeft
          onClick={getTileTrackFunction({
            sliderId: window.location.pathname,
            sliderType: 'twoTileBanner',
            position,
            tileId: leftTile.tileId && sanityTools.richTextToPlainText(leftTile.tileId),
            ...demographyTrackingData
          })}
        >
          <ItemTile
            mobileImageUrl={leftTile.mobileImageUrl}
            desktopImageUrl={leftTile.desktopImageUrl}
            label={leftTile.label}
            sublabel={leftTile.sublabel}
            toUrl={leftUrl && `${leftUrl.pathname}${leftUrl.search}${leftUrl.hash}`}
            textPlacement={textPlacement}
            textColor={textColor}
          />
        </ImageLeft>
        <ImageRight
          onClick={getTileTrackFunction({
            sliderId: window.location.pathname,
            sliderType: 'twoTileBanner',
            position,
            tileId: rightTile.tileId && sanityTools.richTextToPlainText(rightTile.tileId)
          })}
        >
          <ItemTile
            mobileImageUrl={rightTile.mobileImageUrl}
            desktopImageUrl={rightTile.desktopImageUrl}
            label={rightTile.label}
            sublabel={rightTile.sublabel}
            toUrl={rightUrl && `${rightUrl.pathname}${rightUrl.search}${rightUrl.hash}`}
            textPlacement={textPlacement}
            textColor={textColor}
          />
        </ImageRight>
      </ImagesWrapper>
    </Background>
  )
}

const ItemTile = ({
  mobileImageUrl,
  desktopImageUrl,
  label,
  sublabel,
  toUrl,
  textPlacement,
  textColor
}) => {
  const { isTablet } = useResponsive()
  return (
    <ImageTile
      aspectRatio={isTablet ? 100 : 50}
      imageUrl={isTablet ? mobileImageUrl : desktopImageUrl}
      label={label}
      sublabel={sublabel}
      to={toUrl}
      textPlacement={textPlacement}
      textColor={textColor}
      mobileWidth='40vw'
      desktopWidth='40vw'
    />
  )
}

const Background = styled.div`
  background-color: ${({ theme, color }) => theme.color[color].shade10 || color};
`

const ImagesWrapper = styled.div`
  display: flex;
  margin: auto;
  ${({ theme }) => theme.layout.contentPadding};
`

const ImageLeft = styled.div`
  width: 50%;
  margin-right: 0.5rem;
`

const ImageRight = styled.div`
  width: 50%;
  margin-left: 0.5rem;
`

TwoTileBanner.propTypes = {
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  leftTile: PropTypes.object.isRequired,
  rightTile: PropTypes.object.isRequired,
  color: PropTypes.string,
  textPlacement: PropTypes.oneOf(['center'])
}

export default TwoTileBanner
