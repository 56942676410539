import config from 'config'
import { imageTools } from '@sellpy/commons'

const imageUrlFromAsset = (asset, options) => {
  // instead of using the lib (https://github.com/sanity-io/image-url) for building image url from sanity asset reference,
  // we pulled out the code necessary (the lib would increase the bundle size, and we don't need it for more than building a url)
  // see reference: https://github.com/sanity-io/image-url/blob/master/src/parseAssetId.ts
  const [, id, dimensionString, format] = asset._ref.split('-')
  const imageUrl = `${imageTools.SANITY_CDN_URL}/images/${config.sanity.projectId}/${config.sanity.dataset}/${id}-${dimensionString}.${format}`
  const optimizedImage = imageTools.optimizeSanityImage(imageUrl, options)
  return optimizedImage
}

const richTextToPlainText = (blocks = []) =>
  blocks
    .map((block) =>
      block._type !== 'block' || !block.children
        ? ''
        : block.children.map((child) => child.text).join('')
    )
    .join(' ')

const isNotEmpty = (object) => {
  return Boolean(object && Object.values(object).some(Boolean))
}

export const sanityTools = {
  richTextToPlainText,
  imageUrlFromAsset,
  isNotEmpty
}
