import { fromJS } from 'immutable'
import Parse from '../../client/lib/parse'
import { cacheEntities } from '../entities/actions'

export const getPaymentAndPaidEntities = (paymentId) => async (dispatch) => {
  const { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
  dispatch(cacheEntities(payment))
  dispatch(cacheEntities(entities))
  return { payment, entities }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const pollPaymentAndPaidEntitiesForCompletion = (paymentId) => async (dispatch) => {
  let count = 0
  let { payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
  while (!payment.get('completedAt') && count++ < 20) {
    await sleep(3000)
    ;({ payment, entities } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId }))
  }
  dispatch(cacheEntities(payment))
  dispatch(cacheEntities(entities))
  return { payment, entities }
}

export const getPayment = (paymentId) => async (dispatch) => {
  const { payment } = await Parse.Cloud.run('getPaymentAndPaidEntities', { paymentId })
  dispatch(cacheEntities(payment))
  return { payment }
}

export const GET_ORDER_LOADING = 'GET_ORDER_LOADING'
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR'

export const getOrder = (orderId) => {
  return (dispatch) => {
    dispatch({ type: GET_ORDER_LOADING })
    return new Parse.Query('Order')
      .include('items')
      .include('user')
      .get(orderId)
      .then((order) => {
        dispatch(cacheEntities(order))
        return order.get('promoCode')
          ? Parse.Cloud.run('getPromoCode', {
              objectId: order.get('promoCode').id
            })
          : Promise.resolve()
      })
      .then((promoCode) => dispatch(cacheEntities(promoCode)))
      .catch((error) => {
        dispatch({ type: GET_ORDER_ERROR, error })
        return Promise.reject(error)
      })
  }
}

export const GET_MY_ITEM_DATA_SUCCESS = 'GET_MY_ITEM_DATA_SUCCESS'

export const getOrderAndClaimForMyItems = () => {
  return (dispatch) => {
    return Parse.Cloud.run('orderAndClaimForMyItems', {}).then((results) => {
      results.forEach((result) => dispatch(cacheEntities(result)))
      dispatch({ type: GET_MY_ITEM_DATA_SUCCESS })
    })
  }
}

const GET_ORDER_RATING_LOADING = 'GET_ORDER_RATING_LOADING'
const GET_ORDER_RATING_SUCCESS = 'GET_ORDER_RATING_SUCCESS'

export const getOrderRating = (orderId) => {
  return (dispatch) => {
    dispatch({ type: GET_ORDER_RATING_LOADING })
    return Parse.Cloud.run('getOrderRating', { orderId })
      .then((orderRating) => {
        dispatch(cacheEntities(orderRating))
        dispatch({ type: GET_ORDER_RATING_SUCCESS })
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const getDeliveriesForOrder = (orderId) => {
  return (dispatch) => {
    return Parse.Cloud.run('getDeliveriesForOrder', { orderId })
      .then((deliveries) => dispatch(cacheEntities(deliveries)))
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const GET_PUBLIC_ORDER_DETAILS_FOR_ITEM = 'GET_PUBLIC_ORDER_DETAILS_FOR_ITEM'

export const getPublicOrderDetailsForItem = (itemId) => async (dispatch) => {
  const publicOrder = await Parse.Cloud.run('getPublicOrderDetailsForItem', { itemId })
  if (publicOrder) {
    dispatch({
      type: GET_PUBLIC_ORDER_DETAILS_FOR_ITEM,
      publicOrder: fromJS({ [publicOrder.objectId]: publicOrder })
    })
  }
}
