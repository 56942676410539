import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Airmee,
  Budbee,
  CityMail,
  Colissimo,
  Dhl,
  PostNord,
  InPost
} from '@sellpy/design-system-react-web'
import { H3 } from '../../../../uiComponents'
import { useDeliveryCompanies } from '../../../../../common/hooks/useDeliveryCompanies'
import { DeliveryPaymentWrapper } from './styles'

const DELIVERY_COMPANY_ICONS = {
  airmee: <Airmee />,
  budbee: <Budbee />,
  citymail: <CityMail />,
  colissimo: <Colissimo />,
  dhl: <Dhl />,
  postnord: <PostNord />,
  inpost: <InPost />
}

export const IconContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`

const DeliveryInfo = ({ showTrustScore }) => {
  const { t } = useTranslation('footer')
  const deliveryCompanies = useDeliveryCompanies()
  return deliveryCompanies.length > 0 ? (
    <DeliveryPaymentWrapper showTrustScore={showTrustScore}>
      <H3 design='body6'>{t('deliveryOptions').toUpperCase()}</H3>
      <IconContainer>
        {deliveryCompanies.map((company) => (
          <React.Fragment key={company}>{DELIVERY_COMPANY_ICONS[company]}</React.Fragment>
        ))}
      </IconContainer>
    </DeliveryPaymentWrapper>
  ) : null
}

export default DeliveryInfo
