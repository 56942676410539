import React, { useState } from 'react'
import Parse from 'parse'
import styled, { css, useTheme } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { user } from '@sellpy/commons'
import {
  ICONS,
  Icon,
  Button,
  Span,
  H2,
  RouterLink,
  A,
  P,
  Blob
} from '@sellpy/design-system-react-web'
import { logout } from '../../../common/user/actions'
import { SIZES, blobColorSelector } from '../../uiComponents/constants.js'
import {
  isFeatureEnabled,
  FEATURE_CREDITS,
  FEATURE_SELL,
  FEATURE_REFERRALS,
  FEATURE_BAG_SUBSCRIPTION,
  FEATURE_P2P
} from '../../featureToggle'
import { staticContentLoggedInCategories } from '../menuItems'
import { mediaQueries } from '../../uiComponents/mediaQueries'
import { faqLink } from '../../../common/region/locale'
import { isLoyaltyEnabled } from '../../../common/region/region'
const { userIsFull } = user

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.grey.shade10};
  padding: 2rem 1rem;
`

const listLinkStyle = css`
  height: 4rem;
  padding: 0 1rem;
  color: ${({ theme }) => theme.color.grey.shade1};
  border: ${({ theme, border }) => (border ? `1px solid ${theme.color.grey.shade7}` : 'none')};
  border-right: none;
  border-left: none;
  background-color: ${({ darker, theme }) => darker && theme.color.grey.shade10};
  justify-content: space-between;
  display: flex;
  font-size: ${({ theme }) => `${theme.sizes[SIZES.MEDIUM]}px`};
  -webkit-tap-highlight-color: ${({ theme }) => theme.color.grey.shade9};
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    ${mediaQueries.largerThanTablet} {
      background-color: ${({ theme }) => theme.color.grey.shade9};
    }
  }
`
const StyledSpan = styled(Span)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ListLinkInternal = styled(({ darker, ...props }) => <RouterLink {...props} />)`
  ${listLinkStyle}
`

const ListLinkExternal = styled(({ darker, ...props }) => <A {...props} />)`
  ${listLinkStyle}
`

const ListLinkButton = styled(({ darker, border, ...props }) => <div {...props} />)`
  ${listLinkStyle}
`

const BottomContent = ({ section, closeMenu }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('menu')
  const loggedIn = userIsFull(Parse.User.current())
  return (
    <div>
      {loggedIn && (
        <>
          <HeaderWrapper>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              <Icon name={ICONS.PROFILE} style={{ margin: '0 1rem 0 0', fontSize: '2rem' }} />
              <H2 design='h7' noMargin>
                {t(`loginContent.myAccount`)}
              </H2>
            </div>
            {isFeatureEnabled(FEATURE_SELL) && (
              <RouterLink
                onClick={() => closeMenu('myAccount')}
                to='/home'
                noMargin
                color='black'
                style={{ textDecoration: 'underline' }}
              >
                {t(`loginContent.goToMyAccountPage`)}
              </RouterLink>
            )}
          </HeaderWrapper>
          <LinkList closeMenu={closeMenu} section={section} />
        </>
      )}
      <ListLinkInternal
        darker
        to={'/sustainability'}
        onClick={() => closeMenu('sustainability')}
        noMargin
      >
        {t(`loginContent.savedCO2`)}
      </ListLinkInternal>
      {isFeatureEnabled(FEATURE_CREDITS) && (
        <ListLinkInternal
          darker
          to={'/gift-card/purchase'}
          onClick={() => closeMenu('buyGiftCard')}
          noMargin
        >
          {t(`loginContent.buyGiftCard`)}
        </ListLinkInternal>
      )}
      {isLoyaltyEnabled && (
        <ListLinkInternal
          darker
          to={'/first'}
          onClick={() => closeMenu('loyaltyInfoPage')}
          noMargin
        >
          {t('loginContent.loyaltyInfo')}
        </ListLinkInternal>
      )}
      <ListLinkExternal darker href={faqLink} onClick={() => closeMenu('faq')} target='_blank'>
        {t(`loginContent.faq`)}
      </ListLinkExternal>
      <ListLinkInternal
        darker
        to={'/support'}
        onClick={() => closeMenu('support')}
        noMargin
        style={{}}
      >
        {t(`loginContent.helpAndSupport`)}
      </ListLinkInternal>
      {loggedIn && (
        <>
          <ListLinkInternal
            darker
            onClick={() => closeMenu('accountDetails')}
            noMargin
            to={'/profile'}
          >
            {t(`loginContent.accountDetails`)}
          </ListLinkInternal>
          <ListLinkButton
            darker
            onClick={() => {
              closeMenu('logout')
              dispatch(logout('/'))
            }}
          >
            {t(`loginContent.logout`)}
          </ListLinkButton>
        </>
      )}
      <ListLinkButton
        darker
        style={{
          backgroundColor: 'white',
          borderBottomColor: 'white'
        }}
      />
      {!loggedIn && (
        <div style={{ padding: '0 2rem' }}>
          <Link to={'/login'}>
            <Button fullWidth label={t('loginContent.login')} onClick={() => closeMenu('login')} />
          </Link>
          <P center style={{ margin: '0.5rem' }} onClick={() => closeMenu('login')}>
            <Trans i18nKey={'menu:loginContent.notLoggedInYet'}>
              part0 <RouterLink to={'/signup'}>part1</RouterLink>
            </Trans>
          </P>
        </div>
      )}
    </div>
  )
}

export default BottomContent

const LinkList = ({ section, closeMenu }) => {
  const { t } = useTranslation('menu')
  const theme = useTheme()
  const [category, setCategory] = useState()
  const userId = Parse.User.current() ? Parse.User.current().id : ''
  const content = {
    sell: [
      { label: 'saleOverview', to: '/sales' },
      {
        label: 'circle',
        featureToggle: FEATURE_P2P,

        blob: (
          <Blob
            label='Circle'
            style={{ margin: '0 1rem' }}
            color={blobColorSelector['p2p']}
            size={SIZES.SMALL}
          />
        )
      },
      {
        label: 'buyRequest',
        featureToggle: FEATURE_P2P,
        blob: (
          <Blob
            label='Circle'
            style={{ margin: '0 1rem' }}
            color={blobColorSelector['p2p']}
            size={SIZES.SMALL}
          />
        )
      },
      { label: 'youStore', to: `/store/user/${userId}` },
      { label: 'balanceAndPayout', to: '/balance' },
      {
        label: 'subscribeToSellpyBags',
        to: '/bagsubscription/manage',
        featureToggle: FEATURE_BAG_SUBSCRIPTION
      },
      { label: 'referAFriend', to: '/referrals', featureToggle: FEATURE_REFERRALS },
      { label: 'companySales', to: '/business' }
    ],
    buy: [
      { label: 'orders', to: '/orders' },
      { label: 'myShopping', to: '/my-shopping' },
      { label: 'purchasedItems', to: '/user/orders' },
      { label: 'favorites', to: '/my-shopping/favorites' },
      { label: 'trackedSearch', to: '/my-shopping/tracked-searches' },
      { label: 'recommendation', to: '/my-shopping/weekly-recommendations' },
      { label: 'sellpyCredits', to: '/credit/balance', featureToggle: FEATURE_CREDITS },
      { label: 'redeemGiftCard', to: '/gift-card/redeem', featureToggle: FEATURE_CREDITS }
    ]
  }
  if (section === 'buy' && category) setCategory('')
  if (!category)
    return content[section].map(
      ({ label, to, featureToggle, blob }) =>
        isFeatureEnabled(featureToggle) &&
        (to ? (
          <ListLinkInternal noMargin key={label} to={to} onClick={() => closeMenu(label)}>
            <StyledSpan noMargin>
              {t(`loginContent.${label}`)}
              {blob}
            </StyledSpan>
          </ListLinkInternal>
        ) : (
          <ListLinkButton key={label} onClick={() => setCategory(label)}>
            <StyledSpan noMargin>
              {t(`loginContent.${label}`)}
              {blob}
            </StyledSpan>
            <Icon name={ICONS.ARROW_RIGHT} style={{ color: theme.color.grey.shade2 }} />
          </ListLinkButton>
        ))
    )

  return (
    <>
      <ListLinkButton key={'closeMenu'} darker border onClick={() => setCategory('')}>
        <Icon name={ICONS.ARROW_LEFT} style={{ color: theme.color.grey.shade2 }} />
        {t(`loginContent.${category}`)}
        <div />
      </ListLinkButton>
      {staticContentLoggedInCategories[category].map(({ headline, to, blob }) => (
        <ListLinkInternal noMargin key={headline} to={to} onClick={() => closeMenu(headline)}>
          <StyledSpan noMargin>
            {t(`loginContent.${headline}`)}
            {blob}
          </StyledSpan>
        </ListLinkInternal>
      ))}
    </>
  )
}
