import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useInputValidators = ({ namespace } = {}) => {
  const namespaces = useMemo(() => (namespace ? [namespace, 'common'] : ['common']), [namespace])
  const { t } = useTranslation(namespaces)
  return {
    applyValidator: (validator) => (value, formValues) => validator(value, formValues, { t })
  }
}
