import thunk from 'redux-thunk'
import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './app/reducer'

export default function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
  const store = composeEnhancers(applyMiddleware(thunk))(createStore)(rootReducer)

  // This is still needed for hot reloading reducers and actions, even if main.js now seems to be fine without it due to react-refresh-webpack-plugin
  if (module.hot) {
    module.hot.accept('./app/reducer', () => {
      store.replaceReducer(rootReducer)
    })
  }

  return store
}
