import { theme } from '@sellpy/design-system-react-web'
import colorTheme from './color/theme.json'
import textTheme from './text/theme.js'
import generalTheme from './theme.json'

export default {
  color: colorTheme,
  text: textTheme,
  ...generalTheme,
  ...theme
}
