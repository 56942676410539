import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ActionInfoBox } from '@sellpy/design-system-react-web'
import { ERRORS } from '../AdyenSessionPayment.jsx'

const StyledActionInfoBox = styled(ActionInfoBox)`
  margin-bottom: 8px;
`
const parseParseErrors = ({ details }, t) => {
  const definedErrorsDetails = {
    reservationsNotInSync: {
      headline: t('errorHandler.parse.reservationsNotInSync.headline'),
      subheadline: t('errorHandler.parse.reservationsNotInSync.subheadline')
    },
    invalidCreditAmount: {
      headline: t('errorHandler.parse.invalidCreditAmount.headline'),
      subheadline: t('errorHandler.parse.invalidCreditAmount.subheadline')
    },
    priceIncreasedTooMuch: {
      headline: t('errorHandler.parse.priceIncreasedTooMuch.headline'),
      subheadline: t('errorHandler.parse.priceIncreasedTooMuch.subheadline')
    },
    ongoingSendBackRequest: {
      headline: t('errorHandler.parse.ongoingSendBackRequest.headline'),
      subheadline: t('errorHandler.parse.ongoingSendBackRequest.subheadline')
    }
  }
  return (
    definedErrorsDetails[(details?._error)] || {
      headline: t('errorHandler.parse.general.headline'),
      subheadline: t('errorHandler.parse.general.subheadline')
    }
  )
}

const adyenParseErrors = (error, t) => {
  const definedErrorsDetails = {
    'swish App was not found': {
      headline: t('errorHandler.adyen.swishAppwasnotfound.headline'),
      subheadline: t('errorHandler.adyen.swishAppwasnotfound.subheadline')
    }
  }
  return (
    definedErrorsDetails[error.message] || {
      headline: t('errorHandler.adyen.general.headline'),
      subheadline: t('errorHandler.adyen.general.subheadline')
    }
  )
}

const _isFormValidationError = (error) => error.type === 'validate'

const ErrorHandler = ({ errors, clearErrors }) => {
  const { t } = useTranslation('adyenSessionPayment')

  const parseError = errors[ERRORS.PARSE_ERROR]
  if (parseError) {
    const { headline, subheadline } = parseParseErrors(parseError.message, t)
    return (
      <StyledActionInfoBox backgroundColor={'red'} headline={headline} subHeadline={subheadline} />
    )
  }

  const adyenError = errors[ERRORS.ADYEN_ERROR]
  if (adyenError && !_isFormValidationError(adyenError)) {
    if (adyenError?.name === 'CANCEL') {
      clearErrors(ERRORS.ADYEN_ERROR)
      return <div />
    }
    const { headline, subheadline } = adyenParseErrors(adyenError, t)
    return (
      <StyledActionInfoBox backgroundColor={'red'} headline={headline} subHeadline={subheadline} />
    )
  }

  return <div />
}

export default ErrorHandler
