import { marketplace } from '@sellpy/commons'
import { fromJS } from 'immutable'
import Parse from 'parse'
import { cacheEntities } from '../entities/actions'
import { runCloudCached } from '../lib/parseTools'
import { region } from '../region/region'

export const getAllTransactionsForItem = (itemId) => async (dispatch) =>
  runCloudCached((transactions) => {
    dispatch(cacheEntities(transactions))
  })('getAllTransactionsForItem', { itemId })

export const getAllSoldItemsWithBalanceReductionsForUser = () => async (dispatch) => {
  const transactions = await Parse.Cloud.run('getAllSoldItemsWithBalanceReductionsForUser')
  dispatch(cacheEntities(transactions))
}

export const getAllP2pTransactionsForItem = (itemId) => async (dispatch) =>
  runCloudCached((transactions) => {
    dispatch(cacheEntities(transactions))
  })('getAllP2pTransactionsForItem', { itemId })

export const SALE_BALANCES_FETCHED = 'SALE_BALANCES_FETCHED'

export const getSaleBalancesForUser = () => async (dispatch) =>
  runCloudCached((saleBalances) => {
    dispatch({ type: SALE_BALANCES_FETCHED, saleBalances: fromJS(saleBalances) })
  })('getSaleBalancesForUser', {
    currency: marketplace.CURRENCY[process.env.REGION]
  })

export const TOTAL_BALANCE_FETCHED = 'TOTAL_BALANCE_FETCHED'
export const getTotalBalanceForUser = () => async (dispatch) =>
  dispatch({
    type: TOTAL_BALANCE_FETCHED,
    totalBalance: fromJS(
      await Parse.Cloud.run('getTotalBalanceForUser', {
        currency: marketplace.CURRENCY[region()]
      })
    )
  })

export const TOTAL_BALANCE_WITHOUT_RESERVED_AD_FEES_FETCHED =
  'TOTAL_BALANCE_WITHOUT_RESERVED_AD_FEES_FETCHED'
export const getTotalBalanceWithoutReservedAdFeesForUser = () => async (dispatch) =>
  dispatch({
    type: TOTAL_BALANCE_WITHOUT_RESERVED_AD_FEES_FETCHED,
    totalBalanceWithoutReservedAdFees: fromJS(
      await Parse.Cloud.run('getTotalBalanceWithoutReservedAdFeesForUser', {
        currency: marketplace.CURRENCY[region()]
      })
    )
  })

export const RESERVED_AD_FEES_FETCHED = 'RESERVED_AD_FEES_FETCHED'

export const getUnsettledReservedAdFeesForUser = () => async (dispatch) =>
  runCloudCached((reservedAdFees) => {
    dispatch({ type: RESERVED_AD_FEES_FETCHED, reservedAdFees: fromJS(reservedAdFees) })
  })('getUnsettledReservedAdFeesForUser', {
    currency: marketplace.CURRENCY[process.env.REGION]
  })

export const verifyPayout = ({ token }) => async (dispatch) => {
  const payout = await Parse.Cloud.run('verifyPayout', { token })
  dispatch(cacheEntities(payout))
  return payout
}

export const getPayoutsAwaitingVerification = () => async (dispatch) => {
  const payouts = await Parse.Cloud.run('getPayoutsAwaitingVerification')
  dispatch(cacheEntities(payouts))
}

export const BALANCE_DETAILS_FETCHED = 'BALANCE_DETAILS_FETCHED'

export const REFETCH_BALANCE_DETAILS = 'REFETCH_BALANCE_DETAILS'

export const getUserBalanceDetails = (page) => async (dispatch) => {
  const events = await Parse.Cloud.run('getUserBalanceDetails', { page: page })
  dispatch({
    type: BALANCE_DETAILS_FETCHED,
    events: fromJS(events)
  })
  return events
}
