import config from 'config'
import Parse from 'parse'
import { captureException } from '@sentry/react'
import { ERRORS } from './AdyenSessionPayment.jsx'

const clearUrl = ({ urlParams, navigate }) => {
  // Remove redirectResult/sessionId-params to make checkout interactive again
  urlParams.delete('redirectResult')
  urlParams.delete('sessionId')
  navigate(
    {
      search: urlParams.toString()
    },
    { replace: true }
  )
}

export const getAdyenConfig = ({
  session,
  navigate,
  setError,
  paymentSubmit,
  getValidFormData,
  setLoading,
  userExists,
  urlParams,
  clearErrors,
  creditAmount
}) => ({
  locale: session.shopperLocale,
  environment: config.adyen.environment,
  clientKey: config.adyen.clientKey,
  analytics: {
    enabled: false
  },
  session,
  onPaymentCompleted: async (result) => {
    if (result.resultCode === 'Authorised') {
      // 2024-09: Setting query param 'authorised' is only kept for old app clients.
      // It can be removed when clients have been updated.
      navigate(`/advance-payment/${session.id}?authorised=true`)
      return
    }

    clearUrl({ urlParams, navigate })

    if (result.resultCode === 'Error') {
      captureException(result)
      setError(ERRORS.ADYEN_ERROR, {
        message: result.resultCode
      })
      setLoading(false)
    }

    if (result.resultCode === 'Cancelled') {
      setLoading(false)
      await Parse.Cloud.run('cancelPaymentBySessionId', {
        adyenSessionId: session.id
      })
    }

    if (result.resultCode === 'Refused') {
      setError(ERRORS.ADYEN_ERROR, {
        message: result.resultCode
      })
      setLoading(false)
    }
  },
  onError: (error) => {
    clearUrl({ urlParams, navigate })
    captureException(error)
    setError(ERRORS.ADYEN_ERROR, { name: error.name, message: error.message })
  },
  beforeSubmit: async (data, component, actions) => {
    try {
      const formValues = await getValidFormData()
      const commencedPayment = await paymentSubmit({
        paymentId: session.reference,
        adyenPaymentData: { adyenSessionId: session.id, ...data },
        creditAmount,
        dataUsageConsent: formValues.dataUsageConsent
      })
      if (component.props.amount.value !== commencedPayment.get('adyenAmount').amount) {
        setError(ERRORS.PARSE_ERROR, {
          message: 'adyenAmount in payment and the actual amount differ',
          details: {
            paymentAdyenAmount: component.props.amount.value,
            clientAdyenAmount: commencedPayment.get('adyenAmount').amount,
            payment: commencedPayment.id
          }
        })
        actions.reject()
      }
      actions.resolve(data)
    } catch (e) {
      if (e.message !== 'invalidFormData') {
        captureException(e)
        setError(ERRORS.PARSE_ERROR, e)
      }
      actions.reject()
      setLoading(false)
    }
  },
  onActionHandled: async ({ componentType }) => {
    const selectedPaymentOptions = document.getElementsByClassName('selected_payment_option')
    if (selectedPaymentOptions.length > 0) {
      const element = selectedPaymentOptions[0]
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 100,
        behavior: 'smooth'
      })
    }
    try {
      await Parse.Cloud.run('handleAdyenSessionAction', {
        paymentId: session.reference,
        action: componentType
      })
    } catch (error) {
      console.error('failed running handleAdyenSessionAction', error)
    }
  },
  paymentMethodsConfiguration: {
    ...(!session.enableRecurring && {
      card: { enableStoreDetails: userExists }
    }),
    paypal: {
      style: {
        // Optional configuration for PayPal payment buttons.
        layout: 'vertical',
        color: 'gold',
        disableMaxWidth: true
      }
    },
    ideal: {
      issuer: '1160'
    }
  },
  onChange: (state) => {
    if (state.isValid) {
      clearErrors()
    }
  }
})
