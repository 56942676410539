import { createSelector } from 'reselect'
import { DateTime } from 'luxon'
import { currentUserSelector } from '../../user/selectors'
import { getEntity } from './base'

export const getCurrentLoyaltyProgramPeriod = createSelector(
  [getEntity('LoyaltyProgramPeriod'), currentUserSelector],
  (periods, userId) =>
    periods.find(
      (period) =>
        period?.get('user') === userId && DateTime.fromISO(period.get('endAt')) > DateTime.local()
    )
)
