import { user as userTools } from '@sellpy/commons'

const {
  PERMISSION_TYPE_EMAIL_MARKETING,
  PERMISSION_TYPE_EXTERNAL_MARKETING,
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_DATA_POLICY,
  PERMISSION_TYPE_LOYALTY_PROGRAM
} = userTools
export {
  PERMISSION_TYPE_ANALYTICS,
  PERMISSION_TYPE_EMAIL_MARKETING,
  PERMISSION_TYPE_EXTERNAL_MARKETING,
  PERMISSION_TYPE_DATA_POLICY,
  PERMISSION_TYPE_LOYALTY_PROGRAM
}
