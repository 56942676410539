import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Icon, P } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { mediaQueries } from '../../../../../../uiComponents/mediaQueries.js'
import { analyticsSearch } from '../../../../../../../common/analytics/actions.js'

export const SuggestionList = styled.ul`
  list-style-type: 'none';
  margin: 0;
  padding: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 100%;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 8px;
  padding: 4px 16px;
  ${mediaQueries.tablet} {
    min-height: 40px;
  }
`

const Item = styled.li`
  display: flex;
  z-index: 2500;
  :hover {
    text-decoration: none;
  }
  &[aria-selected='true'] {
    ${Container} {
      background-color: ${({ theme }) => theme.color.grey.shade9};
      text-decoration: none;
    }
    // Ugly solution to emulate hover effect on selection for chips.
    button {
      background-color: ${({ theme }) => theme.color.blue.shade9};
      border-color: ${({ theme }) => theme.color.blue.shade9};
    }
  }
`

const StyledIcon = styled(Icon)`
  font-size: 24px;
  color: ${({ theme }) => theme.color.grey.shade5};
`

const Link = styled(RouterLink)`
  width: 100%;
  box-sizing: border-box;
  ::first-letter {
    text-transform: capitalize;
  }
  :hover {
    text-decoration: none;
  }
`

const Title = styled(P).attrs({ design: 'body1', noMargin: true })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SuggestionItem = ({ icon, title, subTitle, getItemProps, item, source, children }) => {
  return (
    <Item
      {...getItemProps({
        item,
        source
      })}
    >
      <Link
        to={source.getItemUrl({ item }).url}
        onClick={() => {
          analyticsSearch({
            searchTerm: item.query || item.label || item.brand,
            searchSource: source.sourceId
          })
        }}
      >
        {children || (
          <Container>
            <StyledIcon name={icon} />
            <div>
              <Title>{title}</Title>
              <P design='body5' noMargin>
                {subTitle}
              </P>
            </div>
          </Container>
        )}
      </Link>
    </Item>
  )
}
