import styled from 'styled-components'
import { mediaQueries } from '../../uiComponents/mediaQueries.js'
import Bone from './components/Bone.jsx'

export const PromoBannerBone = styled(Bone)`
  width: 100%;
  background-color: ${({ theme }) => theme.color.blue.shade10};
  border-radius: 0;
  height: 34px;
  transition: height 0.3s;
  display: flex;
  z-index: 200;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${mediaQueries.phone} {
    height: 48px;
  }
`
