import React from 'react'
import styled from 'styled-components'
import { imageWrapperStyle } from '../../uiComponents/header/storeHeader/components/StoreImage.jsx'
import {
  ButtonsWrapper,
  ContentWrapper,
  StoreButtonsWrapper,
  WrapperDiv
} from '../../uiComponents/header/storeHeader/styles.js'
import Bone from './components/Bone.jsx'

const ImageBone = styled(Bone)`
  ${imageWrapperStyle};
`

export const StoreHeaderSkeleton = ({ style }) => {
  return (
    <WrapperDiv style={style}>
      <ImageBone />
      <ContentWrapper>
        <Bone maxWidth={250} height={28} />
        <Bone maxWidth={500} height={64} />
        <ButtonsWrapper>
          <StoreButtonsWrapper style={{ width: '100%' }}>
            <Bone maxWidth={130} height={40} />
            <Bone maxWidth={130} height={40} />
          </StoreButtonsWrapper>
        </ButtonsWrapper>
      </ContentWrapper>
    </WrapperDiv>
  )
}
