import { combineReducers } from 'redux'
import { OrderedMap, Record } from 'immutable'
import * as actions from './actions'

const toasts = (state = OrderedMap(), { type, payload }) => {
  switch (type) {
    case actions.SHOW_TOAST:
      return state.set(payload.key, {
        key: payload.key,
        options: payload.options,
        type: payload.type
      })

    case actions.HIDE_TOAST:
      return state.filter((notification) => notification.key !== payload)

    default:
      return state
  }
}

const p2pHitsState = new Record({
  numberOfHitsShown: 0,
  lastSearchUrl: ''
})

const p2pHits = (state = p2pHitsState(), { type, numberOfHitsShown, lastSearchUrl }) => {
  switch (type) {
    case actions.SET_NUMBER_OF_P2P_HITS_SHOWN:
      return state.set('numberOfHitsShown', numberOfHitsShown)
    case actions.NEW_P2P_SEARCH:
      return state.set('numberOfHitsShown', numberOfHitsShown).set('lastSearchUrl', lastSearchUrl)
    default:
      return state
  }
}

const lightBoxOpen = (state = false, { type, value }) => {
  switch (type) {
    case actions.SET_LIGHTBOX_OPEN:
      return value
    default:
      return state
  }
}

const cookieSettingsModalOpen = (state = false, { type, value }) => {
  switch (type) {
    case actions.SET_COOKIE_SETTINGS_MODAL_OPEN:
      return value
    default:
      return state
  }
}

export default combineReducers({
  toasts,
  p2pHits,
  cookieSettingsModalOpen,
  lightBoxOpen
})
