import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Step, { COLORS, POSITION, STATE } from './step/Step.jsx'

const StepperWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`

const getPosition = ({ stepIndex, totalCount }) => {
  if (stepIndex === 0) return POSITION.FIRST
  if (stepIndex === totalCount - 1) return POSITION.LAST
  return POSITION.MIDDLE
}

const getState = ({ stepIndex, activeIndex }) => {
  if (stepIndex === activeIndex) return STATE.ACTIVE
  return stepIndex < activeIndex ? STATE.DONE : STATE.INACTIVE
}

const MAX_STEP_COUNT = 7

const Stepper = ({ steps, active, color = COLORS.DEFAULT }) => {
  steps = steps.slice(0, MAX_STEP_COUNT)
  active = Math.max(active, -1)
  active = Math.min(active, steps.length)

  const allStepsHaveLabels = steps.every((step) => step.label)
  return (
    <StepperWrapper>
      {steps.map(({ label, icon, to }, index) => (
        <Step
          key={'step_' + index}
          icon={icon || index + 1}
          label={allStepsHaveLabels && label}
          state={getState({ stepIndex: index, activeIndex: active })}
          position={getPosition({ stepIndex: index, totalCount: steps.length })}
          color={color}
          to={to}
        />
      ))}
    </StepperWrapper>
  )
}

const stepArraySizeValidation = (propTypeCheck) => (props, propName, componentName) => {
  if (
    (Array.isArray(props[propName]) && props[propName]?.length < 2) ||
    props[propName]?.length > MAX_STEP_COUNT
  ) {
    return new Error(
      `Invalid number of steps supplied to Stepper. 
      Steppers must contain between 2 and ${MAX_STEP_COUNT} steps.`
    )
  }
  return PropTypes.checkPropTypes(
    {
      [propName]: propTypeCheck
    },
    props,
    propName,
    componentName
  )
}

const activeWithinBounds = (props, propName) => {
  if (typeof props[propName] !== 'number')
    return new Error(`Invalid prop type: ${propName} should be of type 'number'.`)

  if (props[propName] < -1 || props[propName] > props['steps']?.length) {
    return new Error(
      `Step index out of bounds: ${propName} should be a number between -1 and ${props['steps']?.length} (one step before/after the first/last step in the array).\n` +
        `This warning most likely means something has gone wrong when calculating which step should be active.`
    )
  }
}

Stepper.propTypes = {
  steps: stepArraySizeValidation(
    PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        onClick: PropTypes.func
      })
    ).isRequired
  ),
  active: activeWithinBounds
}
export default Stepper
