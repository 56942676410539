import React from 'react'
import styled from 'styled-components'
import { ErrorMessage } from '@hookform/error-message'
import get from 'lodash/get'
import { P } from '@sellpy/design-system-react-web'

const ErrorP = styled(P)`
  color: ${({ theme }) => theme.color.red.default} !important;
`

const StyledP = styled(P)`
  color: ${({ disabled, theme }) => (disabled ? theme.color.grey.shade6 : theme.color.grey.shade3)};
`

const SubLabel = ({ errors, name, label, disabled, className }) => {
  const erroneous = Boolean(get(errors, name))
  return (
    <div className={className}>
      {erroneous && !disabled ? (
        <ErrorMessage errors={errors} name={name} as={<ErrorP design='body3' noMargin />} />
      ) : (
        <StyledP design='body3' noMargin disabled={disabled}>
          {label}
        </StyledP>
      )}
    </div>
  )
}

export default SubLabel
