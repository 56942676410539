export const queries = {
  '& Other Stories': { refinementList: { 'metadata.brand': ['& Other Stories'] } },
  '8848 Altitude': { refinementList: { 'metadata.brand': ['8848 Altitude'] } },
  'Abercrombie & Fitch Kids': {
    refinementList: { 'metadata.brand': ['Abercrombie & Fitch Kids', 'abercrombie KIDS'] }
  },
  'Acne Studios': { refinementList: { 'metadata.brand': ['Acne', 'Acne Jeans', 'Acne Studios'] } },
  Adidas: {
    refinementList: {
      'metadata.brand': [
        'Adidas',
        'Adidas Originals',
        'Adidas Stan Smith',
        'Stella McCartney For Adidas'
      ]
    }
  },
  Apple: { refinementList: { 'metadata.brand': ['Apple'] } },
  Arket: { refinementList: { 'metadata.brand': ['Arket'] } },
  Asos: {
    refinementList: {
      'metadata.brand': [
        'ASOS',
        'ASOS denim',
        'ASOS Petite',
        'Asos Maternity',
        'ASOS Shoes',
        'ASOS Swim',
        'Asos Curve',
        'ASOS Tall'
      ]
    }
  },
  BabyBjörn: { refinementList: { 'metadata.brand': ['BabyBjörn'] } },
  Barbour: { refinementList: { 'metadata.brand': ['Barbour'] } },
  Birkenstock: { refinementList: { 'metadata.brand': ['Birkenstock'] } },
  Bondelid: { refinementList: { 'metadata.brand': ['Bondelid'] } },
  Boomerang: { refinementList: { 'metadata.brand': ['Boomerang'] } },
  Burberry: { refinementList: { 'metadata.brand': ['Burberry'] } },
  'By Malene Birger': { refinementList: { 'metadata.brand': ['By Malene Birger'] } },
  'By Malina Collection': { refinementList: { 'metadata.brand': ['By Malina Collection'] } },
  Bläck: { refinementList: { 'metadata.brand': ['Bläck'] } },
  COS: { refinementList: { 'metadata.brand': ['COS'] } },
  Canon: { refinementList: { 'metadata.brand': ['Canon'] } },
  'Calvin Klein': { refinementList: { 'metadata.brand': ['Calvin Klein', 'Calvin Klein Jeans'] } },
  'Carin Wester': { refinementList: { 'metadata.brand': ['Carin Wester'] } },
  Converse: { refinementList: { 'metadata.brand': ['Converse'] } },
  Craft: { refinementList: { 'metadata.brand': ['Craft'] } },
  Cubus: { refinementList: { 'metadata.brand': ['Cubus'] } },
  Desigual: { refinementList: { 'metadata.brand': ['Desigual'] } },
  'Didriksons 1913': { refinementList: { 'metadata.brand': ['Didriksons 1913'] } },
  'Dr. Martens': { refinementList: { 'metadata.brand': ['Dr. Martens'] } },
  Ellos: { refinementList: { 'metadata.brand': ['Ellos'] } },
  Eton: { refinementList: { 'metadata.brand': ['Eton'] } },
  Everest: { refinementList: { 'metadata.brand': ['Everest'] } },
  'Filippa K': { refinementList: { 'metadata.brand': ['Filippa K'] } },
  'Fred Perry': { refinementList: { 'metadata.brand': ['Fred Perry'] } },
  Fjällräven: { refinementList: { 'metadata.brand': ['Fjällräven'] } },
  GANT: { refinementList: { 'metadata.brand': ['GANT'] } },
  Ganni: { refinementList: { 'metadata.brand': ['Ganni'] } },
  'Gap Kids': { refinementList: { 'metadata.brand': ['Gap Kids'] } },
  Geggamoja: { refinementList: { 'metadata.brand': ['Geggamoja'] } },
  'Gina Tricot': {
    refinementList: {
      'metadata.brand': ['Gina Tricot', 'Perfect Jeans Gina Tricot', 'Gina Tricot Lingerie']
    }
  },
  Gustavsberg: { refinementList: { 'metadata.brand': ['Gustavsberg'] } },
  'H&M': {
    refinementList: {
      'metadata.brand': [
        '&Denim by H&M',
        'Basic By H&M',
        'Divided by H&M',
        'H&M',
        'H&M Basic',
        'H&M Conscious Collection',
        'H&M Kids',
        'H&M Baby',
        'H&M Mama',
        'H&M Modern Classic',
        'H&M Party',
        'H&M Premium Quality',
        'H&M Sport',
        'H&M Studio',
        'H&M Trend',
        'H&M Young',
        'H&M Home',
        'H&M+',
        'L.O.G.G by H&M',
        'The Garden Collection by H&M'
      ]
    }
  },
  '&Denim by H&M': { refinementList: { 'metadata.brand': ['&Denim by H&M'] } },
  'Basic By H&M': { refinementList: { 'metadata.brand': ['Basic By H&M'] } },
  'Divided by H&M': { refinementList: { 'metadata.brand': ['Divided by H&M'] } },
  'H&M Basic': { refinementList: { 'metadata.brand': ['H&M Basic', 'Basic by H&M'] } },
  'H&M Conscious Collection': {
    refinementList: { 'metadata.brand': ['H&M Conscious Collection'] }
  },
  'H&M Conscious': { refinementList: { 'metadata.brand': ['H&M Conscious'] } },
  'H&M Conscious Exclusive': { refinementList: { 'metadata.brand': ['H&M Conscious Exclusive'] } },
  'H&M Kids': { refinementList: { 'metadata.brand': ['H&M Kids', 'H&M Young'] } },
  'H&M Baby': { refinementList: { 'metadata.brand': ['H&M Baby'] } },
  'H&M Mama': { refinementList: { 'metadata.brand': ['H&M Mama'] } },
  'H&M Modern Classic': { refinementList: { 'metadata.brand': ['H&M Modern Classic'] } },
  'H&M Party': { refinementList: { 'metadata.brand': ['H&M Party'] } },
  'H&M Premium Quality': { refinementList: { 'metadata.brand': ['H&M Premium Quality'] } },
  'H&M Sport': { refinementList: { 'metadata.brand': ['H&M Sport'] } },
  'H&M Studio': { refinementList: { 'metadata.brand': ['H&M Studio'] } },
  'H&M Trend': { refinementList: { 'metadata.brand': ['H&M Trend'] } },
  'H&M+': { refinementList: { 'metadata.brand': ['H&M+'] } },
  'L.O.G.G by H&M': { refinementList: { 'metadata.brand': ['L.O.G.G by H&M'] } },
  'The Garden Collection by H&M': {
    refinementList: { 'metadata.brand': ['The Garden Collection by H&M'] }
  },
  'H&M Collabs': {
    refinementList: {
      'metadata.brand': [
        'Sonia Rykiel for H&M',
        'Isabel Marant Pour H&M',
        'Jimmy Choo for H&M',
        'Kenzo X H&M',
        'Morris & Co X H&M',
        'M by Madonna for H&M',
        'Karl Lagerfeld for H&M',
        'Versace for H&M',
        'Matthew Williamson for H&M',
        'Fashion Against AIDS By H&M',
        'Stella McCartney for H&M',
        'Alexander Wang X H&M',
        'Marni at H&M',
        'Balmain By H&M',
        'Roberto Cavalli At H&M',
        'Maison Martin Margiela by H&M',
        'David Beckham By H&M',
        'Anna Glover x H&M',
        'Chiboogi By H&M',
        'Marimekko By H&M',
        'Zara Larsson X H&M',
        'GP & J Baker x H&M',
        'H&M x Moschino',
        'Lanvin x H&M',
        'Viktor & Rolf x H&M'
      ]
    }
  },
  'Sonia Rykiel for H&M': { refinementList: { 'metadata.brand': ['Sonia Rykiel for H&M'] } },
  'Isabel Marant Pour H&M': { refinementList: { 'metadata.brand': ['Isabel Marant Pour H&M'] } },
  'Jimmy Choo for H&M': { refinementList: { 'metadata.brand': ['Jimmy Choo for H&M'] } },
  'Kenzo X H&M': { refinementList: { 'metadata.brand': ['Kenzo X H&M'] } },
  'Morris & Co X H&M': { refinementList: { 'metadata.brand': ['Morris & Co X H&M'] } },
  'M by Madonna for H&M': { refinementList: { 'metadata.brand': ['M by Madonna for H&M'] } },
  'Karl Lagerfeld for H&M': { refinementList: { 'metadata.brand': ['Karl Lagerfeld for H&M'] } },
  'Versace for H&M': { refinementList: { 'metadata.brand': ['Versace for H&M'] } },
  'Matthew Williamson for H&M': {
    refinementList: { 'metadata.brand': ['Matthew Williamson for H&M'] }
  },
  'Fashion Against AIDS By H&M': {
    refinementList: { 'metadata.brand': ['Fashion Against AIDS By H&M'] }
  },
  'Stella McCartney for H&M': {
    refinementList: { 'metadata.brand': ['Stella McCartney for H&M'] }
  },
  'Alexander Wang X H&M': { refinementList: { 'metadata.brand': ['Alexander Wang X H&M'] } },
  'Marni at H&M': { refinementList: { 'metadata.brand': ['Marni at H&M'] } },
  'Balmain By H&M': { refinementList: { 'metadata.brand': ['Balmain By H&M'] } },
  'Roberto Cavalli At H&M': { refinementList: { 'metadata.brand': ['Roberto Cavalli At H&M'] } },
  'Maison Martin Margiela by H&M': {
    refinementList: { 'metadata.brand': ['Maison Martin Margiela by H&M'] }
  },
  'David Beckham By H&M': { refinementList: { 'metadata.brand': ['David Beckham By H&M'] } },
  'Anna Glover x H&M': { refinementList: { 'metadata.brand': ['Anna Glover x H&M'] } },
  'Chiboogi By H&M': { refinementList: { 'metadata.brand': ['Chiboogi By H&M'] } },
  'Marimekko By H&M': { refinementList: { 'metadata.brand': ['Marimekko By H&M'] } },
  'Zara Larsson X H&M': { refinementList: { 'metadata.brand': ['Zara Larsson X H&M'] } },
  'GP & J Baker x H&M': { refinementList: { 'metadata.brand': ['GP & J Baker x H&M'] } },
  'H&M x Moschino': { refinementList: { 'metadata.brand': ['H&M x Moschino'] } },
  'Lanvin x H&M': { refinementList: { 'metadata.brand': ['Lanvin x H&M'] } },
  'Viktor & Rolf x H&M': { refinementList: { 'metadata.brand': ['Viktor & Rolf x H&M'] } },
  'H&M Home': { refinementList: { 'metadata.brand': ['H&M Home'] } },
  'H&M Herr': { refinementList: { 'metadata.brand': ['H&M Herr'] } },
  Haglöfs: { refinementList: { 'metadata.brand': ['Haglöfs'] } },
  Hope: { refinementList: { 'metadata.brand': ['Hope', 'HOPE by Ringstrand Söderberg'] } },
  'Höganäs Keramik': { refinementList: { 'metadata.brand': ['Höganäs', 'Höganäs Keramik'] } },
  IKEA: { refinementList: { 'metadata.brand': ['IKEA'] } },
  Iittala: { refinementList: { 'metadata.brand': ['Iittala'] } },
  Indiska: { refinementList: { 'metadata.brand': ['Indiska'] } },
  'Jack & Jones': {
    refinementList: {
      'metadata.brand': [
        'Jack & Jones',
        'Premium by Jack & Jones',
        'Originals by Jack & Jones',
        'Core By Jack & Jones',
        'Jack & Jones Premium',
        'Jack & Jones Vintage',
        'Jack & Jones Core',
        'Jack & Jones Jeans Intelligence'
      ]
    }
  },
  'J.Lindeberg': { refinementList: { 'metadata.brand': ['J.Lindeberg'] } },
  KappAhl: {
    refinementList: {
      'metadata.brand': [
        'KappAhl',
        'Newbie By Kappahl',
        'Design by Kappahl',
        'XLNT by KappAhl',
        'Lab Industries by Kappahl',
        'Kaxs By Kappahl',
        'Denim Co. by Kappahl',
        'Woxo by Kappahl',
        'Vintage Stories by KappAhl',
        'Bodyzone by KappAhl',
        'Number One By KappAhl',
        'K by KappAhl',
        'WOXO 720 by Kappahl',
        'We Kids By Kappahl',
        'Girls By Kappahl',
        'WeTweens By Kappahl',
        'Baby By Kappahl',
        'Ü by Kappahl',
        'No 1 by Kappahl',
        'BabyBaby By Kappahl'
      ]
    }
  },
  'Kosta Boda': { refinementList: { 'metadata.brand': ['Kosta Boda'] } },
  Lagerhaus: { refinementList: { 'metadata.brand': ['Lagerhaus'] } },
  Lee: { refinementList: { 'metadata.brand': ['Lee'] } },
  'Levi Strauss & Co': { refinementList: { 'metadata.brand': ['Levi Strauss & Co', 'Levis'] } },
  Lexington: { refinementList: { 'metadata.brand': ['Lexington'] } },
  Lindex: {
    refinementList: {
      'metadata.brand': [
        'Lindex',
        'Holly & Whyte by Lindex',
        'Lindex Kids',
        'Kids By Lindex',
        'Out Wear By Lindex',
        'Denim By Lindex',
        'Baby by Lindex',
        'Generous by Lindex',
        'Lindex Baby',
        'Detroit by Lindex',
        'Bamse By Lindex',
        'New Born By Lindex',
        'Rain Wear By Lindex',
        'Softwear By Lindex'
      ]
    }
  },
  'Lindex Kids': {
    refinementList: {
      'metadata.brand': ['Baby by Lindex', 'Bamse By Lindex', 'Kids By Lindex', 'Lindex Kids']
    }
  },
  Livly: { refinementList: { 'metadata.brand': ['Livly'] } },
  'Lyle & Scott': { refinementList: { 'metadata.brand': ['Lyle & Scott'] } },
  Marimekko: { refinementList: { 'metadata.brand': ['Marimekko'] } },
  'Massimo Dutti': { refinementList: { 'metadata.brand': ['Massimo Dutti'] } },
  'Me&i': { refinementList: { 'metadata.brand': ['Me&i'] } },
  'Michael Kors': { refinementList: { 'metadata.brand': ['Michael Kors'] } },
  'Mini Rodini': { refinementList: { 'metadata.brand': ['Mini Rodini'] } },
  Monki: { refinementList: { 'metadata.brand': ['Monki'] } },
  NLY: {
    refinementList: { 'metadata.brand': ['NLY', 'NLY Eve', 'NLY One', 'NLY Shoes', 'NLY Trend'] }
  },
  Newbie: { refinementList: { 'metadata.brand': ['Newbie', 'Newbie By Kappahl'] } },
  Nike: {
    refinementList: {
      'metadata.brand': [
        'Nike',
        'Nike Air',
        'Nike Air Max',
        'Nike Dri Fit',
        'Nike Golf',
        'Nike Pro',
        'Nike Running'
      ]
    }
  },
  'Nudie Jeans': { refinementList: { 'metadata.brand': ['Nudie Jeans'] } },
  Nikon: { refinementList: { 'metadata.brand': ['Nikon'] } },
  'Odd Molly': { refinementList: { 'metadata.brand': ['Odd Molly'] } },
  Orrefors: { refinementList: { 'metadata.brand': ['Orrefors'] } },
  'Our Legacy': { refinementList: { 'metadata.brand': ['Our Legacy'] } },
  POMPdeLux: { refinementList: { 'metadata.brand': ['POMPdeLux'] } },
  'Peak Performance': { refinementList: { 'metadata.brand': ['Peak Performance'] } },
  Philips: { refinementList: { 'metadata.brand': ['Philips'] } },
  'Polarn O. Pyret': { refinementList: { 'metadata.brand': ['Polarn O. Pyret'] } },
  Puma: { refinementList: { 'metadata.brand': ['Puma'] } },
  'Ralph Lauren': {
    refinementList: {
      'metadata.brand': [
        'Denim & Supply Ralph Lauren',
        'Lauren Ralph Lauren',
        'Polo Jeans Company Ralph Lauren',
        'Polo Ralph Lauren',
        'Polo Sport Ralph Lauren',
        'Ralph Lauren',
        'Ralph Lauren Sport'
      ]
    }
  },
  Riley: { refinementList: { 'metadata.brand': ['Riley'] } },
  Rodebjer: { refinementList: { 'metadata.brand': ['Rodebjer'] } },
  'Rose & Born': { refinementList: { 'metadata.brand': ['Rose & Born'] } },
  Rörstrand: { refinementList: { 'metadata.brand': ['Rörstrand'] } },
  Röhnisch: { refinementList: { 'metadata.brand': ['Röhnisch'] } },
  'Samsøe & Samsøe': { refinementList: { 'metadata.brand': ['Samsøe & Samsøe'] } },
  Skultuna: { refinementList: { 'metadata.brand': ['Skultuna'] } },
  Saucony: { refinementList: { 'metadata.brand': ['Saucony'] } },
  Superdry: { refinementList: { 'metadata.brand': ['Superdry', 'Superdry Vintage'] } },
  Sony: { refinementList: { 'metadata.brand': ['Sony'] } },
  'Tiger of Sweden': {
    refinementList: { 'metadata.brand': ['Tiger of Sweden', 'Tiger of Sweden Jeans'] }
  },
  'Tommy Hilfiger': { refinementList: { 'metadata.brand': ['Tommy Hilfiger'] } },
  Uniqlo: { refinementList: { 'metadata.brand': ['Uniqlo'] } },
  Villervalla: { refinementList: { 'metadata.brand': ['Villervalla'] } },
  WHYRED: { refinementList: { 'metadata.brand': ['WHYRED'] } },
  Weekday: { refinementList: { 'metadata.brand': ['MTWTFSS WEEKDAY', 'Weekday'] } },
  Zara: {
    refinementList: {
      'metadata.brand': [
        'Zara',
        'Zara Accessories',
        'Zara Basic',
        'Zara Basic Collection',
        'Zara Basic Denim',
        'Zara Basic Outerwear',
        'Zara Collection',
        'Zara Denim',
        'Zara Knit',
        'Zara Knitwear',
        'Zara Man',
        'Zara Man Basic',
        'Zara Trafaluc',
        'Zara Trafaluc Collection',
        'ZARA Trafaluc outerwear',
        'Zara W&B Collection',
        'Zara Woman',
        'Zara Woman Premium Denim Collection'
      ]
    }
  },
  'Zara Home': { refinementList: { 'metadata.brand': ['Zara Home'] } },
  'Zara Kids': {
    refinementList: {
      'metadata.brand': [
        'Zara',
        'Zara Baby',
        'Zara Babygirl',
        'Zara Boys',
        'Zara Boys Collection',
        'Zara Girls',
        'Zara Girls',
        'Zara Mini',
        'Zara Kids'
      ]
    }
  },
  Åhléns: { refinementList: { 'metadata.brand': ['Åhléns'] } },
  // CATEGORIES
  'TV-Spel': { hierarchicalMenu: { 'categories.lvl0': ['TV-spel & Datorspel'] } },
  Sällskapsspel: { hierarchicalMenu: { 'categories.lvl0': ['Hobby > Sällskapsspel'] } },
  Filmer: { hierarchicalMenu: { 'categories.lvl0': ['DVD & Videofilmer'] } },
  Pussel: { hierarchicalMenu: { 'categories.lvl0': ['Hobby > Pussel'] } },
  Böcker: { hierarchicalMenu: { 'categories.lvl0': ['Böcker & Tidningar'] } },
  Musik: { hierarchicalMenu: { 'categories.lvl0': ['Musik'] } },
  Porslin: {
    hierarchicalMenu: { 'categories.lvl0': ['Hem & Hushåll > Hushållsartiklar > Porslin'] }
  },
  Glas: { hierarchicalMenu: { 'categories.lvl0': ['Hem & Hushåll > Hushållsartiklar > Glas'] } },
  Köksredskap: {
    hierarchicalMenu: { 'categories.lvl0': ['Hem & Hushåll > Hushållsartiklar > Köksredskap'] }
  },
  // 'Muggar & Koppar': { hierarchicalMenu: { 'categories.lvl0': ['DVD & Videofilmer'] } },
  Hushållsmaskiner: {
    hierarchicalMenu: { 'categories.lvl0': ['Hem & Hushåll > Hushållsmaskiner'] }
  },
  Textilier: { hierarchicalMenu: { 'categories.lvl0': ['Hem & Hushåll > Hemtextil'] } }
}
