import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  --shimmer-width: 200px;
  --shimmer-pos-left: calc(-1 * (100% + var(--shimmer-width)));
  --shimmer-pos-right: calc(100% + var(--shimmer-width));
`

const shimmerAnimation = keyframes`
  from {
    transform: translateX(var(--shimmer-pos-left));
    opacity: 0.1;
  }
  21% {
    transform: translateX(var(--shimmer-pos-left));
  }
  30.5% {
    opacity: 0.5;
  }
  48% {
    transform: translateX(var(--shimmer-pos-right));
    opacity: 0.1;
  }
  to {
    transform: translateX(var(--shimmer-pos-right));
    opacity: 0.1;
  }
`

const shadowAnimation = keyframes`
  21% {
    opacity: 0;
  }
  30.5% {
    opacity:0.1;
  }
  48% {
    opacity: 0;
  }
`

const ShimmerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  transform: translateX(-100vw);
  opacity: 0.1;

  will-change: transform;
  animation: ${shimmerAnimation} 7s 1s cubic-bezier(0.2, 0.5, 0.8, 0.5) infinite;
`

const Shimmer = styled.div`
  position: absolute;
  background-color: white;
  height: 100%;
  width: var(--shimmer-width);
  left: 50%;
  transform: skewX(-10deg) translateX(-50%);
`

const Depth = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  animation: ${shadowAnimation} 7s 1s ease-in-out infinite;
`

const ShimmerAnimation = () => (
  <Wrapper>
    <ShimmerWrapper>
      <Shimmer />
    </ShimmerWrapper>
    <Depth />
  </Wrapper>
)

export default ShimmerAnimation
