import React, { useState, useEffect } from 'react'

const LoadingDots = ({ size, ...props }) => {
  const type = size === 'small' ? '.' : '●'
  const [dots, setDots] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      const newDots = (dots + 1) % (size === 'small' ? 4 : 6) || 1
      setDots(newDots)
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [dots, size])

  return (
    <span {...props}>
      <span>{type.repeat(dots)}</span>
      <span style={{ color: 'transparent' }}>{type.repeat(5 - dots)}</span>
    </span>
  )
}

export default LoadingDots
