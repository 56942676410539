import { createSelector } from 'reselect'
import { balance, payout } from '@sellpy/commons'
import { List } from 'immutable'

import { currentUserSelector } from '../user/selectors'
import { getEntity } from '../entities/selectors/base'
const { PAYOUT_STATUS } = payout

// TODO remove when not used to set initial value for circle items price. Balance transactions should not be fetched for buyers only for own items.
export const itemBalanceTransactionsSelector = createSelector(
  [getEntity('BalanceTransaction'), (_, itemId) => itemId],
  (balanceTransactions, itemId) =>
    balanceTransactions.filter((balanceTransaction) => balanceTransaction.get('item') === itemId)
)

export const totalBalanceSelector = (state) => state.balance.totalBalance
export const totalBalanceSelectorWithoutReservedAdFees = (state) =>
  state.balance.totalBalanceWithoutReservedAdFees

const ownBalanceTransactionsSelector = createSelector(
  [getEntity('BalanceTransaction'), currentUserSelector],
  (balanceTransactions, userId) =>
    balanceTransactions.toList().filter((transaction) => transaction.get('user') === userId)
)

export const ownRegularItemBalanceTransactionsSelector = createSelector(
  [ownBalanceTransactionsSelector, (_, itemId) => itemId],
  (balanceTransactions, itemId) => {
    const balanceTransactionsWithPossibleDuplicates = balanceTransactions
      .filter((balanceTransaction) => balanceTransaction.get('item') === itemId)
      .filter((balanceTransaction) =>
        balance.TRANSACTION_TYPES_CONNECTED_TO_REGULAR_ITEMS.includes(
          balanceTransaction.get('type')
        )
      )
    return List(
      balance.TRANSACTION_TYPES_CONNECTED_TO_REGULAR_ITEMS.map((transactionType) =>
        balanceTransactionsWithPossibleDuplicates
          .filter((balanceTransaction) => balanceTransaction.get('type') === transactionType)
          .reduce((latestTransaction, transaction) =>
            latestTransaction.get('createdAt') > transaction.get('createdAt')
              ? latestTransaction
              : transaction
          )
      )
    ).filter(Boolean)
  }
)

export const ownP2pItemBalanceTransactionsSelector = createSelector(
  [ownBalanceTransactionsSelector, (_, itemId) => itemId],
  (balanceTransactions, itemId) =>
    balanceTransactions
      .filter((transaction) =>
        balance.TRANSACTION_TYPES_CONNECTED_TO_P2P.includes(transaction.get('type'))
      )
      .filter((transaction) => !itemId || transaction.get('item') === itemId)
)

export const ownReferralBalanceTransactionsSelector = createSelector(
  [ownBalanceTransactionsSelector, getEntity('Referral')],
  (transactions, referrals) =>
    transactions
      .filter((transaction) => referrals.get(transaction.get('referral')))
      .map((transaction) => transaction.set('referral', referrals.get(transaction.get('referral'))))
)

export const ownSoldItemBalanceReductionSelector = createSelector(
  [ownBalanceTransactionsSelector, (_, itemId) => itemId],
  (transactions, itemId) => {
    return transactions.filter(
      (transaction) =>
        transaction.get('item') === itemId &&
        (transaction.get('type') === balance.TRANSACTION_TYPES.ITEM_CLAIMED_SOLD_REDUCTION ||
          transaction.get('type') === balance.TRANSACTION_TYPES.P2P_ITEM_CLAIMED_SOLD_REDUCTION)
    )
  }
)

export const ownPayOutSelector = createSelector(
  [getEntity('PayOut'), (_, payoutId) => payoutId, currentUserSelector],
  (payouts, payoutId, userId) =>
    payouts
      .filter((payout) => payout.get('objectId') === payoutId)
      .filter((payout) => payout.get('user') === userId)
      .first()
)

export const ownPayOutAwaitingVerificationSelector = createSelector(
  [getEntity('PayOut'), currentUserSelector],
  (payouts, userId) =>
    payouts
      .filter((payout) => payout.get('user') === userId)
      .filter((payout) => payout.get('status') === PAYOUT_STATUS.AWAITING_VERIFICATION)
      .first()
)

export const balanceDetailsSelector = createSelector(
  [(state) => state.balance.balanceDetails],
  (events) => {
    return events.flatten(1)
  }
)

export const currentBalancePageSelector = createSelector(
  [(state) => state.balance.balanceDetails],
  (events) => {
    return events.size
  }
)

export const allBalanceDataFetchedSelector = createSelector(
  [(state) => state.balance.balanceDetails],
  (events) => {
    return events.get(events.size - 1)?.size === 0
  }
)
