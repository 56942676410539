import { useEffect, useState } from 'react'
import { runCloudCached } from '../../common/lib/parseTools'

export const useMIV = () => {
  const [MIV, setMIV] = useState()

  useEffect(() => {
    runCloudCached()('getMIV').then((MIV) => setMIV(MIV))
  }, [setMIV])

  return { MIV }
}
