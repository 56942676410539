import React from 'react'
import { A, Grid, RouterLink } from '@sellpy/design-system-react-web'
import { RightContent, LeftContent, ButtonsContainer, SlabButton } from './styles'

const PlainSlab = ({
  heading,
  body,
  buttons,
  rightContent,
  mirrored,
  rightContentToBottom,
  className
}) => {
  return (
    <Grid className={className}>
      <LeftContent mirrored={mirrored} rightContentToBottom={rightContentToBottom}>
        {heading}
        <div style={{ marginBottom: '-1rem' }}>{body}</div>
        <ButtonsContainer>{buttons}</ButtonsContainer>
      </LeftContent>
      <RightContent mirrored={mirrored} rightContentToBottom={rightContentToBottom}>
        {rightContent}
      </RightContent>
    </Grid>
  )
}

export const PlainSlabButton = ({ label, link, ...rest }) => {
  const button = <SlabButton label={label} {...rest} />
  if (link && link.includes('https')) {
    return (
      <A noMargin href={link} target='_blank' rel='noopener noreferrer'>
        {button}
      </A>
    )
  }

  if (link)
    return (
      <RouterLink noMargin to={link}>
        {button}
      </RouterLink>
    )

  return button
}

export default PlainSlab
