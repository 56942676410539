import React from 'react'
import { ICONS, Icon, P } from '@sellpy/design-system-react-web'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const COLORS = {
  DEFAULT: 'black',
  BLACK: 'black',
  BLUE: 'blue'
}

export const POSITION = {
  FIRST: 'first',
  MIDDLE: 'middle',
  LAST: 'last'
}

export const STATE = {
  DONE: 'done',
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const mainStepColor = (theme, color) => {
  return {
    [COLORS.DEFAULT]: theme.color.black.default,
    [COLORS.BLACK]: theme.color.black.default,
    [COLORS.BLUE]: theme.color.blue.default
  }[color]
}

const barColor = ({ theme, full, $color }) =>
  full ? mainStepColor(theme, $color) : theme.color.grey.shade8
const iconColor = ({ theme, state }) =>
  state === STATE.INACTIVE ? theme.color.grey.shade6 : theme.color.white.default
const iconBackgroundColor = ({ theme, state, $color }) =>
  state === STATE.INACTIVE ? theme.color.grey.shade8 : mainStepColor(theme, $color)
const textColor = ({ theme, state, $color }) =>
  state === STATE.INACTIVE ? theme.color.grey.shade6 : mainStepColor(theme, $color)

const StepBar = styled.div`
  background-color: ${barColor};
  grid-column: ${({ side }) => (side === 'left' ? 'step-start / middle' : 'middle / step-end')};

  width: 100%;
  height: 4px;
`

// this component is also sometimes rendered as a Link, see actual render call for more info
const StepWrapper = styled.div`
  display: grid;
  height: 48px;
  width: 100%;
  grid-template-columns: [step-start] 1fr [middle] 1fr [step-end];
  grid-template-rows: ${({ $hasLabel }) => ($hasLabel ? '1fr 1fr' : '1fr')};
  align-items: center;
  justify-content: center;
  & > div {
    grid-row: 1;
  }

  cursor: default;
  user-select: none;

  ${({ to }) =>
    Boolean(to) &&
    `
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  `}
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  grid-column: step-start / step-end;
`

const StepSymbolStyle = css`
  background-color: ${iconBackgroundColor};
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 0px;
  text-align: center;
  ${({ $hasLabel }) =>
    $hasLabel
      ? `
    font-size: 14px;
    line-height: 24px;
    height: 24px;
  `
      : `
    font-size: 28px;
    line-height: 48px;
    height: 48px;
  `}
  color: ${iconColor};
`

const StyledNumber = styled(P).attrs({ design: 'body2', noMargin: true })`
  ${StepSymbolStyle}
`
const StyledIcon = styled(Icon)`
  ${StepSymbolStyle}
`

const StepSymbol = ({ icon, state, $hasLabel, $color }) => {
  if (state === STATE.DONE) icon = ICONS.CHECKMARK

  return typeof icon === 'number' ? (
    <StyledNumber state={state} $hasLabel={$hasLabel} $color={$color}>
      {icon}
    </StyledNumber>
  ) : (
    <StyledIcon name={icon} state={state} $hasLabel={$hasLabel} $color={$color} />
  )
}

const Label = styled(P).attrs({
  design: 'body4',
  noMargin: true
})`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  grid-row: 2;
  grid-column: step-start / step-end;
  color: ${textColor};
`

const Step = ({ icon, position, state, label, color, to }) => {
  const $hasLabel = Boolean(label)
  return (
    <StepWrapper
      $hasLabel={$hasLabel}
      to={state === STATE.DONE ? to : undefined}
      as={state === STATE.DONE && Boolean(to) && Link} // for more info: https://styled-components.com/docs/api#as-polymorphic-prop
      aria-current={state === STATE.DONE ? 'step' : undefined}
    >
      {position !== POSITION.FIRST && (
        <StepBar full={state !== STATE.INACTIVE} side='left' $color={color} />
      )}
      {position !== POSITION.LAST && (
        <StepBar full={state === STATE.DONE} side='right' $color={color} />
      )}
      <IconContainer>
        <StepSymbol icon={icon} state={state} $hasLabel={$hasLabel} $color={color} />
      </IconContainer>
      {$hasLabel && (
        <Label state={state} $color={color}>
          {label}
        </Label>
      )}
    </StepWrapper>
  )
}

export default Step
