import { ITEM_STATUS } from '@sellpy/commons'

export const isItemSold = (status) => {
  return status === 'betald' || status === 'skickad' || status === 'hämtad'
}

export const isItemAvailable = (status) => {
  return status === 'utlagd'
}

export const isItemUnavailable = (status) => {
  return status === 'ej såld' || status === 'skänkt'
}

export const itemStateMap = {
  vilande: 'pendingSale',
  registrerad: 'pendingSale',
  försäljningBegärd: 'pendingSale',
  ombedömningBegärd: 'pendingReevaluation',
  utlagd: 'ongoingSale',
  såld: 'pendingPayment',
  utsorterad: 'rejected',
  betald: 'sold',
  hämtad: 'sold',
  skickad: 'sold',
  skänkt: 'donated',
  'ej såld': 'donated',
  skänktDirekt: 'donated',
  tillbakaskickad: 'sellerReturned',
  tillbakaskickadDirekt: 'sellerReturned',
  awaitingApproval: 'awaitingApproval',
  reklamerat: 'claimed'
}

export const itemStateToStatuses = Object.keys(itemStateMap).reduce((acc, key) => {
  const value = itemStateMap[key]
  const list = acc[value] || []
  acc[value] = [...list, key]
  return acc
}, {})

export const ITEM_STATUS_MAP = {
  awaitingApproval: [ITEM_STATUS.AWAITING_APPROVAL],
  published: [ITEM_STATUS.UTLAGD],
  sortedForRecycling: [ITEM_STATUS.UTSORTERAD],
  sold: [ITEM_STATUS.BETALD],
  reEvaluationRequested: [ITEM_STATUS.OMBEDÖMNING_BEGÄRD],
  processing: [ITEM_STATUS.REGISTRERAD, ITEM_STATUS.VILANDE],
  donated: [ITEM_STATUS.SKÄNKT, ITEM_STATUS.SKÄNKT_DIREKT],
  sentBack: [ITEM_STATUS.TILLBAKASKICKAD, ITEM_STATUS.TILLBAKASKICKAD_DIREKT]
  // ITEM_STATUS.FÖRSÄLJNING_BEGÄRD is missing , should be in processing
}
