import { money } from '@sellpy/commons'
import { ICONS, Icon, P, Span } from '@sellpy/design-system-react-web'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

/**
 * This entire component is largely based on the design system checkbox.
 * If a bug appears, check if it's already been fixed in the DS,
 * and if not: consider whether the fix should be forwarded to the DS.
 */

const _defaultLabelColor = ({ disabled, theme }) =>
  disabled ? theme.color.grey.shade6 : theme.color.grey.shade2

const _defaultFilledColor = ({ disabled, erroneous, theme }) =>
  disabled
    ? theme.color.grey.shade4
    : erroneous
    ? theme.color.red.default
    : theme.color.blue.default

const CheckboxWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey.shade10 : theme.color.grey.shade9};
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  justify-content: stretch;
  align-items: center;
  min-height: 40px;
  width: 100%;
`

const StyledLabel = styled(P)`
  color: ${_defaultLabelColor};
`

const LabelWrapper = styled.div`
  padding: 8px 8px 8px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledInput = styled.input.attrs({
  type: 'checkbox'
})`
  --icon-check-box: '\\E8B9';
  --icon-check-box-checked-filled: '\\E8B1';
  appearance: none;
  border: none;
  font-size: 24px;
  line-height: 24px;
  width: 40px;
  height: 40px;
  font-family: 'fontello';
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  color: ${({ erroneous, theme }) =>
    erroneous ? theme.color.red.default : theme.color.grey.shade6};

  ::before {
    /* Checkbox icon */
    content: var(--icon-check-box);
    position: relative;
    visibility: visible;
    top: 8px;
    padding: 8px;
  }

  &:checked {
    color: ${_defaultFilledColor};
    ::before {
      /* Checkbox icon */
      content: var(--icon-check-box-checked-filled);
    }
  }
`
const SellpyIcon = styled(Icon).attrs({
  name: ICONS.SELLPY_CIRCLE_FILLED
})`
  font-size: 24px;
  color: ${({ theme }) => theme.color.blue.default};
`

// eslint-disable-next-line react/display-name
const CreditsCheckbox = React.forwardRef(
  (
    {
      disabled,
      onChange,
      userTotalBalance,
      maxCreditAmount,
      creditsInSubUnits,
      ...hookFormFieldProps
    },
    ref
  ) => {
    const theme = useTheme()
    const { t } = useTranslation('adyenSessionPayment')
    return (
      <CheckboxWrapper disabled={disabled}>
        {/* 2024-04
            In Firefox v124, setting the disabled prop on the input tag resulted in Hook Form
            resetting it's value for 'useCredit' when disabled changed true -> false.
            This in turn leads to another useEffect trigger in AdyenSessionPayment without
            credits selected. This hack with rendering a "dumb" checkbox when disabled solved the issue
        */}
        {disabled ? (
          <StyledInput disabled />
        ) : (
          <StyledInput ref={ref} onChange={onChange} value='' {...hookFormFieldProps} />
        )}
        <LabelWrapper>
          <div>
            <StyledLabel design='body1' noMargin disabled={disabled}>
              <Trans
                i18nKey='adyenSessionPayment:credits.checkbox.label'
                values={money.toBaseUnit(creditsInSubUnits)}
              >
                part1
                <P inline design='body2' noMargin>
                  part2
                </P>
              </Trans>
            </StyledLabel>
            {money.toBaseUnit(maxCreditAmount).amount >= 1 && (
              <Span noMargin design={'body5'} style={{ color: theme.color.grey.shade3 }}>
                {t('credits.checkbox.subLabelWithCredits', {
                  amount: money.formatSubUnit(maxCreditAmount),
                  totalAmount: money.formatSubUnit(creditsInSubUnits)
                })}
              </Span>
            )}
          </div>
          <SellpyIcon />
        </LabelWrapper>
      </CheckboxWrapper>
    )
  }
)

export default CreditsCheckbox
