import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Button, Grid, H2, P, ResponsiveImage } from '@sellpy/design-system-react-web'
import { staticImageAssets } from 'config'
import LazyLoad from '../../../../components/LazyLoad.jsx'
import { Background } from '../styles.js'
import { mediaQueries } from '../../../mediaQueries'

const StyledGrid = styled(Grid)`
  grid-template-rows: 1fr 3fr;
  ${mediaQueries.tablet} {
    grid-template-rows: auto auto auto;
  }
`
const Input = styled.div`
  background: ${({ theme }) => theme.color.grey.shade10};
  grid-column: 6 / span 4;
  grid-row: 2 / span 1;
  padding: calc((100vw - 6rem - 22rem) / 24);
  z-index: 0;
  ${mediaQueries.tablet} {
    grid-column: 1 / span 12;
    grid-row: 2 / span 2;
    padding: 2rem;
  }
`
const Text = styled.div`
  grid-column: 1 / span 5;
  grid-row: 2 / span 1;
  padding: 0 calc((100vw - 6rem - 22rem) / 24);
  ${mediaQueries.tablet} {
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
    padding: 4rem 2rem;
  }
`

const ImageWrapper = styled.div`
  height: 100%;
  grid-column: 8 / span 5;
  grid-row: 1 / span 2;
  ${mediaQueries.tablet} {
    display: none;
  }
`

const TextButton = styled(Button)`
  margin: 1rem 0;
  ${mediaQueries.tablet} {
    display: none;
  }
`
const InputButton = styled(Button)`
  margin: 1rem 0;
  display: none;
  ${mediaQueries.tablet} {
    display: block;
  }
`

const InputSlabWithImage = ({
  color = 'blue',
  imageURL,
  headline,
  body,
  inputComponent,
  buttonLabel,
  onClick
}) => {
  const theme = useTheme()
  return (
    <StyledGrid>
      <Background color={color} />
      <Text>
        <H2 noMargin={!body}>{headline}</H2>
        <P design='body3' noMargin>
          {body}
        </P>
        {buttonLabel && <TextButton label={buttonLabel} onClick={onClick} />}
      </Text>
      <Input>
        {inputComponent}
        {buttonLabel && <InputButton label={buttonLabel} onClick={onClick} />}
      </Input>
      <ImageWrapper>
        <LazyLoad>
          <div style={{ height: '100%' }}>
            <ResponsiveImage
              style={{
                minWidth: `calc(100% + ${theme.layout.standardPaddingDesktop})`,
                height: '100%',
                width: '100%',
                objectFit: 'cover'
              }}
              src={staticImageAssets + imageURL}
              mobileWidth='90vw'
              desktopWidth='40vw'
            />
          </div>
        </LazyLoad>
      </ImageWrapper>
    </StyledGrid>
  )
}

export default InputSlabWithImage
