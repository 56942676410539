import React from 'react'
import styled from 'styled-components'

const NotificationContainer = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  background-color: ${({ badgeColor, theme }) => badgeColor || theme.color.red.shade5};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 12px;
    color: white;
    font-family: ${({ theme }) => theme.text.fonts.body};
  }
`

const NotificationBadge = ({ count, badgeColor, className }) => {
  return (
    <NotificationContainer badgeColor={badgeColor} className={className}>
      <span>{count}</span>
    </NotificationContainer>
  )
}

export default NotificationBadge
