import { Record, Map } from 'immutable'
import * as actions from './actions'

const InitialState = Record({
  estimatedSellability: undefined,
  assumedProfit: new Map({ percentage: 0, value: 0 }),
  mapDrawerResult: undefined,
  category: undefined
})
const initialState = new InitialState()

export default (state = initialState, action) => {
  if (!(state instanceof InitialState)) return initialState
  switch (action.type) {
    case actions.GET_ITEM_SELLABILITY_ESTIMATE_SUCCESS:
      return state.set('estimatedSellability', action.payload)
    case actions.GET_ITEM_SELLABILITY_ESTIMATE_ERROR:
      return state.set('estimatedSellability', null)
    case actions.CACHE_MAP_DRAWER_RESULT:
      return state.set('mapDrawerResult', action.result)
  }
  return state
}
