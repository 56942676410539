import styled from 'styled-components'
import { Icon } from '@sellpy/design-system-react-web'
import { RouterLink, SIZES, Span } from '../../../uiComponents'
import { mediaQueries } from '../../../uiComponents/mediaQueries'

export const StyledRouterLink = styled(RouterLink)`
  padding: 1.4rem 1rem;
  border: none;
  display: block;
  color: ${({ color, theme }) => (color ? theme.color[color].default : theme.color.grey.shade3)};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    text-decoration: none;
    ${mediaQueries.largerThanTablet} {
      background-color: ${({ theme }) => theme.color.grey.shade9};
    }
  }
`

export const ListItem = styled.div`
  border: none;
  ${mediaQueries.largerThanTablet} {
    &:hover {
      & > * {
        background-color: ${({ theme }) => theme.color.grey.shade9};
      }
    }
  }
`

export const ListIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  margin-right: 16px;
`

export const CategoryIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  margin-right: 8px;
`

export const StyledButton = styled.button`
  display: flex;
  border: none;
  padding: 1rem 1rem;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.grey.shade2};
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-left: none;
  border-right: none;
  text-align: start;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    text-decoration: none;
  }
`

export const StyledCategoryButton = styled.button`
  font-family: ${({ theme }) => theme.text.fonts.body};
  font-weight: 500;
  outline: none;
  width: 100%;
  margin: 0;
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  color: ${({ theme }) => theme.color.grey.shade3};
  font-size: ${({ theme }) => `${theme.sizes[SIZES.EXTRA_LARGE]}px`};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.grey.shade10};
  padding: 1.5rem 1rem;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: ${({ theme }) => theme.color.grey.shade9};
  }
`

export const ArrowWrapper = styled.div`
  display: flex;
  height: 1rem;
  width: 1rem;
  margin-left: auto;
`
export const StyledSpan = styled(Span)`
  display: flex;
  flex-direction: row;
  align-items: center;
`
