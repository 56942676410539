import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Checkbox, { CHECKBOX_DIRECTIONS, CheckboxSubLabel } from './Checkbox.jsx'

const CheckboxGroupWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  border: 0px;
  margin: 0px;
  ${({ direction }) =>
    direction === CHECKBOX_DIRECTIONS.RIGHT
      ? `/* DIRECTION: RIGHT */
        width: auto; 
        align-items: stretch;`
      : `/* DIRECTION: LEFT */
        width: fit-content; 
        align-items: start;`}
`

const CheckboxGroup = ({
  direction = CHECKBOX_DIRECTIONS.LEFT,
  disabled,
  register,
  name,
  registerOptions,
  errors,
  helpText,
  options,
  className
}) => {
  return (
    <div>
      <CheckboxGroupWrapper direction={direction} className={className}>
        {options.map((option, index) => {
          return (
            <Checkbox
              disabled={disabled}
              key={`${name}${index}`}
              value={option.value}
              label={option.label}
              errors={errors}
              {...register(name, registerOptions)}
              direction={direction}
              isPartOfGroup={true}
            />
          )
        })}
      </CheckboxGroupWrapper>
      {(helpText || Boolean(errors)) && (
        <CheckboxSubLabel
          errors={errors}
          name={name}
          disabled={disabled}
          direction={direction}
          label={helpText}
        />
      )}
    </div>
  )
}

CheckboxGroup.propTypes = {
  direction: PropTypes.oneOf(Object.values(CHECKBOX_DIRECTIONS)),
  disabled: PropTypes.bool,
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  registerOptions: PropTypes.object,
  errors: PropTypes.object.isRequired,
  helpText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired // validated in the Checkbox component
    }).isRequired
  )
}

export default CheckboxGroup
