/**
 *
 * @param {*} object GraphQL Connection containing edges and nodes
 * @returns {Array} Array of nodes
 */
export const fromConnection = (object) =>
  object?.edges ? object.edges.map((edge) => edge.node) : []

/**
 * @param {Object} data The response from a graphQL endpoint containing edges and nodes
 * @param {string} path A path to property to be returned from inside the response
 * @returns {Array} Array of values of the properties specified in the path
 */

export const parseResult = (data, path) => {
  if (!data) return []
  const [dataName, ...steps] = path.split('.')
  let iteratedData = fromConnection(data[dataName])
  for (const stepIndex in steps) {
    const step = steps[stepIndex]
    iteratedData = iteratedData.flatMap((currentLevel) => currentLevel[step])
  }
  return iteratedData
}
