import { A, P } from '@sellpy/design-system-react-web'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { region as regionFunctions } from '@sellpy/commons'

import styled from 'styled-components'

const DataPolicyLink = styled(A)`
  margin: -8px 0px -8px 0px;
  padding: 8px 0px 8px 0px;
  display: inline-block;
`

const DataPolicyNotice = ({ center }) => {
  const { t } = useTranslation('login')
  return (
    <P center={center}>
      <Trans t={t} i18nKey={'login:policies.prefix'}>
        prefix
      </Trans>
      <Trans i18nKey='login:policies.sell'>
        <DataPolicyLink
          href={regionFunctions.sellingPolicyLink(process.env.REGION)}
          target='_blank'
          rel='noopener noreferrer'
        >
          link
        </DataPolicyLink>
        ,
      </Trans>
      <Trans i18nKey='login:policies.datapolicy'>
        <DataPolicyLink
          href={regionFunctions.dataPolicyLink(process.env.REGION)}
          target='_blank'
          rel='noopener noreferrer'
        >
          link
        </DataPolicyLink>
        ,
      </Trans>
      <Trans i18nKey='login:policies.buy'>
        <DataPolicyLink
          href={regionFunctions.purchasePolicyLink(process.env.REGION)}
          target='_blank'
          rel='noopener noreferrer'
        >
          link
        </DataPolicyLink>
      </Trans>
    </P>
  )
}

export default DataPolicyNotice
