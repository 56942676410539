export const CHECKOUT_ROUTES = {
  VALIDATE_EMAIL_ROUTE: '/checkout/validateEmail',
  ADDRESS_ROUTE: '/checkout/address',
  SHIPPING_ROUTE: '/checkout/shipping',
  SUMMARY_ROUTE: '/checkout/summary',
  PAYMENT_ROUTE: '/checkout/payment',
  CART_ROUTE: '/checkout/cart'
}

export const CHECKOUT_STEP_DATA = [
  {
    labelKey: 'delivery',
    to: CHECKOUT_ROUTES.ADDRESS_ROUTE
  },
  {
    labelKey: 'shipping',
    to: CHECKOUT_ROUTES.SHIPPING_ROUTE
  },
  {
    labelKey: 'summary',
    to: CHECKOUT_ROUTES.SUMMARY_ROUTE
  },
  {
    labelKey: 'payment',
    to: CHECKOUT_ROUTES.PAYMENT_ROUTE
  }
]

const routesToShowStepperAt = [
  CHECKOUT_ROUTES.VALIDATE_EMAIL_ROUTE,
  CHECKOUT_ROUTES.ADDRESS_ROUTE,
  CHECKOUT_ROUTES.SHIPPING_ROUTE,
  CHECKOUT_ROUTES.SUMMARY_ROUTE,
  CHECKOUT_ROUTES.PAYMENT_ROUTE
]

export const showStepper = (activeRoute) => routesToShowStepperAt.includes(activeRoute)
