import { createSelector } from 'reselect'
import { itemState } from '@sellpy/commons'
import { getEntity } from '../entities/selectors/base'

export const currentUserSelector = (state) => {
  return state.user.parseUser && state.user.parseUser.id
}
export const currentUserAdress = (state) => state.user.parseUser?.get('address')
export const currentUserCountry = (state) => state.user.parseUser?.get('address')?.country
export const currentParseUser = (state) => state.user.parseUser

export const itemsForSaleForCurrentUserSelector = createSelector(
  [getEntity('Item'), currentUserSelector],
  (items, userId) =>
    items.filter((item) => item.get('user') === userId).filter(itemState.ongoingSale)
)
