import styled from 'styled-components'
import { mediaQueries } from '../../mediaQueries'

const Card = styled.div`
  border-radius: 10px;
  display: flex;
  background-color: white;
  filter: ${({ theme }) => `drop-shadow(0px 2px 8px ${theme.color.grey.shade7})`};
  grid-column: ${({ columns }) => (columns ? `span ${columns}` : 'auto')};
  ${mediaQueries.phone} {
    grid-column: span 1;
  }
`

export default Card
