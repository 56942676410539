/* global localStorage */

export const getVisitedItems = () => {
  try {
    const serializedState = (localStorage.getItem && localStorage.getItem('visitedItems')) || '[]'
    return JSON.parse(serializedState)
  } catch (error) {
    console.warn('localStorage error', error)
    return []
  }
}

export const persistVisitedItems = (visitedItems) => {
  try {
    localStorage.setItem('visitedItems', JSON.stringify(visitedItems))
  } catch (error) {
    console.warn('localStorage error', error)
  }
}

const STORED_LATEST_SEARCHES_NUMBER = 10
export const DISPLAYED_LATEST_SEARCHES_NUMBER = 8

export const persistLatestSearches = ({ path, title }) => {
  try {
    const latestSearches = JSON.parse(localStorage.getItem('latestSearches')) || []
    const searchIndex = latestSearches.findIndex((search) => search.path === path)

    if (searchIndex !== -1) {
      latestSearches.unshift(latestSearches.splice(searchIndex, 1)[0])
      localStorage.setItem('latestSearches', JSON.stringify(latestSearches))
      return
    }
    localStorage.setItem(
      'latestSearches',
      JSON.stringify([{ path, title }, ...latestSearches].slice(0, STORED_LATEST_SEARCHES_NUMBER))
    )
  } catch (error) {
    console.warn('localStorage error', error)
  }
}

export const getLatestSearches = () => {
  try {
    const latestSearches = JSON.parse(localStorage.getItem('latestSearches')) || []
    const nonDeprecatedSearches = latestSearches.filter((search) => search.path)
    return nonDeprecatedSearches
  } catch (error) {
    console.warn('localStorage error', error)
    return []
  }
}
