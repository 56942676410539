// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js!../node_modules/@adyen/adyen-web/dist/adyen.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js!../node_modules/@sellpy/design-system-react-web/dist/assets/font-icons.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../node_modules/@sellpy/design-system-react-web/dist/assets/font-icons.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'fontello';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n    format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n[class^='icon-']:before,\n[class*=' icon-']:before {\n  font-family: 'fontello';\n  font-style: normal;\n  font-weight: normal;\n  speak: never;\n  display: inline-block;\n  text-decoration: inherit;\n  text-align: center;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.form-horizontal .control-label {\n  text-align: left;\n}\n\nhtml,\nbody {\n  height: 100%;\n  font-size: 12px;\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-text-size-adjust: 100%;\n}\n\np {\n  font-size: 12px;\n  margin-top: 0;\n  margin-bottom: 1rem;\n}\n\na {\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\nhtml {\n  background: #ffffff;\n}\n\n.cardPanel .panel-body {\n  padding-bottom: 5px;\n}\n\n#intercom-container {\n  z-index: 1060 !important;\n}\n\n.adyen-checkout__dropdown__button {\n  width: initial;\n}\n", ""]);
// Exports
module.exports = exports;
