import { P, Span } from '@sellpy/design-system-react-web'
import React from 'react'
import styled from 'styled-components'
import { IconAndValueContainer, IconSizing, Item } from '../styles'

export const Option = ({ option, onChange, inputRef, value }) => (
  <Item
    onClick={() => {
      onChange(option.value)
      inputRef.current.value = option.label || option.value
    }}
    isSelected={option.value === value}
  >
    <IconAndValueContainer>
      {option?.icon && <IconSizing>{option?.icon}</IconSizing>}
      <P noMargin design='body3'>
        {option.label}
      </P>
    </IconAndValueContainer>
  </Item>
)

const EmptyStateLabel = styled(Span)`
  padding: 8px 8px 8px 16px;
  color: ${({ theme }) => theme.color.grey.shade3};
  cursor: default;
`

export const Options = ({ options, onChange, value, inputRef, noResult = '' }) => {
  if (noResult)
    return (
      <EmptyStateLabel noMargin design='body3'>
        {noResult}
      </EmptyStateLabel>
    )

  return options.map((option, i) => (
    <Option key={i} option={option} onChange={onChange} inputRef={inputRef} value={value} />
  ))
}
