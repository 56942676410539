import { useEffect } from 'react'

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        // Dirty fix to not close modal when clicking on a select component
        // TODO: refactor select component to not use lib and remove this fix
        event.target.classList.contains('Select-option')
      ) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export default useClickOutside
