import { dac7, inputValidators } from '@sellpy/commons'
import {
  AT,
  BE,
  BG,
  CY,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GR,
  HR,
  HU,
  IE,
  IT,
  LT,
  LU,
  LV,
  MT,
  NL,
  PL,
  PT,
  RO,
  SE,
  SI,
  SK,
  SingleLineInput
} from '@sellpy/design-system-react-web'
import debounce from 'lodash/debounce'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FormWrapper from '../../components/ui/form/FormWrapper.jsx'
import useResponsive from '../../hooks/useResponsive.js'
import { useInputValidators } from '../../lib/hookFormTools.js'
import { A, Checkbox, P } from '../../uiComponents/index.js'
import SingleSelector from '../../uiComponents/input/selectors/singleSelector/SingleSelector.jsx'
import { HR as HorizontalRule } from '../../uiComponents/modal/styles.js'
import { getValidateTin } from '../validators.js'

const flags = {
  AT,
  BE,
  BG,
  CY,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GR,
  HR,
  HU,
  IE,
  IT,
  LT,
  LU,
  LV,
  MT,
  NL,
  PL,
  PT,
  RO,
  SE,
  SI,
  SK
}

export const StyledA = styled(A)`
  text-decoration: underline;
`

const HorizontalGroup = styled.div`
  display: flex;
  flex-direction: ${({ largerThanTablet }) => (largerThanTablet ? 'row' : 'column')};
  gap: 24px;

  & > div {
    width: ${({ largerThanTablet }) => (largerThanTablet ? '50%' : '100%')};
  }
`

const debounceAsyncValidateTin = debounce(({ resolve, tin, countryCode, errorMsg }) => {
  return getValidateTin({ countryCode, tin }).then((valid) => {
    resolve(valid || errorMsg)
  })
}, 500)

const DAC7Form = ({ control, errors, register, watch, trigger, dirtyFields }) => {
  const { t } = useTranslation(['common', 'balance'])
  const { applyValidator } = useInputValidators()
  const { largerThanTablet } = useResponsive()

  const taxIdentificationCountry = watch('taxIdentificationCountry')
  const country = watch('country')

  useEffect(() => {
    if (taxIdentificationCountry && dirtyFields.taxIdentificationNumber) {
      trigger('taxIdentificationNumber')
    }
  }, [taxIdentificationCountry, watch, trigger, dirtyFields])

  useEffect(() => {
    if (country && dirtyFields.country) {
      trigger('postalCode')
    }
  }, [country, watch, trigger, dirtyFields])

  const tinHelpText = `${t(
    'balance:balanceOverview.dac7Modal.form.taxAuthorityDetails.tin.helpText'
  )}: ${taxIdentificationCountry && dac7.sampleTIN[taxIdentificationCountry].join(', ')}`

  return (
    <FormWrapper style={{ width: '100%' }}>
      <HorizontalGroup largerThanTablet={largerThanTablet}>
        <SingleLineInput
          {...register('firstName', {
            validate: applyValidator(inputValidators.required),
            maxLength: {
              value: 50,
              message: t('common:genericErrors.maxLengthExceeded', { maxLength: 50 })
            }
          })}
          errors={errors}
          label={t('balance:balanceOverview.dac7Modal.form.firstName.label')}
          helpText={t('balance:balanceOverview.dac7Modal.form.firstName.helpText')}
        />

        <SingleLineInput
          {...register('lastName', {
            validate: applyValidator(inputValidators.required),
            maxLength: {
              value: 50,
              message: t('common:genericErrors.maxLengthExceeded', { maxLength: 50 })
            }
          })}
          errors={errors}
          label={t('balance:balanceOverview.dac7Modal.form.lastName.label')}
          helpText={t('balance:balanceOverview.dac7Modal.form.lastName.helpText')}
        />
      </HorizontalGroup>
      <SingleLineInput
        {...register('dateOfBirth', {
          validate: {
            required: applyValidator(inputValidators.required),
            validDate: (value) => inputValidators.validateDateOfBirth(value, null, { t })
          }
        })}
        errors={errors}
        label={t('balance:balanceOverview.dac7Modal.form.dateOfBirth.label')}
        helpText={t('balance:balanceOverview.dac7Modal.form.dateOfBirth.helpText')}
      />

      <HorizontalRule style={{ margin: '16px 0' }} />
      <P design='body2'>{t('balance:balanceOverview.dac7Modal.form.primaryAddress.header')}</P>
      <SingleLineInput
        {...register('streetAddress', {
          validate: {
            required: applyValidator(inputValidators.required),
            street: applyValidator(inputValidators.street)
          },
          maxLength: {
            value: 50,
            message: t('common:genericErrors.maxLengthExceeded', { maxLength: 50 })
          }
        })}
        errors={errors}
        label={t('common:address.street')}
        helpText={t('balance:balanceOverview.dac7Modal.form.street.helpText')}
      />
      <HorizontalGroup largerThanTablet={largerThanTablet}>
        <SingleLineInput
          {...register('postalCode', {
            validate: {
              zipCode: applyValidator(inputValidators.zipCode),
              required: applyValidator(inputValidators.required)
            }
          })}
          errors={errors}
          label={t('common:address.postalCode')}
        />
        <SingleLineInput
          {...register('city', {
            validate: applyValidator(inputValidators.required)
          })}
          errors={errors}
          label={t('common:address.city')}
        />
      </HorizontalGroup>
      <SingleSelector
        label={t('balance:balanceOverview.dac7Modal.form.taxAuthorityDetails.country.label')}
        subLabel={t('balance:balanceOverview.dac7Modal.form.taxAuthorityDetails.country.subLabel')}
        name={'country'}
        options={dac7.europeanUnionCodes.map((countryCode) => {
          const Flag = flags[countryCode]
          return {
            value: countryCode,
            icon: <Flag />,
            label: t(`common:address.countries.${countryCode}`)
          }
        })}
        required={t('common:genericErrors.required')}
        control={control}
      />

      <HorizontalRule style={{ margin: '16px 0' }} />
      <P design='body2'>{t('balance:balanceOverview.dac7Modal.form.taxAuthorityDetails.header')}</P>

      <HorizontalGroup largerThanTablet={largerThanTablet}>
        <SingleSelector
          label={t(
            'balance:balanceOverview.dac7Modal.form.taxAuthorityDetails.countryOfIssue.label'
          )}
          subLabel={t(
            'balance:balanceOverview.dac7Modal.form.taxAuthorityDetails.countryOfIssue.subLabel'
          )}
          name={'taxIdentificationCountry'}
          options={dac7.europeanUnionCodes.map((countryCode) => {
            const Flag = flags[countryCode]
            return {
              value: countryCode,
              icon: <Flag />,
              label: t(`common:address.countries.${countryCode}`)
            }
          })}
          required={t('common:genericErrors.required')}
          control={control}
        />
        <SingleLineInput
          {...register('taxIdentificationNumber', {
            validate: {
              tin: (val) =>
                new Promise((resolve) => {
                  debounceAsyncValidateTin({
                    resolve,
                    tin: val,
                    countryCode: taxIdentificationCountry,
                    errorMsg: tinHelpText
                  })
                })
            },
            required: true
          })}
          errors={errors}
          label={dac7.textTIN[taxIdentificationCountry]}
        />
      </HorizontalGroup>

      <Checkbox
        {...register('confirmation', { required: true })}
        label={t('balance:balanceOverview.dac7Modal.form.confirmation')}
        checked={Boolean(watch('confirmation'))}
      />
    </FormWrapper>
  )
}

export default DAC7Form
