import { Map, Record } from 'immutable'
import { FETCH_SALE_PROCESS_ESTIMATE_SUCCESS, SET_REFERRAL_CODE } from '../sale/actions'

const InitialState = Record({
  containerOrders: Map(),
  referralCode: undefined,
  processEstimates: Map()
})
const initialState = new InitialState()

const containerOrder = (state = initialState, action) => {
  if (!(state instanceof InitialState)) return initialState
  switch (action.type) {
    case SET_REFERRAL_CODE:
      return state.set('referralCode', action.referralCode)

    case FETCH_SALE_PROCESS_ESTIMATE_SUCCESS: {
      return state.setIn(['processEstimates', action.saleId], action.estimate)
    }
  }
  return state
}

export default containerOrder
