import { marketplace, money } from '@sellpy/commons'
import { useSelector } from 'react-redux'
import { region } from '../../../../common/region/region'

export const useMaxCreditAmount = (totalAmount) => {
  const availableCredits = useSelector((state) => state.cart.credit)
  const creditsInSubUnits = money.toSubUnit({
    amount: availableCredits,
    currency: marketplace.CURRENCY[region()]
  })
  const maxCreditAmount = money.gte(creditsInSubUnits, totalAmount)
    ? totalAmount
    : creditsInSubUnits

  return maxCreditAmount
}
