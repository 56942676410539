import { captureException } from '@sentry/react'
import Parse from 'parse'
import { useEffect, useState } from 'react'
import config from 'config'

const getCachedInstallationId = () => {
  return localStorage.getItem(`Parse/${config.parse.applicationId}/installationId`)
}

export const getInstallationId = async () => {
  try {
    return (
      getCachedInstallationId() ||
      Parse.CoreManager.getInstallationController().currentInstallationId()
    )
  } catch (error) {
    captureException(error)
  }
}

export const useInstallationId = () => {
  const [installationId, setInstallationId] = useState(getCachedInstallationId())
  const [loading, setLoading] = useState(!installationId)

  useEffect(() => {
    getInstallationId().then((installationId) => {
      setInstallationId(installationId)
      setLoading(false)
    })
  }, [])

  return { installationId, loading }
}
