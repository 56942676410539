import React from 'react'
import { itemCategoryMapper } from '@sellpy/commons'
import { Blob, ICONS } from '@sellpy/design-system-react-web'
import { SIZES } from '../uiComponents'
import { FEATURE_BAG_PICKUP, FEATURE_P2P, FEATURE_SELL } from '../featureToggle'
import { locale } from '../../common/region/locale'
import { blobColorSelector } from '../uiComponents/constants'

export const staticContentLoggedInCategories = {
  circle: [
    { headline: 'listed', to: '/user/circle/listed' },
    { headline: 'available-for-circle', to: '/user/circle/available-for-circle' },
    { headline: 'to-be-sent', to: '/user/circle/to-be-sent' },
    { headline: 'sent', to: '/user/circle/sent' },
    { headline: 'pending-sale', to: '/user/circle/pending-sale' },
    { headline: 'claim', to: '/user/circle/claim' }
  ],
  buyRequest: [
    { headline: 'buy-request-sent', to: '/user/circle/buy-request/buy-request-sent' },
    { headline: 'buy-request-received', to: '/user/circle/buy-request/buy-request-received' },
    { headline: 'buy-request-for-sale', to: '/user/circle/buy-request/buy-request-for-sale' }
  ]
}

const initialAlgoliaCategories = [
  {
    label: itemCategoryMapper.baseCategories(locale).women,
    icon: ICONS.TOP,
    value: itemCategoryMapper.baseCategories(locale).women
  },
  {
    label: itemCategoryMapper.baseCategories(locale).men,
    icon: ICONS.TSHIRT,
    value: itemCategoryMapper.baseCategories(locale).men
  },
  {
    label: itemCategoryMapper.baseCategories(locale).children,
    icon: ICONS.BOTTLE,
    value: itemCategoryMapper.baseCategories(locale).children
  },
  {
    label: itemCategoryMapper.baseCategories(locale).things,
    icon: ICONS.WALLET,
    value: itemCategoryMapper.baseCategories(locale).things
  },
  {
    label: itemCategoryMapper.baseCategories(locale).beauty,
    icon: ICONS.MAKEUP,
    value: itemCategoryMapper.baseCategories(locale).beauty
  }
]
export const initialCategoryFilterList = {
  level: 0,
  data: initialAlgoliaCategories
}

export const initialBuyOrSellList = {
  level: 0,
  buy: [
    {
      label: 'allProducts',
      icon: ICONS.CATEGORY,
      link: '/search'
    },
    ...initialAlgoliaCategories,
    {
      label: 'premiumSelection',
      icon: ICONS.PREMIUM,
      link: '/store/selection/premium-selection'
    },
    {
      label: 'hiddenGems',
      icon: ICONS.GEM,
      link: '/store/selection/hidden-gems'
    },
    {
      label: 'new',
      icon: ICONS.EYE,
      link: '/search?sortBy=saleStartedAt_desc'
    },
    {
      label: 'brands',
      icon: ICONS.MULTIPLE_TAGS,
      link: '/brands'
    },
    {
      label: 'stores',
      icon: ICONS.COLLECTION,
      link: '/stores'
    },
    {
      label: 'about',
      icon: ICONS.SELLPY_CIRCLE,
      link: '/about'
    }
  ],
  sell: [
    {
      label: 'sell',
      icon: ICONS.TAG,
      link: '/sell',
      featureToggle: FEATURE_SELL
    },
    {
      label: 'orderBag',
      icon: ICONS.BAG,
      link: '/order-bag',
      featureToggle: FEATURE_SELL
    },
    {
      label: 'orderPickUp',
      icon: ICONS.DELIVERY,
      link: '/orderPickUp',
      featureToggle: FEATURE_BAG_PICKUP
    },
    {
      label: 'howToSell',
      icon: ICONS.HELP_CIRCLE,
      link: '/howItWorks'
    },
    {
      label: 'cost',
      icon: ICONS.CASH,
      link: '/pricingInfo'
    },
    {
      label: 'sellpyCircle',
      icon: ICONS.RECYCLE_CIRCLE,
      blob: (
        <Blob
          label='Circle'
          style={{ margin: '0 1rem' }}
          color={blobColorSelector['p2p']}
          size={SIZES.SMALL}
        />
      ),
      link: '/circle',
      featureToggle: FEATURE_P2P
    },
    {
      label: 'about',
      icon: ICONS.SELLPY_CIRCLE,
      link: '/about'
    }
  ]
}
