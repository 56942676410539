import React from 'react'
import styled, { css } from 'styled-components'
import { ResponsiveImage } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../../mediaQueries'

export const imageWrapperStyle = css`
  width: 40%;
  max-width: 400px;
  min-height: 240px;
  ${mediaQueries.phone} {
    width: 100%;
    max-width: unset;
  }
  aspect-ratio: 6 / 4;
`

const ImageWrapper = styled.div`
  ${imageWrapperStyle}
`

const StyledImage = styled(ResponsiveImage)`
  object-fit: cover;
  object-position: center top;
  object-position: ${({ hotspot: { x, y } }) => `${x}% ${y}%`};
  height: 100%;
  width: 100%;
`

const StoreImage = ({ src, alt, hotspot }) => {
  return (
    <ImageWrapper>
      <StyledImage src={src} alt={alt} hotspot={hotspot} mobileWidth='100vw' desktopWidth='20vw' />
    </ImageWrapper>
  )
}

export default StoreImage
