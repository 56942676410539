import { runCloudCached } from '../lib/parseTools'
import { cacheEntities } from '../entities/actions'
import Parse from '../../client/lib/parse'
import { region } from '../region/region'

export const getOwnContainerOrders = () => async (dispatch) => {
  return runCloudCached((ownContainerOrders) => dispatch(cacheEntities(ownContainerOrders)))(
    'getOwnContainerOrders'
  )
}

export const getOwnContainerOrderById = (containerOrderId) => async (dispatch) =>
  runCloudCached((containerOrder) => dispatch(cacheEntities(containerOrder)))(
    'getOwnContainerOrderById',
    { containerOrderId }
  )

export const getBagPromoCodeForUser = () => (dispatch) => {
  return Parse.Cloud.run('getBagPromoCodeForUser').then((promocode) => {
    dispatch(cacheEntities(promocode))
    return promocode
  })
}

export const sendDistanceReturnFreightLabel = async (data) => {
  return Parse.Cloud.run('sendDistanceReturnFreightLabel', data)
}

export const createSessionCheckoutContainerOrders = (data) => {
  return Parse.Cloud.run('createSessionCheckoutContainerOrders', {
    ...data,
    region: region()
  })
}

export const commenceSessionCheckoutContainerOrders = (data) => {
  return Parse.Cloud.run('commenceSessionCheckoutContainerOrders', {
    ...data,
    region: region()
  })
}
