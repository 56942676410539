import { DataLayer } from '../../../common/analytics/dataLayer.js'
import { getInstallationId } from '../../hooks/useInstallationId.js'
import { snippets as Snippets } from './snippets.js'

// basically a replica of https://www.npmjs.com/package/react-gtm-module
const tagManager = {
  dataScript: function(dataLayer) {
    const script = document.createElement('script')
    script.innerHTML = dataLayer
    return script
  },
  gtm: function({ id, dataLayer, dataLayerName }) {
    const snippets = Snippets.tags({ id, dataLayer, dataLayerName })

    const script = () => {
      const script = document.createElement('script')
      script.innerHTML = snippets.script
      return script
    }

    const dataScript = this.dataScript(snippets.dataLayerVar)

    return {
      script,
      dataScript
    }
  },
  initialize: async function({ gtmId, dataLayer, dataLayerName = 'dataLayer' }) {
    const installationId = await getInstallationId()
    const dataLayerEvent = DataLayer.createDataLayerEvent({
      event: 'installationId',
      installationId
    })

    let newDataLayer
    if (dataLayer) {
      newDataLayer = [...dataLayer, dataLayerEvent]
    } else {
      newDataLayer = [dataLayerEvent]
    }

    const gtm = this.gtm({
      id: gtmId,
      dataLayer: newDataLayer,
      dataLayerName: dataLayerName
    })

    document.head.appendChild(gtm.dataScript)
    document.head.insertBefore(gtm.script(), document.head.childNodes[0])
  }
}

export default tagManager
