import { useDispatch } from 'react-redux'
import { Set } from 'immutable'
import { useCallback } from 'react'
import { money } from '@sellpy/commons'
import { getAlgoliaItems } from '../../market/src/common/items/actions'
import { region } from '../../market/src/common/region/region'

const getAnalyticsFormattedCategories = ({ item }) => {
  return {
    item_category: item?.metadata?.demography,
    ...Object.values(item?.categories ?? {})
      .map((categoryArray) => categoryArray[0])
      .reduce((map, category, index) => {
        const categoryArray = category.split(' > ')
        map[`item_category${index + 2}`] = categoryArray[categoryArray.length - 1]
        return map
      }, {})
  }
}

export const useAnalyticsFormattingUtils = () => {
  const dispatch = useDispatch()

  const getAnalyticsFormattedItem = useCallback(
    async ({ item }) => {
      const itemId = item?.objectId || item?.objectID

      const itemToUse =
        itemId &&
        item?.metadata?.brand &&
        item?.metadata?.demography &&
        item?.categories &&
        item?.[`price_${region()}`]
          ? item
          : (await dispatch(getAlgoliaItems(Set([itemId]))))?.[0]

      const price =
        itemToUse?.[`price_${region()}`] && money.toBaseUnit(itemToUse?.[`price_${region()}`])

      return {
        item_id: itemId,
        item_brand: itemToUse?.metadata?.brand,
        price: price?.amount,
        currency: price?.currency,
        ...getAnalyticsFormattedCategories({ item: itemToUse })
      }
    },
    [dispatch]
  )

  return { getAnalyticsFormattedItem }
}
