import { fromJS } from 'immutable'
import { runCloudCached } from '../lib/parseTools'

export const SET_ALL_PHONE_COUNTRIES = 'SET_ALL_PHONE_COUNTRIES'
export const getPhoneCountryList = (locale) => (dispatch) => {
  return runCloudCached((countryList) => {
    countryList.sort((a, b) => a.iso2.localeCompare(b.iso2))
    return dispatch({ type: SET_ALL_PHONE_COUNTRIES, payload: fromJS(countryList) })
  })('getPhoneCountryList', { locale })
}
