import React, { useState } from 'react'
import { inputValidators } from '@sellpy/commons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { H2, P, STICKERS, Sticker } from '@sellpy/design-system-react-web'
import { useResponsive } from '../../hooks'
import { signUpForNewsletter } from '../../../common/user/actions'
import { showToast } from '../../../common/ui/actions'
import { TOAST_TYPE } from '../../uiComponents/notifications/Toasts.jsx'
import {
  PERMISSION_TYPE_DATA_POLICY,
  PERMISSION_TYPE_EMAIL_MARKETING
} from '../../../common/user/dataHandling'
import DataConsent from '../dataConsent/DataConsent.jsx'
import {
  Wrapper,
  StyledSticker,
  Info,
  Header,
  StyledForm,
  FieldWithButton,
  StyledButton,
  StyledInput
} from './styles'

const NewsLetter = () => {
  const { t } = useTranslation(['landing', 'common'])
  const dispatch = useDispatch()
  const { isTablet } = useResponsive()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const dataUsageConsent = useSelector((state) => state.user.dataUsageConsent)
  if (dataUsageConsent.get(PERMISSION_TYPE_EMAIL_MARKETING)) {
    return null
  }

  const onSubmit = ({ email, dataUsageConsent }) => {
    setLoading(true)
    return signUpForNewsletter({ email, dataUsageConsent, region: process.env.REGION })
      .then((response) => {
        const { header, body } = {
          codeCreated: {
            header: t('newsletter.toastMessage.codeCreated.header', { email }),
            body: t('newsletter.toastMessage.codeCreated.body')
          },
          alreadyReceivedCode: {
            header: t('newsletter.toastMessage.alreadyReceivedCode.header', {
              email
            }),
            body: t('newsletter.toastMessage.alreadyReceivedCode.body')
          }
        }[response]

        setLoading(false)
        dispatch(
          showToast({
            header,
            body,
            type: TOAST_TYPE.SUCCESS
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showToast({
            header:
              error.code === 999
                ? t('newsletter.toastMessage.error.header')
                : t('common:errorToast.header'),
            body:
              error.code === 999
                ? t('newsletter.toastMessage.error.body')
                : t('common:errorToast.body'),
            type: error.code === 999 ? TOAST_TYPE.INFO : TOAST_TYPE.ERROR
          })
        )
      })
  }

  return (
    <Wrapper>
      {!isTablet && (
        <StyledSticker>
          <Sticker type={STICKERS.PHONE_GREEN} customSize={80} />
        </StyledSticker>
      )}
      <Info>
        <Header noMargin design='h3' as={H2}>
          {t('newsletter.headline')}
        </Header>
        <P design='body1'>{t('newsletter.subHeadline')}</P>
      </Info>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FieldWithButton>
          <StyledInput
            label={t('newsletter.inputField')}
            {...register('email', {
              validate: (value) => inputValidators.email(value, null, { t })
            })}
            errors={errors}
          />
          <div>
            {/* unstyled div + fullWidth = responsive button with ability to flex-stretch on css query */}
            <StyledButton label={t('newsletter.cta')} type='submit' loading={loading} fullWidth />
          </div>
        </FieldWithButton>
        <DataConsent
          permissions={[{ type: PERMISSION_TYPE_DATA_POLICY, required: true }]}
          register={register}
          errors={errors}
        />
      </StyledForm>
    </Wrapper>
  )
}

export default NewsLetter
