import styled from 'styled-components'
import { mediaQueries } from '../mediaQueries'

const Frame = styled.div`
  padding: 2rem;
  box-shadow: ${({ boxShadow = true }) => (boxShadow ? '0 5px 15px rgba(0, 0, 0, 0.07)' : '')};
  ${mediaQueries.smallPhone} {
    padding: 1rem;
  }
`

export default Frame
