import React from 'react'
import styled from 'styled-components'
import useResponsive from '../../../../../hooks/useResponsive.js'
import { mediaQueries } from '../../../../../uiComponents/mediaQueries.js'
import { Brands } from './sections/Brands.jsx'
import { SuggestionListContainer } from './components/SuggestionSection.jsx'
import { BrandSuggestions } from './sections/BrandSuggestions.jsx'
import { Categories } from './sections/Categories.jsx'
import { LatestSearches } from './sections/LatestSearches.jsx'
import { StructuredSearchQuerySuggestions } from './sections/StructuredSearchQuerySuggestions.jsx'
import { ChipList, ChipSlider } from './components/SuggestionChip.jsx'
import { QuerySuggestions } from './sections/QuerySuggestions.jsx'

const Container = styled.ul`
  display: block;
  margin-top: 4px;
  border-radius: 8px;
  width: 100%;
  list-style-type: none;
  padding: 0;
  padding-bottom: 8px;
  z-index: 2500;
  overflow: hidden;

  ${mediaQueries.largerThanTablet} {
    position: absolute;
    background-color: white;
  }

  ${mediaQueries.tablet} {
    min-height: calc(65vh);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & > ${SuggestionListContainer}:not(:last-child):not(:has(${ChipSlider})) {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey.shade9};
  }

  & > ${SuggestionListContainer}:not(:last-child):has(${ChipList}) {
    padding-bottom: 12px;
  }
`

export const SuggestionBox = ({ isOpen, query, autocomplete, collections }) => {
  const { isTablet } = useResponsive()

  const emptyResults = collections?.every((collection) => collection.items.length === 0)

  return isOpen && (isTablet || !emptyResults) ? (
    <Container {...autocomplete.getPanelProps({})}>
      {collections?.map((collection) => {
        const { items, source } = collection
        if (items.length === 0) return

        const sectionProps = {
          key: `source-list-${source.sourceId}`,
          items,
          source,
          getListProps: autocomplete.getListProps,
          getItemProps: autocomplete.getItemProps
        }

        switch (source.sourceId) {
          case 'brands':
            return <Brands {...sectionProps} />
          case 'categories':
            return <Categories {...sectionProps} />
          case 'latestSearches':
            return <LatestSearches {...sectionProps} />
          case 'querySuggestions':
            return <QuerySuggestions {...sectionProps} hasQuery={Boolean(query)} />
          case 'brandSuggestions':
            return <BrandSuggestions {...sectionProps} hasQuery={Boolean(query)} />
          case 'structuredSearchQuerySuggestions':
            return <StructuredSearchQuerySuggestions {...sectionProps} />
          default:
            return null
        }
      })}
    </Container>
  ) : null
}
