import { createSelector } from 'reselect'
import { currentUserSelector } from '../../user/selectors'
import { getEntity } from './base'
// Only to be used in actions and other selectors in components use: reservationsForItemsInCartSelector
export const ownFreshReservations = createSelector(
  [getEntity('ItemReservation'), currentUserSelector],
  (reservations, userId) =>
    reservations
      .filter((reservation) => reservation.get('status') === 'fresh')
      .filter((reservation) => reservation.get('user') === userId)
)

export const ownFreshReservationForItem = createSelector(
  [ownFreshReservations, (_, itemId) => itemId],
  (reservations, itemId) => reservations.find((reservation) => reservation.get('item') === itemId)
)

export const itemsForOwnFreshReservations = createSelector(
  [ownFreshReservations, getEntity('Item')],
  (reservations, items) => {
    const itemsInReservations = reservations.map((reservation) => reservation.get('item')).toSet()
    return items.filter((item) => itemsInReservations.has(item.get('objectId')))
  }
)

export const offersForOwnFreshReservations = createSelector(
  [getEntity('MarketOffer'), ownFreshReservations],
  (marketOffers, reservations) => {
    if (marketOffers.size === 0) return []
    return reservations
      .filter((reservation) => reservation.get('type') === 'market')
      .mapEntries(([_, reservation]) => {
        const offer = marketOffers.get(reservation.get('marketOffer'))
        return [offer.get('objectId'), offer]
      })
  }
)

export const sellerReturnFeesForOwnFreshReservations = createSelector(
  [
    ownFreshReservations,
    getEntity('Item'),
    getEntity('Bag'),
    (state) => state.cart.get('pricingModels')
  ],
  (reservations, items, bags, pricingModels) => {
    return reservations
      .filter((reservation) => reservation.get('type') === 'sellerReturn')
      .mapEntries(([_, reservation]) => {
        const item = items.get(reservation.get('item'))
        const bag = bags.get(item.get('bag'))
        const pricingModel = pricingModels[bag.get('currency')][bag.get('pricingModel')]
        return [
          reservation.get('objectId'),
          {
            amount: pricingModel.adFee,
            currency: bag.get('currency')
          }
        ]
      })
  }
)
