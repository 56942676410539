import React from 'react'
import { marketplace } from '@sellpy/commons'
import { gql, useQuery } from '@apollo/client'
import Parse from 'parse'
import { PromoBannerBone } from '../routing/skeletons/PromoBannerSkeleton.jsx'
import SanityPromoBanner from '../promoBanner/SanityPromoBanner.jsx'
import { region } from '../../common/region/region.js'

const getPromoBannerVariantFromUserData = ({
  customerHasBalance,
  isFirstTimeMarketCustomer,
  isLoyalCustomer,
  isStaleCustomer
}) => {
  if (isFirstTimeMarketCustomer) return 'firstTimeCustomer'
  if (isStaleCustomer) return 'staleCustomer'
  if (customerHasBalance) return 'balanceCustomer'
  if (isLoyalCustomer) return 'loyaltyCustomer'
  return 'default'
}

const GET_USER_VARIANT_STATE = gql`
  query getData($currency: String, $region: String) {
    isFirstTimeMarketCustomer
    isStaleCustomer
    customerHasBalance(currency: $currency)
    isLoyalCustomer(region: $region)
  }
`

const PromoBannerVariantSwitch = () => {
  const user = Parse.User.current()
  const { data, loading } = useQuery(GET_USER_VARIANT_STATE, {
    variables: { currency: marketplace.CURRENCY[region()], region: region() },
    skip: !user
  })

  if (loading) return <PromoBannerBone />

  // if no data is returned eg. due to a server-side error, we fall back to the 'default' variant
  const variant = !user
    ? 'firstTimeCustomer'
    : data
    ? getPromoBannerVariantFromUserData(data)
    : 'default'
  return <SanityPromoBanner variant={variant} />
}

export default PromoBannerVariantSwitch
