import React from 'react'
import styled from 'styled-components'
import { Icon, ICONS } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../../mediaQueries'
import { DataLayer } from '../../../../../common/analytics/dataLayer.js'
import { RangeSlider } from './RangeSlider.jsx'

// eslint-disable-next-line react/display-name
export const BrightnessAdjuster = React.forwardRef(
  ({ isVisible, imageBrightness, setImageBrightness, itemId }, ref) => {
    return (
      <Container>
        {isVisible && (
          <>
            <RangeSlider
              ref={ref}
              value={imageBrightness}
              onChange={setImageBrightness}
              min={100}
              max={250}
              onRelease={() =>
                DataLayer.trackImageBrightnessAdjustment({
                  brightness: imageBrightness / 100,
                  itemId
                })
              }
            />
            <Icon name={ICONS.SUN} style={{ fontSize: '24px' }} />
          </>
        )}
      </Container>
    )
  }
)

const CONTAINER_SHORT_SIDE_SIZE = 24
const MARGIN_FROM_IMAGE = 16

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;

  ${mediaQueries.largerThanTablet} {
    transform: rotate(270deg);
    width: ${CONTAINER_SHORT_SIDE_SIZE}px;
    position: absolute;
    top: 50%;
    left: calc(-${CONTAINER_SHORT_SIDE_SIZE}px - ${MARGIN_FROM_IMAGE}px);
  }

  ${mediaQueries.tablet} {
    margin-top: ${MARGIN_FROM_IMAGE}px;
    height: ${CONTAINER_SHORT_SIDE_SIZE}px;
  }
`
