import React from 'react'
import { useTranslation } from 'react-i18next'
import merge from 'lodash/merge'
import { searchUrls } from '@sellpy/commons'
import { useLocation } from 'react-router-dom'
import { useMenWomenChildrenUrlFilter } from '../filterHelperFunctions'
import { region } from '../../../common/region/region.js'
import MultiColumnChipBanner from '../components/banners/multiColumnChipBanner/MultiColumnChipBanner.jsx'
import { getTileTrackFunction } from '../../../common/analytics/actions'
import { staticContentBrandSlab, staticContentHmBrandSlab } from './staticContent/brandSlab'

export const BrandsChipBanner = ({ position }) => {
  const menWomenChildrenUrlFilter = useMenWomenChildrenUrlFilter()
  const { t } = useTranslation('landing')
  const { pathname } = useLocation()

  const bannerData = staticContentBrandSlab(pathname).map((item) => ({
    title: item.headline,
    chips: item.items.map(({ label, searchState }) => ({
      title: label,
      link: `${searchUrls.stringifySearchStateV2(region())(
        merge(searchState, menWomenChildrenUrlFilter)
      )}`,
      onClick: getTileTrackFunction({
        sliderId: `${window.location.pathname}`,
        sliderTitle: item.headline,
        sliderType: 'MultiColumnChipBanner',
        tileId: label,
        position: position,
        totalCount: item.items.length
      })
    }))
  }))

  return (
    <MultiColumnChipBanner
      title={t([
        `slabs.brandSlab.headline.${window.location.pathname}`,
        `slabs.brandSlab.headline./`
      ])}
      readMoreLink={'/brands'}
      readMoreText={t('slabs.brandSlab.linkLabel')}
      columns={bannerData}
    />
  )
}

export const BrandsxHMChipBanner = ({ position }) => {
  const { t } = useTranslation('landing')
  const bannerData = staticContentHmBrandSlab()['/hm'].map((item) => ({
    title: item.headline,
    chips: item.items.map(({ label, searchState }) => ({
      title: label,
      link: `${searchUrls.stringifySearchStateV2(region())(searchState)}`,
      onClick: getTileTrackFunction({
        sliderId: `${window.location.pathname}`,
        sliderTitle: item.headline,
        sliderType: 'MultiColumnChipBanner',
        tileId: label,
        position: position,
        totalCount: item.items.length
      })
    }))
  }))

  return (
    <MultiColumnChipBanner
      title={t(`slabs.brandSlab.headline.${window.location.pathname}`)}
      readMoreLink={'/brands'}
      readMoreText={t('slabs.brandSlab.linkLabel')}
      columns={bannerData}
    />
  )
}
