import { Trans, useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'
import { region as regionFunctions } from '@sellpy/commons'
import { A, P } from '../uiComponents'
import { region } from '../../common/region/region'

const Text = styled(P)`
  text-align: center;
  padding: 1rem 1rem 2rem 1rem;
`

export const CheckoutPolicies = ({ withSalePolicy }) => {
  const { t } = useTranslation('cart')
  return (
    <Text noMargin design={'body5'}>
      <Trans t={t} i18nKey='policies.prefix'>
        prefix
      </Trans>
      {regionFunctions.showCancellationPolicy(region()) && (
        <Trans t={t} i18nKey='policies.cancellation'>
          <A
            href={regionFunctions.cancellationPolicyLink(process.env.REGION)}
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </A>
          ,
        </Trans>
      )}
      {withSalePolicy && (
        <Trans t={t} i18nKey='policies.sell'>
          <A
            href={regionFunctions.sellingPolicyLink(process.env.REGION)}
            target='_blank'
            rel='noopener noreferrer'
          >
            link
          </A>
          ,
        </Trans>
      )}
      <Trans t={t} i18nKey='policies.datapolicy'>
        <A
          href={regionFunctions.dataPolicyLink(process.env.REGION)}
          target='_blank'
          rel='noopener noreferrer'
        >
          link
        </A>
        ,
      </Trans>
      <Trans t={t} i18nKey='policies.buy'>
        <A
          href={regionFunctions.purchasePolicyLink(process.env.REGION)}
          target='_blank'
          rel='noopener noreferrer'
        >
          link
        </A>
        ,
      </Trans>
      <Trans t={t} i18nKey='policies.sufix'>
        sufix
      </Trans>
    </Text>
  )
}
