import { fromJS } from 'immutable'

export function resultsToList(results) {
  return fromJS(results).map((obj) => fromJS(obj.toJSON()))
}

export function resultsToMap(results) {
  const list = resultsToList(results)
  return list.toMap().mapEntries(([k, v]) => [v.get('objectId'), v])
}

export function resultToMap(result) {
  return fromJS(result.toJSON())
}
