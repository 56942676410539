import React from 'react'
import styled from 'styled-components'
import ShimmerAnimation from './ShimmerAnimation.jsx'

const BoneWrapper = styled.div`
  height: ${({ height }) => height}px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin-bottom: ${({ bottomMargin }) => bottomMargin}px;
  background-color: ${({ theme }) => theme.color.grey.shade7};
  border-radius: 4px;
  overflow: hidden;
`

const Bone = ({ height, bottomMargin, maxWidth, className }) => {
  return (
    <BoneWrapper
      height={height}
      bottomMargin={bottomMargin}
      maxWidth={maxWidth}
      className={className}
    >
      <ShimmerAnimation />
    </BoneWrapper>
  )
}

export default Bone
