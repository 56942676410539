import { css } from 'styled-components'
import { SIZES, WEIGHTS } from '../constants'
import { mediaQueries } from '../mediaQueries'

const fontColor = ({ disabled, color, design, theme }) => {
  if (disabled) return theme.color.grey.shade1
  if (Object.keys(theme.color).includes(color) && theme.color[color].default)
    return theme.color[color].default
  return (
    {
      subtitle: theme.color.grey.shade4,
      link: theme.color.blue.default
    }[design] || theme.color.grey.shade2
  )
}

const fontStyle = ({ design, italic }) => {
  if (italic) return 'italic'
  return (
    {
      testimonials: 'italic',
      disabledNumbers: 'italic',
      link: 'inherit'
    }[design] || null
  )
}

const lineHeight = ({ design }) => {
  return (
    {
      //  Headers
      h1: '54px',
      h2: '38px',
      h3: '28px',
      h4: '28px',
      h5: '26px',
      h6: '24px',
      h7: '22px',
      h8: '22px',

      //  Bodies
      body1: '22px',
      body2: '22px',
      body3: '18px',
      body4: '18px',
      body5: '14px',
      body6: '14px'
    }[design] || '1.5em'
  )
}

const fontFamily = ({ design, theme }) => {
  return (
    {
      h1: theme.text.fonts.heading,
      h2: theme.text.fonts.heading,
      h3: theme.text.fonts.heading,
      link: 'inherit'
    }[design] || theme.text.fonts.body
  )
}

const textTransform = ({ design }) => {
  return (
    {
      subtitle: 'uppercase',
      navBig: 'uppercase',
      body2Header: 'uppercase',
      link: 'inherit'
    }[design] || null
  )
}

const letterSpacing = ({ design }) => {
  return (
    {
      subtitle: '1px',
      navSmall: '1px',
      navBig: '1px',
      link: 'inherit'
    }[design] || null
  )
}

const fontWeight = ({ design, theme }) => {
  return (
    {
      h1: theme.weights[WEIGHTS.SEMIBOLD],
      h2: theme.weights[WEIGHTS.SEMIBOLD],
      h3: theme.weights[WEIGHTS.SEMIBOLD],
      h4: theme.weights[WEIGHTS.MEDIUM],
      h5: theme.weights[WEIGHTS.MEDIUM],
      h6: theme.weights[WEIGHTS.MEDIUM],
      h7: theme.weights[WEIGHTS.MEDIUM],
      h8: theme.weights[WEIGHTS.REGULAR],
      body1: theme.weights[WEIGHTS.REGULAR],
      body2: theme.weights[WEIGHTS.MEDIUM],
      body3: theme.weights[WEIGHTS.REGULAR],
      body4: theme.weights[WEIGHTS.MEDIUM],
      body5: theme.weights[WEIGHTS.REGULAR],
      body6: theme.weights[WEIGHTS.MEDIUM],

      subtitle: theme.weights[WEIGHTS.MEDIUM],
      testimonials: theme.weights[WEIGHTS.LIGHT],
      disabledNumbers: theme.weights[WEIGHTS.BOLD],
      navSmall: theme.weights[WEIGHTS.REGULAR],
      navBig: theme.weights[WEIGHTS.REGULAR],
      body2Header: theme.weights[WEIGHTS.MEDIUM],
      link: 'inherit'
    }[design] || theme.weights[WEIGHTS.REGULAR]
  )
}

const fontSize = ({ design, theme }) => {
  return (
    {
      h1: `48px`,
      h2: `36px`,
      h3: `24px`,
      h4: `24px`,
      h5: `22px`,
      h6: `20px`,
      h7: `18px`,
      h8: `18px`,
      body1: '16px',
      body2: '16px',
      body3: '14px',
      body4: '14px',
      body5: '12px',
      body6: '12px',

      subtitle: `${theme.sizes[SIZES.MEDIUM]}px`,
      testimonials: `${theme.sizes[SIZES.EXTRA_LARGE]}px`,
      disabledNumbers: `${theme.sizes[SIZES.HUGE]}px`,
      navSmall: `${theme.sizes[SIZES.MEDIUM]}px`,
      navBig: `${theme.sizes[SIZES.EXTRA_LARGE]}px`,
      body2Header: `${theme.sizes[SIZES.LARGE]}px`,
      p: `${theme.sizes[SIZES.MEDIUM]}px`,
      a: `${theme.sizes[SIZES.MEDIUM]}px`,
      link: `inherit`
    }[design] || `${theme.sizes[SIZES.MEDIUM]}px`
  )
}

const fontMargin = (design) => {
  return (
    {
      h1: '0 0 8px 0',
      h2: '0 0 8px 0',
      h3: '0 0 1.5rem 0',
      h4: '0 0 8px 0',
      h5: '0 0 1rem 0',
      h7: '0 0 8px 0',
      subtitle: '0 0 2rem 0',
      span: '0',
      link: 'inherit'
    }[design] || '0 0 8px 0'
  )
}

export const textStyle = css`
  font-family: ${fontFamily};
  font-weight: ${fontWeight};
  text-transform: ${textTransform};
  letter-spacing: ${letterSpacing};
  color: ${fontColor};
  font-style: ${fontStyle};
  text-align: ${({ center }) => center && 'center'};
  font-size: ${fontSize};
  white-space: ${({ type, design }) => (type === 'h1' || design === 'h1') && 'pre-wrap'};
  line-height: ${lineHeight};
  margin: ${({ noMargin, design }) => (noMargin ? 0 : fontMargin(design))};
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  cursor: ${({ type, disabled }) =>
    type === 'a' || type === 'link' ? (disabled ? 'inherit' : 'pointer') : null};
  ${mediaQueries.phone} {
    ${({ design, theme }) =>
      ({
        h1: `
          font-size: ${theme.sizes[SIZES.EXTRA_HUGE]}px;
          line-height: 38px;
        `,
        h2: `
          font-size: ${theme.sizes[SIZES.EXTRA_GRAND]}px;
          line-height: 28px;
        `
      }[design] || null)}
  }
`
