import { ICONS, Icon, P, Span } from '@sellpy/design-system-react-web'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { ErrorMessage } from '@hookform/error-message'
import get from 'lodash/get'
import { gql, useQuery } from '@apollo/client'
import PayoutChips from '../components/PayoutChips.jsx'
import { formatInputValue, formatOutputValue } from '../utils.js'

const defaultValue = '0'

const AlwaysTakeUpHeightWrapper = styled.div`
  min-height: 18px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const GreyBox = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-radius: 8px;
`

const Input = styled.input`
  width: 100%;
  padding: 0;
  text-align: right;
  border: none;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  outline: none;
  font-family: ${({ theme }) => theme.text.fonts.body};
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    color: ${({ theme }) => theme.color.grey.shade6};
    opacity: 1;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`

const LeftElement = styled.div`
  flex: 3;
`
const MiddleElement = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 18px; // To properly center arrow with input we need to account for input label height
`
const RightElement = styled.div`
  flex: 3;
`

const ErrorP = styled(P)`
  color: ${({ theme }) => theme.color.red.default} !important;
`

const GET_CREDIT_MULTIPLIER = gql`
  query getCreditMultiplier {
    creditMultiplier: getCreditMultiplier
  }
`

const CreditConvert = ({ balance }) => {
  const { setValue } = useFormContext()
  const { t } = useTranslation(['balance', 'common'])
  const currency = balance.currency
  const {
    formState: { errors }
  } = useFormContext()

  const { data: creditData, loading: creditMultiplierLoading } = useQuery(GET_CREDIT_MULTIPLIER)

  if (creditMultiplierLoading) return null
  const creditMultiplier = creditData?.creditMultiplier

  const handleChange = (amount, type = 'input') => {
    const changedInput = type === 'input' ? 'amount' : 'outputAmount'
    const output = type === 'input' ? 'outputAmount' : 'amount'
    const formatFunc = type === 'input' ? formatOutputValue : formatInputValue

    setValue(changedInput, amount, { shouldValidate: true })
    if (!amount) {
      setValue(output, null, { shouldValidate: true })
      return
    }
    setValue(output, formatFunc({ amount, currency }, creditMultiplier).value, {
      shouldValidate: true
    })
  }
  const erroneousAmount = Boolean(get(errors, 'amount'))
  const erroneousOutput = Boolean(get(errors, 'outputAmount'))
  return (
    <>
      <Column>
        <Row>
          <LeftElement>
            <CreditInput currency={currency} handleChange={handleChange} balance={balance} t={t} />
          </LeftElement>
          <MiddleElement>
            <Icon name={ICONS.ARROW_RIGHT} style={{ fontSize: '2rem' }} />
          </MiddleElement>
          <RightElement>
            <CreditOutput
              currency={currency}
              handleOutputChange={handleChange}
              creditMultiplier={creditMultiplier}
              t={t}
            />
          </RightElement>
        </Row>
        <AlwaysTakeUpHeightWrapper>
          {erroneousAmount ? (
            <ErrorMessage errors={errors} name={'amount'} as={<ErrorP design='body3' noMargin />} />
          ) : (
            erroneousOutput && (
              <ErrorMessage
                errors={errors}
                name={'outputAmount'}
                as={<ErrorP design='body3' noMargin />}
              />
            )
          )}
        </AlwaysTakeUpHeightWrapper>
      </Column>
      <PayoutChips balance={balance} handleChange={handleChange} />
    </>
  )
}

const CreditInput = ({ handleChange, balance, t }) => {
  const { amount, currency } = balance
  const { register } = useFormContext()
  const theme = useTheme()

  return (
    <>
      <P design='body3' noMargin style={{ color: theme.color.grey.shade3 }}>
        {t('credit.convert.input.label')}
      </P>
      <GreyBox>
        <Input
          {...register('amount', {
            required: t('common:genericErrors.required'),
            min: 0,
            max: {
              value: amount,
              message: t('credit.input.max', { amount, currency })
            }
          })}
          placeholder={defaultValue}
          type='number'
          onChange={(e) => handleChange(e.target.value, 'input')}
          step='.01'
        />
        <P design='body2' noMargin>
          {currency}
        </P>
      </GreyBox>
    </>
  )
}

const CreditOutput = ({ currency, handleOutputChange, creditMultiplier, t }) => {
  const { register } = useFormContext()
  const theme = useTheme()
  return (
    <>
      <P design='body3' noMargin style={{ color: theme.color.grey.shade3 }}>
        {t('credit.convert.output.label')}{' '}
        <Span design='body3' noMargin style={{ fontWeight: 500 }}>
          {t('credit.convert.output.bonus', { amount: Math.round(creditMultiplier * 100 - 100) })}
        </Span>
      </P>
      <GreyBox>
        <Input
          {...register('outputAmount', {
            required: true
          })}
          placeholder={defaultValue}
          type='number'
          step='.01'
          onChange={(e) => handleOutputChange(e.target.value, 'output')}
        />
        <P design='body2' noMargin>
          {currency}
        </P>
      </GreyBox>
    </>
  )
}

export default CreditConvert
