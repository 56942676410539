import { Map, Record } from 'immutable'
import { SET_LOYALTY_START } from './actions'

const InitialState = Record({
  freeBagEligibility: Map(),
  startOfLoyalty: Map()
})

const loyaltyReducer = (state = new InitialState(), { type, userId, value }) => {
  switch (type) {
    case SET_LOYALTY_START:
      return state.setIn(['startOfLoyalty', userId], value)
    default:
      return state
  }
}

export default loyaltyReducer
