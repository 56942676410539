import styled from 'styled-components'
import { IconButton } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../../uiComponents/mediaQueries'
import { Wrapper as DotsWrapper } from '../../../uiComponents/swipeableViews/Dots.jsx'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: sticky;
  ${mediaQueries.largerThanTablet} {
    top: ${({ headerHeight }) => `calc(${headerHeight}px + 1rem)`};
    position: sticky;
  }
  ${mediaQueries.tablet} {
    ${DotsWrapper} {
      margin-bottom: 16px;
    }
  }
`

export const ThumbnailList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  button {
    display: flex;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
`

export const Thumbnail = styled.li`
  display: flex;
  padding: 4px;
  border: ${({ theme, active }) =>
    `1px solid ${active ? theme.color.black.default : theme.color.grey.shade6}`};
  cursor: pointer;
  button {
    cursor: pointer;
  }
  :hover {
    border-color: ${({ theme, active }) => !active && theme.color.grey.shade5};
  }
`

export const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${mediaQueries.tablet} {
    margin: 0 -16px; // this makes the carousel full width on mobile viewports
  }
`

export const Slide = styled.li`
  min-width: 100%;
  display: flex;
  aspect-ratio: 1/1;
  position: relative;
  ${mediaQueries.largerThanTablet} {
    cursor: zoom-in;
  }
`

export const StyledPicture = styled.picture`
  /* ((max content width) / (nr of cols) ) * (amount of cols for carousel and thumbnails) - (thumbnail width) - (thumbnail margin and padding) - one col gap */
  max-height: calc((1200px / 12) * 7 - 72px - 10px - 24px);
  object-fit: contain;
  width: 100%;
  ${mediaQueries.tablet} {
    height: 100vw;
    object-fit: contain;
  }
`

export const SubTextOnImage = styled.div`
  position: absolute;
  bottom: 24px;
  text-align: center;
  padding: 12px;
  background-color: rgba(33, 39, 33, 0.7);
  left: 50%;
  transform: translateX(-50%);
  ${mediaQueries.tablet} {
    bottom: 16px;
  }
`

export const SimilarItemsButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  bottom: 24px;
  ${mediaQueries.tablet} {
    right: 16px;
    bottom: 16px;
  }
`
