/* global FB */

export const login = () => {
  return promisify(FB.login, {
    scope: 'email',
    return_scopes: true
  })
}

export const relogin = () => {
  return promisify(FB.login, {
    auth_type: 'rerequest',
    scope: 'email',
    return_scopes: true
  })
}

const promisify = (callback, options, path) => {
  if (!path) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line standard/no-callback-literal
      callback(function(response) {
        if (response && !response.error) {
          resolve(response)
        } else {
          reject(response.error)
        }
      }, options)
    })
  } else {
    return new Promise((resolve, reject) => {
      callback(path, options, function(response) {
        if (response && !response.error) {
          resolve(response)
        } else {
          reject(response.error)
        }
      })
    })
  }
}
