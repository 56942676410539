import { List, Record } from 'immutable'
import { Settings } from 'luxon'
import * as intlActions from './actions'
Settings.defaultLocale = 'sv-SE'

const InitialState = Record({
  phoneCountryList: List()
})
const initialState = new InitialState()

export const getPhoneCountryListSelector = (state) => state.intl.phoneCountryList

export default function intlReducer(state = initialState, action) {
  switch (action.type) {
    case intlActions.SET_ALL_PHONE_COUNTRIES: {
      return state.set('phoneCountryList', action.payload)
    }
  }
  return state
}
