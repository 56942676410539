import { money } from '@sellpy/commons'

const _format = (toCurrencyJSConverter) => ({ amount, currency }) => {
  if (amount == null || !currency) return ''
  const formattedAmount = toCurrencyJSConverter({ amount, currency }).format()
  return `${
    currency === 'SEK' ? formattedAmount.replace(new RegExp(',00$'), '') : formattedAmount // Remove decimals if amount is integer (only for SEK)
  } ${currency}`
}

export const formatBaseUnit = (valueObject) => _format(money.baseUnitToCurrencyJS)(valueObject)

export const formatSubUnit = (valueObject) => _format(money.subUnitToCurrencyJS)(valueObject)
