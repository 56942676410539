import { combineReducers } from 'redux'
import * as actions from './actions'

const activeIdFavoriteModal = (state = null, { type, itemId }) => {
  switch (type) {
    case actions.SHOW_EDIT_FAVORITE_MODAL:
      return itemId
    case actions.HIDE_EDIT_FAVORITE_MODAL:
      return null
    default:
      return state
  }
}

const loggedOutFavoriteModal = (state = { showModal: false }, { type }) => {
  switch (type) {
    case actions.SHOW_LOGGED_OUT_FAVORITE_MODAL:
      return { ...state, showModal: true }
    case actions.HIDE_LOGGED_OUT_FAVORITE_MODAL:
      return { ...state, showModal: false }
    default:
      return state
  }
}

export default combineReducers({
  activeIdFavoriteModal,
  loggedOutFavoriteModal
})
