import React from 'react'
import { Chip } from '@sellpy/design-system-react-web'
import styled from 'styled-components'
import { mediaQueries } from '../../../../../../uiComponents/mediaQueries.js'
import { SuggestionItem } from '../components/SuggestionItem.jsx'

export const ChipList = styled.ul`
  list-style-type: 'none';
  padding: 0 16px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${mediaQueries.tablet} {
    padding-bottom: 4px;
  }
`

export const ChipSlider = styled.ul`
  list-style-type: 'none';
  overflow: auto;
  display: flex;
  gap: 8px;
  padding: 0 16px;
  margin: 12px 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const SuggestionChipList = ({ children, isSlider, ...listProps }) => {
  return isSlider ? (
    <ChipSlider {...listProps}>{children}</ChipSlider>
  ) : (
    <ChipList {...listProps}>{children}</ChipList>
  )
}

export const SuggestionChipItem = ({ title, getItemProps, item, source }) => (
  <SuggestionItem getItemProps={getItemProps} item={item} source={source}>
    <Chip title={title} color='lightBlue' />
  </SuggestionItem>
)
