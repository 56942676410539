import styled, { css } from 'styled-components'
import { P, Spinner } from '@sellpy/design-system-react-web'
import TextInput from '../components/TextInput.jsx'

export const _handleFocusColor = ({ theme, erroneous, disabled }) =>
  disabled ? theme.color.grey.shade6 : erroneous ? theme.color.red.defalt : theme.color.grey.shade2

export const _handleActiveColor = ({ theme, erroneous, disabled, expanded }) =>
  erroneous
    ? theme.color.red.default
    : expanded
    ? theme.color.grey.shade2
    : disabled
    ? theme.color.grey.shade6
    : theme.color.grey.shade3

/* Disable highlightning on mobile */
const stateEffects = css`
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const StyledTextInput = styled(TextInput)`
  padding: 0;
  margin-right: 0.5rem;
  border-right-style: solid;
  border-right-width: 1px;
  &::placeholder {
    color: ${({ theme }) => theme.color.grey.shade3};
  }
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-color: ${({ isSearchSelector = false, theme }) =>
    isSearchSelector ? theme.color.grey.shade6 : 'transparent'};
`

export const border = css`
  border-radius: 4px;
  box-shadow: 0 0 0 1px ${_handleActiveColor};
`

export const Wrapper = styled.div`
  text-overflow: ellipsis;
  position: relative;
  display: flex;
  flex-direction: column;
  & > p {
    color: ${_handleActiveColor};
  }
  &:focus-within {
    & p {
      color: ${_handleFocusColor};
    }
  }
  &:hover {
    & p {
      color: ${_handleFocusColor};
    }
    div:nth-child(2) {
      box-shadow: 0 0 0 1px ${_handleFocusColor};
    }
  }
  width: 100%;
`

const BaseBox = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  ${border}
  background-color: white;
  &:focus-within {
    box-shadow: 0 0 0 1px ${_handleFocusColor};
  }
  word-break: break-all;
  cursor: ${({ disabled, expandable }) => (disabled || !expandable ? 'default' : 'pointer')};
  ${stateEffects}
`

export const Box = styled(BaseBox)`
  padding: 8px 8px 8px 16px;
  height: 48px;
  & i {
    color: ${({ theme, disabled }) => disabled && theme.color.grey.shade6};
  }
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const ExpandingBox = styled(BaseBox)`
  min-height: 48px;
`

export const Dropdown = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  list-style-type: none;
  position: absolute;
  top: 90%;
  left: 0;
  background-color: #fff;
  z-index: 99; //should likely go over ALL other elements, cant think of exceptions
  border-radius: 4px;
  box-shadow: 0 0 0 1px ${_handleFocusColor};
  overflow: scroll;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-height: 200px;
`

export const Item = styled.li`
  padding: 8px 8px 8px 16px;
  background-color: ${({ theme, isSelected }) => isSelected && theme.color.blue.shade10};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.blue.shade10};
  }
`

export const IconSizing = styled.div`
  width: 24px;
  height: 24px;
`

export const IconAndValueContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  & p {
    color: ${({ theme }) => theme.color.grey.shade2};
  }
`

export const ErrorLabel = styled(P)`
  color: ${({ theme }) => theme.color.red.default};
`
export const StyledSpinner = styled(Spinner)`
  height: 48px;
  width: 48px;
  padding: 8px 0;
`
export const SubLabelContainer = styled.div`
  min-height: 22px;
`

export const ChipContainer = styled.div`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: ${({ theme }) => theme.color.grey.shade5};
  min-height: 32px;
  width: 100%;
  margin: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`
