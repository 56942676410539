import React from 'react'
import styled, { useTheme } from 'styled-components'
import { ICONS, Icon } from '@sellpy/design-system-react-web'

export const Button = styled.button`
  border: none;
  cursor: pointer;
  margin: 0;
  padding: ${({ isToRight }) => (isToRight ? '0 16px 0 8px' : '0 8px 0 16px')};
  min-width: 30px;
  outline: none;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white.default};
`

export const SearchButton = ({ isToRight }) => {
  const theme = useTheme()

  return (
    <Button isToRight={isToRight}>
      <Icon name={ICONS.SEARCH} style={{ fontSize: '24px', color: theme.color.black.default }} />
    </Button>
  )
}
