import fetch from 'isomorphic-fetch'
import { useEffect, useState } from 'react'

let locationPromise = null
let locationCache = null

export const useInferredLocation = () => {
  const [inferredLocation, setInferredLocation] = useState(locationCache?.Country)
  useEffect(() => {
    const identifyLocation = async () => {
      locationPromise =
        locationPromise ||
        fetch('https://europe-west1-sellpy-1098.cloudfunctions.net/ip-location-go')
          .then((response) => response.json())
          .catch(console.warn)
      locationCache = await locationPromise
      setInferredLocation(locationCache?.Country)
    }
    !locationCache && identifyLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return inferredLocation
}
