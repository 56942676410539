import Parse from '../../client/lib/parse'
import { cacheEntities } from '../entities/actions'

export const getPromoCode = ({ promoCodeId }) => {
  return (dispatch) => {
    return Parse.Cloud.run('getPromoCode', {
      objectId: promoCodeId
    }).then((promoCode) => dispatch(cacheEntities(promoCode)))
  }
}
