import React from 'react'
import { useSelector } from 'react-redux'
import { order as orderTools, money } from '@sellpy/commons'
import { useTranslation } from 'react-i18next'
import { formatSubUnit } from '../../../../../common/lib/moneyFormat'
import { P } from '../../../../uiComponents'
import {
  priceForReservedItemsSelector,
  totalPriceForReservedItemsSelector,
  getRegularItemsInCart
} from '../../../../../common/entities/selectors/checkout'
import { useResponsive } from '../../../../hooks'
import { useMOV } from '../../../../checkout/useMOV.js'
import { useMIV } from '../../../../checkout/useMIV'

const region = process.env.REGION

const onlyFreeFreightItems = (items, itemCosts, MIV) =>
  items.every(
    (item) =>
      MIV &&
      orderTools.isFreeFreightItem(MIV)({
        item,
        itemPricing: itemCosts.get(item.get('objectId')),
        region
      })
  )

export const ShortFreeFreightNudge = ({ className }) => {
  const { t } = useTranslation('cart')
  const { isTablet } = useResponsive()
  const items = useSelector(getRegularItemsInCart)
  const itemCosts = useSelector((state) => priceForReservedItemsSelector(state, { items }))
  const totalCost = useSelector((state) => totalPriceForReservedItemsSelector(state, { items }))
  const { mov } = useMOV()
  const { MIV } = useMIV()
  if (!mov || !MIV) return null
  return (
    totalCost.amount < mov.amount &&
    !onlyFreeFreightItems(items, itemCosts, MIV) && (
      <P design={isTablet ? 'body5' : 'body6'} noMargin italic className={className}>
        {t('regularItems.shortFreeFreight', {
          amountLeft: formatSubUnit(money.subtract(mov)(totalCost)),
          freeFreightLimit: formatSubUnit(mov)
        })}
      </P>
    )
  )
}
