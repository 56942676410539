import { combineReducers } from 'redux'
import cart from '../cart/reducer'
import collaboration from '../collaboration/reducer'
import sale from '../sale/reducer'
import intl from '../intl/reducer'
import items from '../items/reducer'
import order from '../order/reducer'
import orders from '../orders/reducer'
import sellerLanding from '../landing/seller/reducer'
import ui from '../ui/reducer'
import user from '../user/reducer'
import entities from '../entities/reducer'
import marketLanding from '../marketLanding/reducer'
import favorites from '../favorites/reducer'
import credit from '../credit/reducer'
import freight from '../freight/reducer'
import balance from '../balance/reducer'
import dac7 from '../dac7/reducer'
import { USER_LOGOUT } from '../user/actions'
import { clearCloudCache } from '../lib/parseTools'
import containerOrder from '../containerOrder/reducer'
import loyalty from '../loyalty/reducer'

const appReducer = combineReducers({
  containerOrder,
  cart,
  collaboration,
  intl,
  items,
  order,
  orders,
  sellerLanding,
  ui,
  user,
  entities,
  marketLanding,
  favorites,
  credit,
  freight,
  sale,
  balance,
  loyalty,
  dac7
})

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    clearCloudCache()
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
