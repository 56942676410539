// https://developers.google.com/tag-manager/quickstart

export const snippets = {
  tags: function({ id, dataLayer, dataLayerName }) {
    if (!id) console.warn('GTM Id is required')

    // Google Tag Manager code snippet
    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');
      window.${dataLayerName}.push(${JSON.stringify({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search
    })})`

    const dataLayerVar = this.dataLayer(...dataLayer, dataLayerName)

    return {
      script,
      dataLayerVar
    }
  },
  dataLayer: function(dataLayer, dataLayerName) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`
  }
}
