import { money, promoCode as promoTools } from '@sellpy/commons'
import { region, nrOfAllowedBagsPerOrder } from '../../common/region/region'

export const calculateSelectedContainersPrices = ({ nrOfBags, containerPrice, promoCode }) =>
  promoTools.applyBagPromoCode({
    promoCode,
    nrOfBags,
    containerPrice,
    region: region()
  })

export const calculateTotalAmount = ({ nrOfBags, containerPrice, promoCode }) =>
  nrOfBags &&
  money.toSubUnit(
    money.add(...calculateSelectedContainersPrices({ nrOfBags, containerPrice, promoCode }))
  )

export const generateContainerSelectOptions = ({ containerPrice, promoCode, t }) => {
  const containerPriceOptions = promoTools.applyBagPromoCode({
    promoCode,
    nrOfBags: nrOfAllowedBagsPerOrder,
    containerPrice,
    region: region()
  })

  return containerPriceOptions.map((_, index) =>
    promoCode
      ? {
          label: t('quantityOfBagsPromoCode', {
            count: index + 1,
            originalPrice: money.formatSubUnit(money.multiply(index + 1)(containerPrice)),
            price: money.formatBaseUnit(money.add(...containerPriceOptions.slice(0, index + 1)))
          }),
          value: index + 1
        }
      : {
          label: t('quantityOfBagsPriced', {
            count: index + 1,
            price: money.formatBaseUnit(money.add(...containerPriceOptions.slice(0, index + 1)))
          }),
          value: index + 1
        }
  )
}
