import React, { useEffect } from 'react'
import { Button, CheckWithConfetti, H4, H7, Icon, ICONS } from '@sellpy/design-system-react-web'
import styled, { useTheme } from 'styled-components'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryRefHandlers } from '@apollo/client'
import { useQuery } from '../hooks'
import { P, SIZES } from '../uiComponents'
import LayoutCenteredColumn, {
  CENTERED_COLUMN_VARIANTS
} from '../components/LayoutCenteredColumn.jsx'
import { mediaQueries } from '../uiComponents/mediaQueries'

const ViewContainer = styled.div`
  padding: 16px 0;
  ${mediaQueries.phone} {
    width: 100%;
  }
  max-width: 664px;
  background-color: ${({ theme }) => theme.color.white.default};
  margin: 16px auto;
`

export const SelectedMethodInfo = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.blue.shade10};
  color: ${({ theme }) => theme.color.white.default};
  padding: 8px;
  gap: 8px;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 100px;

  ${mediaQueries.phone} {
    margin-top: 56px;
    margin-bottom: 40px;
  }
`

const VerificationInfoSection = styled.div`
  margin-top: 30px;
`

const VerificationInfo = styled.div`
  display: flex;
  flex-direction: row;
`

const AutomaticPayoutConfirmation = () => {
  const theme = useTheme()
  const { method } = useQuery()
  const navigate = useNavigate()
  const { t } = useTranslation(['payoutSetup', 'common'])

  const automaticPayoutConfigRef = useLoaderData()
  const { refetch } = useQueryRefHandlers(automaticPayoutConfigRef)

  useEffect(() => {
    refetch()
  }, [])

  const getSelectedMethodName = () => {
    switch (method) {
      case 'bankAccountVerified':
      case 'bankAccount':
        return t('choice.bankAccount.header')
      case 'charity':
        return t('choice.charity.header')
      case 'credits':
        return t('choice.credits.header')
      default:
        return null
    }
  }

  return (
    <>
      <LayoutCenteredColumn variant={CENTERED_COLUMN_VARIANTS.NARROW}>
        <ViewContainer>
          <ContentContainer>
            <CheckWithConfetti style={{ width: '229px', marginBottom: '20px' }} />
            <H4>{t('confirmationPage.selectedMethod.header')}</H4>
            <SelectedMethodInfo>
              <H7 noMargin>{getSelectedMethodName()}</H7>
            </SelectedMethodInfo>
            <VerificationInfoSection>
              {method === 'bankAccountVerified' && (
                <VerificationInfo>
                  <Icon
                    name={ICONS.CHECKMARK}
                    style={{
                      color: theme.color.blue.default,
                      paddingRight: '10px',
                      fontSize: '24px'
                    }}
                  />
                  <P
                    design='body3'
                    noMargin
                    style={{ color: theme.color.grey.shade3, marginTop: '7px' }}
                  >
                    {t('confirmationPage.infoList.bankAccountVerified')}
                  </P>
                </VerificationInfo>
              )}
              <VerificationInfo>
                <Icon
                  name={ICONS.CHECKMARK}
                  style={{
                    color: theme.color.blue.default,
                    paddingRight: '10px',
                    fontSize: '24px'
                  }}
                />
                <P
                  design='body3'
                  noMargin
                  style={{ color: theme.color.grey.shade3, marginTop: '7px' }}
                >
                  {t('confirmationPage.infoList.automaticTransfer')}
                </P>
              </VerificationInfo>
              <VerificationInfo>
                <Icon
                  name={ICONS.CHECKMARK}
                  style={{
                    color: theme.color.blue.default,
                    paddingRight: '10px',
                    fontSize: '24px'
                  }}
                />
                <P
                  design='body3'
                  noMargin
                  style={{ color: theme.color.grey.shade3, marginTop: '7px' }}
                >
                  {t('confirmationPage.infoList.withdraw')}
                </P>
              </VerificationInfo>
            </VerificationInfoSection>
            <P
              design='body3'
              style={{
                color: theme.color.grey.shade3,
                marginTop: '30px',
                marginBottom: '20px',
                textAlign: 'center'
              }}
            >
              {t('confirmationPage.bottomNote')}
            </P>
            <Button
              variant={'outlined'}
              size={SIZES.SMALL}
              label={t('doneButton.label')}
              onClick={() => navigate('/withdraw')}
            />
          </ContentContainer>
        </ViewContainer>
      </LayoutCenteredColumn>
    </>
  )
}

export default AutomaticPayoutConfirmation
