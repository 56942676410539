import {
  AmericanExpress,
  CartesBancaires,
  IDeal,
  Klarna,
  Mastercard,
  PayPal,
  Swish,
  Visa,
  Bancontact,
  MobilePay,
  OnlineBankingPL
} from '@sellpy/design-system-react-web'
import React from 'react'

export const PAYMENT_SERVICE_PROVIDER_ICONS = {
  visa: <Visa />,
  mastercard: <Mastercard />,
  amex: <AmericanExpress />,
  paypal: <PayPal />,
  klarna: <Klarna />,
  klarna_paynow: <Klarna />,
  swish: <Swish />,
  bancontact: <Bancontact />,
  ideal: <IDeal />,
  cartes_bancaires: <CartesBancaires />,
  mobilepay: <MobilePay />,
  onlineBanking_PL: <OnlineBankingPL />
}
