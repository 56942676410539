import styled from 'styled-components'

export const SwipeableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SwipeableList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  scroll-snap-type: x mandatory;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  grid-column-gap: 8px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  li {
    scroll-snap-align: center;
    grid-column: span 1;
  }
`
