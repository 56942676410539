import { Button, H1, H3, P } from '@sellpy/design-system-react-web'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteError, Link } from 'react-router-dom'
import styled from 'styled-components'
import { captureException } from '@sentry/react'
import { mediaQueries } from '../uiComponents/mediaQueries'

const Wrapper = styled.div`
  margin: 0 auto;
  ${({ theme }) => theme.layout.contentPadding}
`

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  ${mediaQueries.tablet} {
    padding: ${({ theme }) => `48px ${theme.layout.standardPaddingTablet}`};
    margin: ${({ theme }) => `0 -${theme.layout.standardPaddingTablet}`};
  }
  ${mediaQueries.phone} {
    padding: ${({ theme }) => `48px ${theme.layout.standardPaddingMobile}`};
    margin: ${({ theme }) => `0 -${theme.layout.standardPaddingMobile}`};
  }
  padding: ${({ theme }) => `48px ${theme.layout.standardPaddingDesktop}`};
  margin: ${({ theme }) => `0 -${theme.layout.standardPaddingDesktop}`};
`

const ErrorMessageWrapper = styled.div`
  background: ${({ theme }) => theme.color.grey.shade9};
  border-radius: 8px;
  padding: 16px;
`

export const ErrorElement = () => {
  const error = useRouteError()
  const { t } = useTranslation('general')
  if (
    error.message.toLowerCase().includes('Failed to fetch'.toLowerCase()) ||
    error.name === 'ChunkLoadError'
  ) {
    console.error(error)
    captureException(error)
    return (
      <Wrapper>
        <Info>
          <div style={{ textAlign: 'center' }}>
            {/* Not always true that we've updated the page, but what the hell */}
            <H1 design='h2'>{t('routeLoading.newUpdate')}</H1>
            <H3 design='h4' center>
              {t('routeLoading.reloadText')}
            </H3>
            <Button
              onClick={() => window.location.reload(true)}
              label={t('routeLoading.reloadButton')}
            />
          </div>
        </Info>
      </Wrapper>
    )
  }

  if (error.name !== 'ChunkLoadError') {
    console.error(error)
    captureException(error)
  }

  return (
    <Wrapper>
      <Info>
        <H1 noMargin design='h2'>
          {t('routeLoading.error1')}
        </H1>
        <ErrorMessageWrapper>
          <P noMargin design='body1'>
            <strong>Error:</strong> {`"${error}"`}
          </P>
          <P noMargin design='body1'>
            <strong>Location:</strong> {window.location.pathname}
          </P>
        </ErrorMessageWrapper>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Button
            onClick={() => window.location.reload(true)}
            size='small'
            label={t('routeLoading.reloadButton')}
          />
          <P noMargin design='body1'>
            {t('routeLoading.genericError.or')}
          </P>
          <Link to='/support'>
            <Button
              size='small'
              variant='outlined'
              label={t('routeLoading.genericError.contactSupport')}
            />
          </Link>
        </div>
      </Info>
    </Wrapper>
  )
}
