import { createSelector } from 'reselect'
import {
  getItemsInCart,
  pricesForItemsInCartSelector,
  reservationsForItemsInCartSelector
} from '../entities/selectors/checkout'

const hideCartFooterOnRoutes = ['/advance-payment', '/payment', '/checkout']

export const isCartFooterVisible = createSelector(
  [(_, { location }) => location, getItemsInCart, pricesForItemsInCartSelector],
  (location, items, pricesForItems) =>
    location &&
    !hideCartFooterOnRoutes.some((route) => location.pathname.includes(route)) &&
    Boolean(items.size) &&
    Boolean(pricesForItems)
)

export const getSortedItemsInCart = createSelector(
  [getItemsInCart, reservationsForItemsInCartSelector],
  (itemsInCart, reservations) =>
    itemsInCart
      .sortBy((item) => {
        const reservation = reservations.find(
          (reservation) => reservation.get('item') === item.get('objectId')
        )
        return reservation ? reservation.get('createdAt') : new Date(1970).toISOString()
      })
      .reverse()
      .toList()
)
