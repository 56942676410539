import { useEffect } from 'react'

const useEscapeKey = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.key === 'Escape') {
        handler(event)
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [ref, handler])
}

export default useEscapeKey
