import { CheckWithConfetti, ICONS, Icon, P } from '@sellpy/design-system-react-web'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { money } from '@sellpy/commons'
import { useDispatch, useSelector } from 'react-redux'
import { getCreditTransactionForPayOut } from '../actions'
import { creditTransactionForPayOutSelector } from '../selectors'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`

const GreyBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.color.grey.shade9};
  gap: 8px;
  border-radius: 8px;
`

const CreditPayoutComplete = ({ creditPayout }) => {
  const { currency } = creditPayout.get('value')
  const amount = creditPayout.get('amount')
  const { t } = useTranslation('balance')
  const theme = useTheme()
  const dispatch = useDispatch()
  const creditTransaction = useSelector((state) =>
    creditTransactionForPayOutSelector(state, creditPayout.id)
  )

  useEffect(() => {
    dispatch(getCreditTransactionForPayOut({ payOut: creditPayout }))
  }, [dispatch, creditPayout])

  return (
    <Container>
      <CheckWithConfetti style={{ width: 230, height: 166 }} />
      <Container>
        <P noMargin design='h4'>
          {t('credit.payout.complete')}
        </P>
        <GreyBox>
          <P noMargin design='h8' style={{ color: theme.color.grey.shade6 }}>
            {amount} {currency}
          </P>
          <Icon name={ICONS.ARROW_RIGHT} size={16} />
          <P noMargin design='h7'>
            {money.formatSubUnit({
              amount: creditTransaction?.get('delta')?.get('amount'),
              currency: creditTransaction?.get('delta')?.get('currency')
            })}
          </P>
        </GreyBox>
        <P noMargin design='body3' style={{ color: theme.color.grey.shade3 }}>
          {t('credit.payout.done')}
        </P>
      </Container>
    </Container>
  )
}

export default CreditPayoutComplete
