import React from 'react'
import styled from 'styled-components'
import MainInfo from './components/mainInfo/MainInfo.jsx'
import DeliveryPaymentInfo from './components/deliveryPaymentInfo/DeliveryPaymentInfo.jsx'
import BottomInfo from './components/BottomInfo.jsx'

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.grey.shade9};
`

const Footer = () => {
  return (
    <Container>
      <MainInfo />
      <DeliveryPaymentInfo />
      <BottomInfo />
    </Container>
  )
}

export default Footer
