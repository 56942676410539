import { useCallback, useEffect } from 'react'

const useClickOutsideMultipleElements = (refArray, handler) => {
  const listener = useCallback(
    (event) => {
      if (refArray.length > 0) {
        if (refArray.some((ref) => ref.current && ref.current.contains(event.target))) {
        } else {
          handler(event)
        }
      }
    },
    [handler, refArray]
  )

  useEffect(() => {
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [listener])
}

export default useClickOutsideMultipleElements
