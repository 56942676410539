import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Button, ICONS, Icon, Spinner } from '@sellpy/design-system-react-web'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { RadioGroup, SIZES } from '../../uiComponents'
import { region } from '../../../common/region/region'
import CharityInformationModal from './CharityInformationModal.jsx'
import { getCharityOrganizationsByRegion } from './charity.gql'

const CharityOptions = () => {
  const { t } = useTranslation(['balance', 'common'])
  const [isOpen, setIsOpen] = useState(false)

  const { data, loading } = useQuery(getCharityOrganizationsByRegion, {
    variables: { region: region() }
  })

  const {
    register,
    formState: { errors }
  } = useFormContext()

  if (!data) return null
  if (loading) return <Spinner />

  const { charityOrganizations } = data
  const organizations = charityOrganizations?.edges?.map((edge) => edge.node)
  const charityOptions = organizations.map((charity) => ({
    value: charity.objectId,
    label: charity.name
  }))

  return (
    <div>
      <CharityInformationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        organizations={organizations}
      />
      <RadioGroup
        name={'charityOrganizationId'}
        register={register}
        registerOptions={{
          required: t('common:genericErrors.required')
        }}
        options={charityOptions}
        errors={errors}
      />
      <Button
        label={t('charity.readmore')}
        rightIcon={<Icon name={ICONS.INFORMATION_CIRCLE} />}
        color={'blue'}
        variant={'text'}
        size={SIZES.SMALL}
        type={'button'}
        onClick={() => setIsOpen(true)}
      />
    </div>
  )
}

export default CharityOptions
