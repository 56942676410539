import { cacheEntities } from '../entities/actions'
import { runCloudCached } from '../lib/parseTools'

export const GET_ORDERS_OVERVIEW_SUCCESS = 'GET_ORDERS_OVERVIEW_SUCCESS'
/**
 * Fetches orders overview from the backend and caches the order ids, freightData and pageInfo in the orders entity in the state.
 *
 * The response contains orders and orderRatings, which are cached in the entities entity in the state.
 * @param {string} after - The cursor to start after.
 * @param {number} first - The number of items to fetch.
 */
export const getOrdersOverview = ({ after = null, first = 10 }) => {
  return async (dispatch) => {
    try {
      await runCloudCached((orderEdges) => {
        const nodes = orderEdges.edges?.map((edge) => edge?.node)
        const entities = nodes
          ?.map((node) => {
            const order = node.order
            const orderRating = node.orderRating
            const items = order.get('items')
            return [order, orderRating, items]
          })
          .flat(2) // Need to flatten two levels
          .filter(Boolean)
        dispatch(cacheEntities(entities))
        const ordersData = nodes?.map((node) => ({
          orderId: node.order?.id,
          freightData: node.freightData
        }))
        dispatch({
          type: GET_ORDERS_OVERVIEW_SUCCESS,
          payload: {
            [after]: {
              ordersData,
              pageInfo: orderEdges.pageInfo
            }
          }
        })
      })('getOrdersOverview', {
        after,
        first
      })
    } catch (error) {
      throw new Error('Could not fetch orders', error)
    }
  }
}
