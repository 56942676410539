import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import sortBy from 'lodash/sortBy'
import { getNotifications } from '../notification/actions'
import { notificationSelector } from '../notification/selectors'

let fetchingData = false

export const useNotifications = () => {
  const dispatch = useDispatch()
  const parseUser = useSelector((state) => state.user.parseUser)
  const notifications = useSelector(notificationSelector)
  const userId = parseUser && parseUser.id

  useEffect(() => {
    if (!fetchingData && userId) {
      fetchingData = true
      dispatch(getNotifications())
        .then(() => (fetchingData = false))
        .catch((error) => {
          fetchingData = false
          return Promise.reject(error)
        })
    }
  }, [dispatch, userId])
  return notifications
}

const GROUPED_NOTIFICATION_TYPES = [
  'favoriteDropInPrice',
  'itemSalePrepared',
  'unratedSale',
  'unratedOrder',
  'saleSortedRating',
  'containerReceived',
  'rejectedItem',
  'p2pListingReminder',
  'p2pListingEnding',
  'userPricingEnding',
  'userPricingEnded'
]

const PRIORITY_NOTIFICATION_TYPES = ['dac7ReportMissing']

export const isPriorityNotification = (notification) =>
  PRIORITY_NOTIFICATION_TYPES.includes(notification.get('type'))

export const isTrivialNotification = (notification) => !isPriorityNotification(notification)

const shouldBeGrouped = (occurences, notification) => {
  const isGroupedType = GROUPED_NOTIFICATION_TYPES.includes(notification.get('type'))
  const occursMorethanOnce = occurences[notification.get('type')] > 1
  return isGroupedType && occursMorethanOnce
}

const groupByTypeSorted = (notifications) => {
  const sortedNotifications = sortBy(
    Object.values(notifications.toJS()),
    (notification) => notification.createdAt
  )

  return sortedNotifications.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: acc[curr.type]
        ? [notifications.get(curr.objectId), ...acc[curr.type]]
        : [notifications.get(curr.objectId)]
    }),
    {}
  )
}

const countOccurenceByType = (notifications) =>
  notifications.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.get('type')]: acc[curr.get('type')] ? acc[curr.get('type')] + 1 : 1
    }),
    {}
  )

const groupedNotifications = (occurences, notifications) =>
  Object.entries(
    groupByTypeSorted(
      notifications.filter((notification) => shouldBeGrouped(occurences, notification))
    )
  )

const singleNotifications = (occurences, notifications) => {
  const singleNotifications = notifications
    .filter((notification) => !shouldBeGrouped(occurences, notification))
    .toArray()

  return sortBy(singleNotifications, (notification) => notification.get('createdAt')).reverse()
}

export const useSortedNotifications = () => {
  const notifications = useNotifications()

  const sortedNotifications = useMemo(() => {
    const occurences = countOccurenceByType(notifications)
    const priorityNotifications = notifications.filter(isPriorityNotification)
    const trivialNotifications = notifications.filter(isTrivialNotification)
    return {
      priority: {
        groupedNotifications: groupedNotifications(occurences, priorityNotifications),
        singleNotifications: singleNotifications(occurences, priorityNotifications)
      },
      trivial: {
        groupedNotifications: groupedNotifications(occurences, trivialNotifications),
        singleNotifications: singleNotifications(occurences, trivialNotifications)
      }
    }
  }, [notifications])

  return { sortedNotifications, noActiveNotifications: notifications.size === 0 }
}
