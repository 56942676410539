import React from 'react'
import styled from 'styled-components'
import { P } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../../../../../uiComponents/mediaQueries'

export const SuggestionListContainer = styled.li`
  padding-bottom: 8px;
  ${mediaQueries.tablet} {
    padding-bottom: 4px;
  }
`

const SuggestionSourceHeader = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
  margin: 8px 16px ${({ $isChipList }) => ($isChipList ? '12px' : '')};

  ${mediaQueries.tablet} {
    margin-bottom: ${({ $isChipList }) => ($isChipList ? '12px' : '4px')};
  }
`

export const SuggestionSection = ({ title, children, isChipList }) => {
  return (
    <SuggestionListContainer>
      {title && (
        <SuggestionSourceHeader design='body3' $isChipList={isChipList}>
          {title}
        </SuggestionSourceHeader>
      )}
      {children}
    </SuggestionListContainer>
  )
}
