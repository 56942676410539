import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { inputValidators, region as regionFunctions } from '@sellpy/commons'
import { SingleLineInput } from '@sellpy/design-system-react-web'
import { region } from '../../../common/region/region.js'

const BankPayoutInput = ({
  internationalBankAccountFields = regionFunctions.internationalBankAccountFields(region())
}) => {
  const { t } = useTranslation(['balance', 'common'])
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return internationalBankAccountFields ? (
    <>
      <SingleLineInput
        label={t('bank.input.bic')}
        type='text'
        {...register('BIC', {
          required: t('common:genericErrors.required'),
          validate: (BIC) => inputValidators.BIC(BIC) || t('bank.input.bicInvalid')
        })}
        errors={errors}
      />
      <SingleLineInput
        label={t('bank.input.iban')}
        type='text'
        {...register('IBAN', {
          required: t('common:genericErrors.required'),
          validate: (IBAN) => inputValidators.IBAN(IBAN) || t('bank.input.ibanInvalid')
        })}
        errors={errors}
      />
    </>
  ) : (
    <>
      <SingleLineInput
        label={t('bank.input.clearing')}
        type='number'
        {...register('clearingNumber', {
          required: { value: true, message: t('common:genericErrors.required') },
          validate: (clearingNumber) =>
            inputValidators.clearingNumberSE(clearingNumber) || t('bank.input.clearingInvalid')
        })}
        errors={errors}
      />
      <SingleLineInput
        label={t('bank.input.account')}
        type='number'
        {...register('accountNumber', {
          required: t('common:genericErrors.required'),
          validate: (accountNumber) =>
            inputValidators.accountNumberSE(accountNumber) || t('bank.input.accountInvalid')
        })}
        errors={errors}
      />
    </>
  )
}

export default BankPayoutInput
