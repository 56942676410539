import styled from 'styled-components'
import { Button, H3, SingleLineInput } from '@sellpy/design-system-react-web'
import { mediaQueries, largerThanLandingPageMaxWidth } from '../../uiComponents/mediaQueries'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.blue.shade10};
  padding: 32px 16px;
  ${mediaQueries.largerThanTablet} {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
  ${largerThanLandingPageMaxWidth} {
    margin-inline: 32px;
  }
`

export const StyledSticker = styled.div`
  grid-column: 1 / 3;
  margin: auto;
`

export const StyledButton = styled(Button)`
  span {
    white-space: nowrap;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mediaQueries.largerThanTablet} {
    grid-column: 3 / 6;
  }
`

export const Header = styled(H3)`
  ${mediaQueries.largerThanTablet} {
    margin-bottom: 0.5rem;
  }
  margin-bottom: 1rem;
`

export const StyledForm = styled.form`
  ${mediaQueries.largerThanTablet} {
    grid-column: 7 / 12;
  }
`

export const FieldWithButton = styled.div`
  display: flex;
  gap: 8px;
  ${mediaQueries.phone} {
    flex-direction: column;
    align-items: stretch;
  }
  ${mediaQueries.largerThanPhone} {
    align-items: flex-end;
  }
`

export const StyledInput = styled(SingleLineInput)`
  flex-grow: 1;
`
