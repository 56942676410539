import styled from 'styled-components'
import { mediaQueries } from '../../mediaQueries'

export const Background = styled.div`
  background: ${({ color, theme }) => theme.color[color].shade10 || color};
  grid-column: 1 / span 12;
  grid-row: 1 / span 2;
  margin: ${({ theme }) => `0 -${theme.layout.standardPaddingDesktop}`};
  ${mediaQueries.tablet} {
    margin: ${({ theme }) => `0 -${theme.layout.standardPaddingTablet}`};
  }
  ${mediaQueries.phone} {
    margin: ${({ theme }) => `0 -${theme.layout.standardPaddingMobile}`};
  }
`
