import styled from 'styled-components'
import { P } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../mediaQueries'

export const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  gap: 32px;
  ${mediaQueries.tablet} {
    gap: 0;
  }
  ${mediaQueries.phone} {
    flex-direction: column;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 60%;

  max-width: 500px;
  padding: 16px 0px;

  ${mediaQueries.tablet} {
    padding: 16px 32px;
  }
  ${mediaQueries.phone} {
    padding: 16px;
  }
`

export const Subtitle = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade3};
`

export const StoreButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  ${mediaQueries.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`
