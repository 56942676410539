import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { marketplace } from '@sellpy/commons'
import { getTotalBalanceForUser } from '../../../common/balance/actions'
import { totalBalanceSelector } from '../../../common/balance/selectors'
import { zeroMoney } from '../../payOuts/utils'
import { region } from '../../../common/region/region'

const useUserTotalBalance = () => {
  const dispatch = useDispatch()
  const totalBalance = useSelector(totalBalanceSelector)
  const [fetchVersion, setFetchVersion] = useState(0)
  const refetch = () => setFetchVersion(fetchVersion + 1)

  useEffect(() => {
    dispatch(getTotalBalanceForUser())
  }, [dispatch, fetchVersion])

  const balance =
    totalBalance?.get('amount') < 0
      ? zeroMoney(marketplace.CURRENCY[region()])
      : totalBalance?.toJS()
  return [balance, refetch]
}

export default useUserTotalBalance
