import { Sha256 } from '@aws-crypto/sha256-js'

export function normalizePhoneNumber(phoneNumber) {
  let normalized = phoneNumber.replace(/\D/g, '')

  normalized = normalized.replace(/^0+/, '')

  return normalized
}

export async function hashStringSHA256(input) {
  const sha256 = new Sha256()

  sha256.update(input)

  const digest = await sha256.digest()

  return Array.from(new Uint8Array(digest))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}
