import { ICONS, Icon } from '@sellpy/design-system-react-web'
import React from 'react'
import styled from 'styled-components'

import { mediaQueries } from '../../mediaQueries.js'

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  ${({ direction }) => (direction === 'right' ? `right: 2rem` : `left: 2rem`)};
  height: 50px;
  width: 50px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  ${mediaQueries.tablet} {
    ${({ direction }) => (direction === 'right' ? `right: 0.5rem` : `left: 0.5rem`)};
    height: 2rem;
    width: 2rem;
  }
`

// eslint-disable-next-line react/display-name
const Arrows = React.forwardRef(({ direction, handleClick }, ref) => (
  <Wrapper ref={ref} direction={direction} onClick={handleClick}>
    {direction === 'right' ? (
      <Icon name={ICONS.CHEVRON_RIGHT} style={{ fontSize: '4rem' }} />
    ) : (
      <Icon name={ICONS.CHEVRON_LEFT} style={{ fontSize: '4rem' }} />
    )}
  </Wrapper>
))

export default Arrows
