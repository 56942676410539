import { money } from '@sellpy/commons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { P } from '../../../../uiComponents'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Divider = styled.div`
  padding-top: 8px;
  margin-top: 8px;
  border-top: ${({ theme }) => `solid 1px ${theme.color.grey.default}`};
  width: 100%;
`
const ItemAmountElement = ({
  adyenAmount,
  creditAmount,
  totalItemAmountWithoutPromoCode,
  totalItemAmountPromoCodeDiscount,
  freightPrice
}) => {
  const { t } = useTranslation('adyenSessionPayment')

  const adyenAmountIsZero = !adyenAmount?.amount

  return (
    <Wrapper>
      <TextWrapper>
        <P design='body3' noMargin>
          {t('amountElements.ItemsAmountElement.itemPrice.label')}
        </P>
        <P design='body3' noMargin>
          {money.formatSubUnit(totalItemAmountWithoutPromoCode)}
        </P>
      </TextWrapper>
      <TextWrapper>
        <P design='body3' noMargin>
          {t('amountElements.ItemsAmountElement.shipping.label')}
        </P>
        <P design='body3' noMargin>
          {money.formatSubUnit(freightPrice)}
        </P>
      </TextWrapper>

      {totalItemAmountPromoCodeDiscount.amount > 0 && (
        <TextWrapper>
          <P design='body3' noMargin>
            {t('amountElements.ItemsAmountElement.promoCodeDiscount.label')}
          </P>
          <P design='body3' noMargin>{`-${money.formatSubUnit(
            totalItemAmountPromoCodeDiscount
          )}`}</P>
        </TextWrapper>
      )}

      {creditAmount.amount > 0 && (
        <TextWrapper>
          <P design='body3' noMargin>
            {t('amountElements.ItemsAmountElement.credits.label')}
          </P>
          <P design='body3' noMargin>{`- ${money.formatSubUnit(creditAmount)}`}</P>
        </TextWrapper>
      )}
      <Divider />
      <TextWrapper>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <P design={'body1'}>{t('amountElements.ItemsAmountElement.adyenAmount.label')}</P>
        </div>
        <P design='body1'>
          {adyenAmountIsZero
            ? money.formatSubUnit({
                amount: 0,
                currency: totalItemAmountWithoutPromoCode.currency
              })
            : money.formatSubUnit(adyenAmount)}
        </P>
      </TextWrapper>
    </Wrapper>
  )
}
export default ItemAmountElement
