import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { mediaQueries } from './mediaQueries'

const Wrapper = styled.div`
  height: ${({ height }) => height || '32px'};
  width: 100%;
  position: relative;
  ${({ withBorder, theme }) =>
    withBorder &&
    `
    :before {
      content: '';
      position: absolute;
      bottom: 50%;
      border-bottom: 1px ${theme.color.grey.shade7} solid;
      width: 100%;
    }
  `};
  ${mediaQueries.tablet} {
    height: ${({ height }) => height || '24px'};
  }
  ${mediaQueries.phone} {
    height: ${({ height }) => height || '24px'};
  }
`

const Divider = ({ style, height, withBorder }) => {
  return <Wrapper style={style} height={height} withBorder={withBorder} />
}

Divider.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
}

export default Divider
