import memoize from 'lodash/memoize'
import Parse from 'parse'
import { createFreightCacheKey } from './helpers'

export const FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_LOADING =
  'FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_LOADING'
export const FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_SUCCESS =
  'FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_SUCCESS'
export const FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_ERROR = 'FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_ERROR'

const getFreightAlternativesForItemMemoized = memoize(
  ({ itemId, region, country }) =>
    Parse.Cloud.run('getFreightAlternativesForItem', {
      itemId,
      region,
      country
    }),
  (params) => JSON.stringify(params)
)

export const fetchFreightAlternativesForItem = (itemId, country) => async (dispatch, getState) => {
  if (!itemId || !country) return null
  dispatch({ type: FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_LOADING })
  getFreightAlternativesForItemMemoized({ itemId, region: process.env.REGION, country })
    .then((fetchedAlternatives) => {
      dispatch({
        type: FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_SUCCESS,
        cacheKey: itemId,
        alternatives: fetchedAlternatives
      })
    })
    .catch((error) => {
      dispatch({ type: FETCH_FREIGHT_ALTERNATIVES_FOR_ITEM_ERROR })
      throw error
    })
}

export const GET_REMAINING_FOR_PACKAGING_IN_FREIGHT_SUCCESS =
  'GET_REMAINING_FOR_PACKAGING_IN_FREIGHT_SUCCESS'

export const getRemainingForPackagingInFreight = (itemIds, country) => async (
  dispatch,
  getState
) => {
  if (!country) return
  const cacheKey = createFreightCacheKey({ itemIds })
  const cached = getState().freight.remainingForPackaging.get(cacheKey)
  if (itemIds.length && !cached) {
    try {
      const fetched = await Parse.Cloud.run('getRemainingForPackagingInFreight', {
        itemIds,
        region: process.env.REGION,
        country
      })
      dispatch({
        type: GET_REMAINING_FOR_PACKAGING_IN_FREIGHT_SUCCESS,
        cacheKey,
        remaining: fetched
      })
    } catch (error) {
      throw error
    }
  }
}
