import styled from 'styled-components'
import { Button } from '@sellpy/design-system-react-web'
import { mediaQueries } from '../../mediaQueries'

export const RightContent = styled.div`
  grid-row: 1;
  padding: calc((100vw - 11 * 2rem - 6rem) / 24);
  grid-column: ${({ mirrored }) => (mirrored ? '1 / span 6' : '7 / span 6')};
  ${mediaQueries.tablet} {
    grid-column: 1 / span 12;
    grid-row: ${({ rightContentToBottom }) => (rightContentToBottom ? 2 : 1)};
  }
  ${mediaQueries.phone} {
    padding-bottom: 1rem;
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc((100vw - 11 * 2rem - 6rem) / 24);
  grid-column: ${({ mirrored }) => (mirrored ? '7 / span 6' : '1 / span 6')};
  ${mediaQueries.tablet} {
    grid-column: 1 / span 12;
    grid-row: ${({ rightContentToBottom }) => (rightContentToBottom ? 1 : 2)};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  ${mediaQueries.phone} {
    width: 100%;
    flex-direction: column;
    a,
    button {
      width: 100%;
    }
  }
`

export const SlabButton = styled(Button)`
  margin: 1rem 1rem 0 0;
  white-space: nowrap;
  ${mediaQueries.phone} {
    margin: 1rem 0 0 0;
    width: 100%;
  }
`
