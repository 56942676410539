import styled from 'styled-components'
import { imageTools } from '@sellpy/commons'
import { mediaQueries } from '../../mediaQueries.js'

export const Cover = styled.div`
  z-index: 1100;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  min-width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  ${mediaQueries.tablet} {
    background-color: ${({ theme }) => theme.color.grey.shade2};
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 60%;
  justify-content: center;
  ${mediaQueries.tablet} {
    width: 100%;
    align-self: center;
    flex-direction: column;
  }
  ${mediaQueries.largeDesktop} {
    width: 45%;
  }
  margin: auto;
`

export const CrossWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: transform ease-in 0.1s;
  ${mediaQueries.largerThanTablet} {
    &:hover {
      transform: scale(1.1);
    }
  }
`

export const DesktopInnerImg = styled.div`
  width: 100%;
  pointer-events: none;
`

const getImageBrightnessStyle = ({ brightness, src }) => {
  if (imageTools.isBrightnessAdjustableImage(src)) {
    return `filter: brightness(${brightness / 100})`
  } else {
    return ''
  }
}

export const StyledFigure = styled.figure`
  width: 100%;
  margin: 0;
  position: relative;
  background-repeat: no-repeat;
  background-image: ${({ zoomEnabled, zoomedImage }) =>
    zoomEnabled ? `url('${zoomedImage}')` : 'none'};
  background-position: 0% 0%;
  transition: background-position ease 0.1s;
  ${DesktopInnerImg} {
    opacity: ${({ zoomEnabled }) => zoomEnabled && 0};
  }
  ${({ brightness, zoomedImage, zoomEnabled }) =>
    zoomEnabled && getImageBrightnessStyle({ brightness, src: zoomedImage })};
`

export const ZoomToolTip = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
`

const StyledBaseImage = styled.img`
  object-fit: contain;
  ${getImageBrightnessStyle}
`

export const StyledDesktopImage = styled(StyledBaseImage)`
  margin-bottom: -3px;
  width: 100%;
  height: 60vw;
  ${mediaQueries.largeDesktop} {
    height: 45vw;
  }
`

export const StyledMobileImage = styled(StyledBaseImage)`
  height: 100vw;
  width: 100%;
`
