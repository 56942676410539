import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Spinner } from '@sellpy/design-system-react-web'
import { useCancelablePromise } from '../../../../common/lib/cancelablePromiseHook.js'
import { SIZES } from '../../constants'
import SimpleModal from '../../modal/SimpleModal.jsx'

const ModalSpinner = styled(Spinner)`
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: '40px';
  width: '40px';
`

const StyledConfirmButton = styled(Button)``

const ButtonWithConfirmation = ({
  onConfirmedAction,
  confirmationTitle,
  confirmationBody,
  onClick,
  noButtonLabel,
  yesButtonLabel,
  buttonComponent,
  className,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation('common')
  const { cancelablePromise } = useCancelablePromise()
  const ButtonComponent = buttonComponent || Button
  const onActionCompleted = (error) => {
    if (error && error.message === 'isCanceled') return
    setOpen(false)
    setLoading(false)
  }
  return (
    <>
      <ButtonComponent
        onClick={(...args) => {
          onClick && onClick(...args)
          setOpen(true)
        }}
        {...rest}
      />
      <SimpleModal
        headline={confirmationTitle || t('confirmation.heading')}
        isOpen={open}
        onClose={onActionCompleted}
        className={className}
        bottomCTA={
          <StyledConfirmButton
            onClick={() => {
              setLoading(true)
              cancelablePromise(onConfirmedAction())
                .then(onActionCompleted)
                .catch(onActionCompleted)
            }}
            size={SIZES.MEDIUM}
            loading={loading}
            label={yesButtonLabel || t('confirmation.yes')}
          />
        }
      >
        {confirmationBody}
        {loading && confirmationBody && <ModalSpinner />}
      </SimpleModal>
    </>
  )
}

ButtonWithConfirmation.propTypes = {
  confirmationTitle: PropTypes.any,
  confirmationBody: PropTypes.any,
  onClick: PropTypes.func,
  onConfirmedAction: PropTypes.func.isRequired,
  noButtonLabel: PropTypes.string,
  yesButtonLabel: PropTypes.string,
  buttonComponent: PropTypes.any
}

export { StyledConfirmButton, ButtonWithConfirmation }
