import { runCloudCached } from '../lib/parseTools'

export const GET_PROCESSING_CARBON_FOOTPRINT_FOR_ITEMS = 'GET_PROCESSING_CARBON_FOOTPRINT_FOR_ITEMS'

export const getProcessingCarbonFootprintForItems = (itemIds) => (dispatch) =>
  runCloudCached((processingCarbonFootprintForItems) => {
    dispatch({
      type: GET_PROCESSING_CARBON_FOOTPRINT_FOR_ITEMS,
      payload: {
        itemIds,
        processingCarbonFootprintForItems
      }
    })
  })('getProcessingCarbonFootprintForItems', { itemIds })
