import config from 'config'
import { getAnalytics, setUserId } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import Parse from 'parse'

let analytics

const initializeAnalytics = () => {
  const app = initializeApp(config.firebase)
  analytics = getAnalytics(app)
  setUserId(analytics, Parse.User.current()?.id)
}

export const getAppAnalytics = () => {
  if (!analytics) initializeAnalytics()

  return analytics
}
