import { DateTime } from 'luxon'
import { marketplace } from '@sellpy/commons'

const DATE_FORMATS = {
  DATE_SHORT: DateTime.DATE_SHORT,
  DATE_LONG: DateTime.DATE_FULL,
  TIME: DateTime.TIME_SIMPLE,
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
  DATETIME_LONG: DateTime.DATETIME_MED
}

export const DEFAULT_CONFIG = {
  lng: process.env.LOCALE_OVERRIDE || marketplace.LOCALE[process.env.REGION],
  fallbackNS: 'common', // used for e.g. form validators & error mapping
  interpolation: {
    escapeValue: false, // react already safes from xss
    format: (value, format, lng) => {
      if (value instanceof Date) {
        format = DATE_FORMATS[format]
        if (!format) throw new Error('Invalid date format')
        return DateTime.fromJSDate(value)
          .setLocale(lng)
          .toLocaleString(format)
      }
      return value
    }
  }
}
