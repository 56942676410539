import { parseTransformer } from '@sellpy/commons'

export const CACHE_ENTITIES = 'CACHE_ENTITIES'
export const cacheEntities = (data) => ({
  type: CACHE_ENTITIES,
  entities: parseTransformer.normalize(data)
})

export const DELETE_ENTITY = 'DELETE_ENTITY'
export const deleteEntity = ({ className, id }) => ({
  type: DELETE_ENTITY,
  className,
  id
})
