import React, { Suspense, useMemo } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import LoadingDots from '../market/src/client/components/LoadingDots.jsx'
import ContainerOrderPayments from './ContainerOrderPayments.jsx'
import AppPaymentErrorElement from './components/AppPaymentErrorElement.jsx'

const ContainerOrder = () => {
  const { userPromise } = useLoaderData()

  const paymentData = useMemo(() => (window.paymentData ? JSON.parse(window.paymentData) : {}), [])

  if (paymentData.version === 1) {
    return (
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingDots size='large' />
          </div>
        }
      >
        <Await resolve={userPromise} errorElement={<AppPaymentErrorElement />}>
          <ContainerOrderPayments paymentData={paymentData} />
        </Await>
      </Suspense>
    )
  }
}

export default ContainerOrder
