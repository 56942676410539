import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { H2, H4 } from '@sellpy/design-system-react-web'

/**
 * @deprecated this componenet isn't part of the design system and missing storybook
 */
export const SectionHeading = ({ id, step, children, style, subHeading }) => {
  const { t } = useTranslation('common')
  return (
    <div id={id} style={{ textAlign: 'center', margin: '16px 0', ...style }}>
      <H2 noMargin design='h4'>
        {step && t('ui.step', { step })}
        <strong>{children}</strong>
      </H2>
      {subHeading && (
        <H4 design='body5' style={{ marginTop: '8px' }}>
          {subHeading}
        </H4>
      )}
    </div>
  )
}

SectionHeading.propTypes = {
  style: PropTypes.object
}
