import { useTranslation } from 'react-i18next'
import { localizedMetadata } from '../../../../common/region/config'

export const useImageAlt = () => {
  const { t } = useTranslation('item')

  const getAlt = ({ item, suffix, photo, index }) => {
    const { brand, model, type, size, color, material, defects } =
      item[localizedMetadata] || item.metadata || {}

    const modelColorSizeData = [model, color?.join(', '), size].filter(Boolean)[0]
    const modelColorSize = modelColorSizeData ? `(${modelColorSizeData})` : ''

    const from = brand && t('metadata.description.from')

    const altString = [type, modelColorSize, from, brand, material?.join(', '), suffix]
      .filter(Boolean)
      .join(' ')

    if (photo?.type === 'defect' && defects) {
      const defect = defects?.find(({ id }) => id === photo.defectId)
      if (!defect || !(defect.type && defect.location)) {
        return [altString, t('defectDefault')].join(' - ')
      } else {
        const { type, location } = defect
        return [altString, type, location].filter(Boolean).join(' - ')
      }
    } else if (photo && !['robot', 'handheld'].includes(photo.type)) {
      return [altString, t(`${photo.type}AltSuffix`)].filter(Boolean).join(' - ')
    } else if (typeof index === 'number' && index > 0) {
      return [altString, t('angleAltSuffix', { index: index + 1 })].join(' - ')
    }

    return altString
  }

  return getAlt
}
