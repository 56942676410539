import theme from './theme.json'

export const mediaQueries = {
  smallPhone: `@media (max-width: ${theme.viewport.smallPhone}px)`,
  phone: `@media (max-width: ${theme.viewport.phone}px)`,
  tablet: `@media (max-width: ${theme.viewport.tablet}px)`,
  smallerThanTabletStyle: `@media (max-width: ${theme.viewport.tablet - 1}px)`,
  desktop: `@media (max-width: ${theme.viewport.desktop}px)`,
  largerThanDesktop: `@media (min-width: ${theme.viewport.desktop + 1}px)`,
  onlyTablet: `@media (max-width: ${theme.viewport.tablet}px) and (min-width: ${theme.viewport
    .phone + 1}px)`,
  largerThanPhone: `@media (min-width: ${theme.viewport.phone + 1}px)`,
  largerThanTablet: `@media (min-width: ${theme.viewport.tablet + 1}px)`,
  smallerThanLargeDesktop: `@media (max-width: ${theme.viewport.largeDesktop + 1}px)`,
  largeDesktop: `@media (min-width: ${theme.viewport.largeDesktop + 1}px)`
}

/**
 * Max width for components on the new landing page.
 * These values may be moved to more formal design tokens in the future
 */
export const landingPageMaxWidth = '1632px'
export const largerThanLandingPageMaxWidth = `@media (min-width: ${1632 + 1}px)`

/**
 * Max width for header and footer of the new landing page.
 * These values may be moved to more formal design tokens in the future
 */
export const layoutMaxWidth = '1920px'
