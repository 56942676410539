import { useContext } from 'react'
import { Context } from '../app/breakpointProvider'

const defaultValue = {}

const useResponsive = () => {
  const { queryMatch } = useContext(Context)
  if (queryMatch === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider')
  }
  return queryMatch
}

export default useResponsive
