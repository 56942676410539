import debounce from 'lodash/debounce'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { money } from '@sellpy/commons'
import { validateAndFetchPromoCode } from '../../common/cart/actions'
import { getValidatedPhoneNumber } from '../../common/user/actions'
import { formValidation } from '../../common/error'
import { validShippingCountries } from '../../common/region/config'
import { zeroMoney } from '../payOuts/utils'
import { analyticsCheckoutAddPromoCode } from '../../common/analytics/actions'
import { CHECKOUT_TYPES } from './analytics'

export const paymentMethodsFromPayment = (payment) => {
  const paymentMethods = []
  if (payment.get('adyenPaymentMethod')) {
    if (payment.get('adyenPaymentMethod').type === 'scheme') {
      if (payment.get('adyenPaymentMethod').storedPaymentMethodId) paymentMethods.push('saved_card')
      else paymentMethods.push('card')
    } else paymentMethods.push(payment.get('adyenPaymentMethod').type)
  }
  if (payment.get('creditAmount')) paymentMethods.push('credit')
  return paymentMethods
}

export const getAmount = (payment) => {
  if (!payment.get('adyenAmount') && payment.get('creditAmount')) return payment.get('creditAmount')
  return !payment.get('adyenAmount') && !payment.get('creditAmount')
    ? zeroMoney()
    : payment.get('creditAmount')
    ? money.add(money.toBaseUnit(payment.get('adyenAmount')), payment.get('creditAmount'))
    : money.toBaseUnit(payment.get('adyenAmount'))
}

export const ensureValueObject = (amount) => {
  if (typeof amount !== 'object') return { amount, currency: 'SEK' }
  return amount
}

export const paymentMethods = {
  APPLE_PAY: 'applePay',
  CREDIT: 'credit',
  GIRO_PAY: 'giroPay',
  NEW_CARD: 'newCard',
  SAVED_CARD: 'savedCard'
}

const _debounceValidatePromoCode = debounce(
  ({
    resolve,
    reject,
    dispatch,
    promoCode,
    itemsInCart,
    nrOfBags,
    t,
    checkoutType,
    analyticsItems,
    phone
  }) => {
    if (!promoCode) return resolve()
    return dispatch(
      validateAndFetchPromoCode({
        promoCode,
        itemIds: itemsInCart && itemsInCart.map((item) => item.get('objectId')).toArray(),
        nrOfBags,
        phone
      })
    )
      .then((value) => {
        analyticsCheckoutAddPromoCode({ promoCode, checkoutType, items: analyticsItems })
        resolve(value)
      })
      .catch((error) =>
        reject(formValidation(error, t('cart:syncErrors.promoCode', { returnObjects: true })))
      )
  },
  1000
)

let phoneValidationCache = {}

export const fetchPhoneValidation = async ({ t, phoneNumber }) => {
  const result = phoneValidationCache[phoneNumber] || (await getValidatedPhoneNumber(phoneNumber))
  phoneValidationCache = { ...phoneValidationCache, [phoneNumber]: result }
  if (result.isValid) {
    return Promise.resolve()
  } else {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      address: { phone: t('common:genericErrors.phoneIntl') },
      _error: t('common:genericErrors.phoneIntl')
    })
  }
}

const promoCodeCache = {}

export const asyncValidateContainerOrderPromoCode = ({ promoCode, nrOfBags, phone }) => async ({
  dispatch,
  t
}) => {
  const cacheString = `${promoCode}${nrOfBags}${phone}`
  const inCache = promoCodeCache[cacheString]
  if (inCache) {
    if (inCache.valid) {
      return
    } else {
      throw inCache.error
    }
  }

  try {
    await new Promise((resolve, reject) =>
      _debounceValidatePromoCode({
        resolve,
        reject,
        dispatch,
        promoCode,
        nrOfBags,
        t,
        checkoutType: CHECKOUT_TYPES.CONTAINER,
        phone
      })
    )
    promoCodeCache[cacheString] = { valid: true }
  } catch (error) {
    promoCodeCache[cacheString] = { valid: false, error }
    throw error
  }
}

export const initialValuesForAddress = ({ address, country }) => {
  // If user has incorrect address for region, only fill in non-postal information and add default region
  // zipCode is replaced with undefined as it is appended in checkout through form but not in Cart content
  const defaultValues = {
    // Only default country for regions with few countries, to reduce user errors
    country: country || (validShippingCountries.length < 4 && validShippingCountries[0]),
    zipCode: undefined
  }
  if (!address) return defaultValues
  if (validShippingCountries.includes(address.country)) {
    return omit(address, ['geo'])
  } else {
    return Object.assign(pick(address, ['firstName', 'lastName', 'phone']), defaultValues)
  }
}

export const getErronousFields = (errors) =>
  Object.keys(errors).reduce((acc, curr) => {
    if (typeof errors[curr] === 'string') return [curr, ...acc, 'string']
    const erronousSubFields = Object.keys(errors[curr]).map((key) => `${curr}.${key}`)
    return [...acc, ...erronousSubFields]
  }, [])

export const paymentMethodIconSrc = ({ value, config }) => {
  switch (value) {
    case 'saved_card':
      return config.get('brand')
        ? `https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${config.get(
            'brand'
          )}.svg`
        : null
    case 'klarna':
    case 'klarna_paynow':
    case 'klarna_account':
      return 'https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg'
    case 'swish':
      return 'https://images.ctfassets.net/zrqoyh8r449h/7jQckYt3H2IMXaSmXvWcY1/693c480520fb384085e2512e93ceb965/Swish_Logo_Secondary_Light-BG_SVG.svg'
    case 'paypal':
      return 'https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg'
    case 'ideal':
      return 'https://www.ideal.nl/img/logo/ideal-logo.svg'
    default:
      return null
  }
}
