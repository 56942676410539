import { combineReducers } from 'redux'
import { DAC7_REPORT_COMPLETE } from './actions'

const report = (state = null, action) => {
  switch (action.type) {
    case DAC7_REPORT_COMPLETE:
      return {
        ...state,
        hasDac7BeenReported: action.hasDac7BeenReported
      }
    default:
      return state
  }
}

export default combineReducers({ report })
