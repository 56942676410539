import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import preventScroll from 'prevent-scroll'
import { analyticsMenuNavigation } from '../../common/analytics/actions'
import { useClickOutside, useEscapeKey, useHeaderHeight } from '../hooks'
import Burger from './components/Burger.jsx'
import Content from './components/Content.jsx'

const Overlay = styled.div`
  visibility: hidden;
  top: ${({ currentHeight }) => `calc(${currentHeight}px + 1px)`}; /* 1px border height */
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: black;
  z-index: 4500;
  transition: opacity 0.15s ease-in-out, visibility 0.15s;
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 0.5;
      visibility: visible;
    `}
`

const menuContentId = 'hamburger-menu-content'

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const currentHeight = useHeaderHeight()
  const node = useRef()
  const closeMenu = (label) => {
    preventScroll.off()
    analyticsMenuNavigation({ label, action: 'navigate' })
    setIsOpen(false)
  }
  useClickOutside(node, () => {
    if (isOpen) {
      closeMenu('clickOutside')
    }
  })
  useEscapeKey(node, () => {
    closeMenu('escape click')
  })
  return (
    <>
      <nav ref={node} style={{ display: 'flex', alignItems: 'center' }}>
        <Burger isOpen={isOpen} setIsOpen={setIsOpen} menuContentId={menuContentId} />
        <Content isOpen={isOpen} closeMenu={closeMenu} id={menuContentId} />
      </nav>
      <Overlay isOpen={isOpen} currentHeight={currentHeight} />
    </>
  )
}

export default Menu
