import { createSelector } from 'reselect'
import { getEntity } from '../entities/selectors/base'
import { currentUserSelector } from '../user/selectors'

export const notificationSelector = createSelector(
  [getEntity('Notification'), currentUserSelector],
  (notifications, userId) => {
    return notifications
      .filter((notification) => notification.get('active'))
      .filter((notification) => notification.get('user') === userId)
  }
)
