import { useEffect, useState } from 'react'
import useResponsive from './useResponsive'

const getDOMElementWhenExistsAsync = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    // eslint-disable-next-line no-undef
    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect()
        resolve(document.querySelector(selector))
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
  })
}

const useHeaderHeight = (ignoreSearchBarOnMobile = false) => {
  const { isTablet } = useResponsive()

  const [headerHeight, setHeaderHeight] = useState(0)
  const [searchBarHeight, setSearchBarHeight] = useState(0)

  useEffect(() => {
    let headerResizeObserver
    let searchBarResizeObserver

    const watchHeaderHeight = async () => {
      // eslint-disable-next-line no-undef
      headerResizeObserver = new ResizeObserver(([entry]) => {
        setHeaderHeight(entry?.borderBoxSize?.[0]?.blockSize || 0)
      })
      headerResizeObserver.observe(await getDOMElementWhenExistsAsync('#topHeader'))

      // eslint-disable-next-line no-undef
      searchBarResizeObserver = new ResizeObserver(([entry]) => {
        setSearchBarHeight(entry?.borderBoxSize?.[0]?.blockSize || 0)
      })
      searchBarResizeObserver.observe(await getDOMElementWhenExistsAsync('#headerSearchBox'))
    }

    watchHeaderHeight()

    return () => {
      // eslint-disable-next-line no-unused-expressions
      headerResizeObserver?.disconnect()
      // eslint-disable-next-line no-unused-expressions
      searchBarResizeObserver?.disconnect()
    }
  }, [setHeaderHeight, setSearchBarHeight])

  return headerHeight - (ignoreSearchBarOnMobile && isTablet ? searchBarHeight : 0)
}

export default useHeaderHeight
