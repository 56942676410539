import { useLocation } from 'react-router-dom'
import { segmentToBaseCategory } from '../../common/region/config'
import { queries } from './content/staticContent/queryMapping'

export const SEGMENT_CHOICES_MEN = 'men'
export const SEGMENT_CHOICES_WOMEN = 'women'
export const SEGMENT_CHOICES_THINGS = 'things'
export const SEGMENT_CHOICES_CHILDREN = 'children'

export const SEGMENT_CHOICES_ALL = [
  SEGMENT_CHOICES_MEN,
  SEGMENT_CHOICES_WOMEN,
  SEGMENT_CHOICES_THINGS,
  SEGMENT_CHOICES_CHILDREN
]

const brandMapper = (query) => [
  queries[query]?.refinementList['metadata.brand'].map((brand) => `metadata.brand:${brand}`)
]
export const menWomenChildrenThingsQueryFilter = ({ path }) => {
  if (path === '/hm') return brandMapper('H&M')
  if (path === '/ahlens') return brandMapper('Åhléns')
  if (SEGMENT_CHOICES_ALL.includes(path.replaceAll('/', ''))) {
    return `segment:${path.replaceAll('/', '')}`
  }
  return ''
}

export const useMenWomenChildrenUrlFilter = (customPath) => {
  const { pathname } = useLocation()
  const path = customPath || pathname
  if (path === '/men')
    return {
      hierarchicalMenu: { 'categories.lvl0': [segmentToBaseCategory.men] }
    }
  if (path === '/women')
    return {
      hierarchicalMenu: { 'categories.lvl0': [segmentToBaseCategory.women] }
    }
  if (path === '/children')
    return {
      hierarchicalMenu: { 'categories.lvl0': [segmentToBaseCategory.children] }
    }
  if (path === '/things')
    return {
      hierarchicalMenu: { 'categories.lvl0': [segmentToBaseCategory.things] }
    }
  if (path === '/hm') return queries['H&M']
  return ''
}
