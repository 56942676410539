import styled from 'styled-components'
import { FixedImage, Grid } from '@sellpy/design-system-react-web'
import { layoutMaxWidth, mediaQueries } from '../../../../uiComponents/mediaQueries'

export const AppBadges = styled.div`
  grid-column: 1 / span 3;
  ${mediaQueries.desktop} {
    grid-column: ${({ showTrustScore }) => (showTrustScore ? 'span 6' : 'span 4')};
  }
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  align-items: center;
  a {
    display: flex;
  }
`

export const DeliveryPaymentWrapper = styled.div`
  grid-column: ${({ showTrustScore }) => (showTrustScore ? 'span 3' : 'span 4')};
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  ${mediaQueries.desktop} {
    grid-column: ${({ showTrustScore }) => (showTrustScore ? 'span 6' : 'span 4')};
  }
`

export const DeliveryPaymentBadges = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  align-self: flex-start;
`

export const BadgeContainer = styled.div`
  width: 48px;
  padding: 0 8px;
`

export const Badge = styled(FixedImage)`
  max-height: 32px;
`

export const ExternalBadge = styled.img`
  width: 48px;
  padding: 4px 4px 0px 0px;
`

export const MidWrapper = styled.div`
  padding: 3rem;
  border-top: ${({ theme }) => `1px solid ${theme.color.grey.shade7}`};
  ${mediaQueries.tablet} {
    padding: 2rem 0;
    border-top: none;
  }
  ${mediaQueries.phone} {
    padding: 1rem 0;
  }
`

export const MidContainer = styled.div`
  margin-inline: auto;
  max-width: ${layoutMaxWidth};
  align-content: center;
  ${mediaQueries.tablet} {
    border-top: none;
    display: flex;
    flex-wrap: wrap;
  }
`

export const BadgeSection = styled(Grid)`
  grid-column: span 12;
  grid-gap: 2rem;
  grid-template-columns: repeat(12, [col-start] 1fr);
  align-content: center;
  ${mediaQueries.tablet} {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
`

export const EcomBadgeSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  grid-column: span 3;
  align-items: center;
  ${mediaQueries.desktop} {
    grid-column: ${({ showTrustScore }) => (showTrustScore ? 'span 6' : 'span 4')};
  }
  ${mediaQueries.tablet} {
    padding: 1rem 0 3rem 0;
    display: ${({ showTrustScore }) => (!showTrustScore ? 'none' : 'flex')};
  }
  ${mediaQueries.phone} {
    padding: 1rem 0 2rem 0;
    display: ${({ showTrustScore }) => (!showTrustScore ? 'none' : 'flex')};
  }
`
