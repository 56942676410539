import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { user as userTools, region as regionFunctions } from '@sellpy/commons'
import styled from 'styled-components'
import { A, Grid, ICONS, Icon, RouterLink } from '@sellpy/design-system-react-web'
import { isFeatureEnabled, FEATURE_SELL } from '../../../featureToggle'
import { logout } from '../../../../common/user/actions'
import { useResponsive } from '../../../hooks'
import { showCancellationPolicy, company, contactAddress } from '../../../../common/region/config'
import constants from '../../../region/constants.json'
import { useCountry } from '../../../../common/hooks/useCountry'
import { layoutMaxWidth, mediaQueries } from '../../../uiComponents/mediaQueries'

const ImpersonationWarning = styled.h3`
  background-color: ${({ theme }) => theme.color.red.shade6};
  color: white;
`

const PolicyLink = styled.div`
  margin: 0 4px;
`
const BottomLinks = styled.div`
  color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? 'white' : theme.color.grey.shade9};
  display: flex;
  flex-wrap: wrap;
  ${mediaQueries.tablet} {
    justify-content: flex-start;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: ${({ customerImpersonation }) => (customerImpersonation ? 'column' : 'row')};
  justify-content: center;
  padding: 3rem;
  background-color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? theme.color.red.shade6 : theme.color.grey.shade2};
  ${mediaQueries.tablet} {
    padding: 2rem 0;
    min-height: 8rem;
  }
  ${mediaQueries.phone} {
    padding: 2rem 0;
  }
`
const BottomContainer = styled(Grid)`
  flex: 1;
  align-items: center;
  margin-inline: auto;
  max-width: ${layoutMaxWidth};
  ${mediaQueries.tablet} {
    display: flex;
    text-align: left;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
  }
`

const BottomInfo = () => {
  const customerImpersonation = useSelector((state) => state.entities.CustomerImpersonation.size)
  const user = useSelector((state) => state.user.parseUser)
  const dispatch = useDispatch()
  const { isTablet } = useResponsive()
  const { t } = useTranslation(['general', 'footer'])
  return (
    <BottomWrapper customerImpersonation={customerImpersonation}>
      {customerImpersonation ? (
        <ImpersonationWarning>
          {t('general:customerImpersonation.banner').toUpperCase()}
        </ImpersonationWarning>
      ) : null}
      <BottomContainer noPadding={!isTablet}>
        <BottomLinks
          customerImpersonation={customerImpersonation}
          style={{ gridColumn: 'span 3', alignItems: 'center' }}
        >
          <Location customerImpersonation={customerImpersonation} />
          <PolicyLink>
            {userTools.userIsFull(user) || customerImpersonation ? (
              <BottomInfoTextLink
                noMargin
                customerImpersonation={customerImpersonation}
                onClick={() => dispatch(logout('/'))}
                to={'/'}
              >
                {t('footer:logout')}
              </BottomInfoTextLink>
            ) : (
              <BottomInfoTextLink
                customerImpersonation={customerImpersonation}
                noMargin
                to={'/login'}
              >
                {t('footer:login')}
              </BottomInfoTextLink>
            )}
          </PolicyLink>
          <span> {userTools.userIsFull(user) ? `| ${user.get('email')}` : ''}</span>
        </BottomLinks>
        <BottomLinks style={{ gridColumn: 'span 4' }} customerImpersonation={customerImpersonation}>
          <PolicyLink>© {company} |</PolicyLink>
          {contactAddress}
        </BottomLinks>
        <BottomLinks style={{ gridColumn: 'span 5' }} customerImpersonation={customerImpersonation}>
          {isFeatureEnabled(FEATURE_SELL) && (
            <PolicyLink>
              <InfoText
                link={regionFunctions.sellingPolicyLink(process.env.REGION)}
                label={t('footer:sellTerms')}
              />
              <span> | </span>
            </PolicyLink>
          )}
          <PolicyLink>
            <InfoText
              link={regionFunctions.purchasePolicyLink(process.env.REGION)}
              label={t('footer:purchaseTerms')}
            />
            <span> | </span>
          </PolicyLink>
          <PolicyLink>
            <InfoText
              link={regionFunctions.dataPolicyLink(process.env.REGION)}
              label={t('footer:dataPolicy')}
            />
          </PolicyLink>
          <span> | </span>
          <PolicyLink>
            <InfoText
              link={regionFunctions.cookiePolicyLink(process.env.REGION)}
              label={t('footer:cookiePolicy')}
            />
          </PolicyLink>
          {showCancellationPolicy && (
            <>
              <PolicyLink>
                <InfoText
                  link={regionFunctions.cancellationPolicyLink(process.env.REGION)}
                  label={t('footer:cancellationPolicy')}
                />
              </PolicyLink>
              <PolicyLink>
                <span> | </span>
                <InfoText
                  link={regionFunctions.imprintLink(process.env.REGION)}
                  label={t('footer:imprint')}
                />
              </PolicyLink>
            </>
          )}
        </BottomLinks>
      </BottomContainer>
    </BottomWrapper>
  )
}

const BottomInfoTextA = styled(({ customerImpersonation, ...props }) => <A {...props} />)`
  color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? 'white' : theme.color.grey.shade9};
`

const BottomInfoTextLink = styled(({ customerImpersonation, ...props }) => (
  <RouterLink {...props} />
))`
  color: ${({ customerImpersonation, theme }) =>
    customerImpersonation ? 'white' : theme.color.grey.shade9};
`

const InfoText = ({ link, label }) => {
  const customerImpersonation = useSelector((state) => state.entities.CustomerImpersonation.size)
  return (
    <BottomInfoTextA
      noMargin
      customerImpersonation={customerImpersonation}
      href={link}
      target='__blank'
      rel='noopener noreferrer'
    >
      {label}
    </BottomInfoTextA>
  )
}

const Location = ({ customerImpersonation }) => {
  const country = useCountry()
  const location = constants.country[country] || constants.country[process.env.REGION]
  if (!location) return null
  return (
    <>
      <div style={{ marginRight: '0.5rem', display: 'flex', justifyContent: 'align-center' }}>
        <Icon name={ICONS.PIN} style={{ fontSize: '2rem' }} />
      </div>
      <span>{location} |</span>
    </>
  )
}

export default BottomInfo
