import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'

const _errorKeyToMessage = (translate) => (type) => (value) => {
  if (typeof translate === 'object') {
    // msg
    return (
      translate.sellpyErrorCode[type][value] || translate.sellpyErrorCode[type].default || value
    )
  } else if (typeof translate === 'function') {
    // i18next, the fallback namespace (common) is used for these errors,
    // that's why we don't have to specify ns with 'common:'
    const genericErrorKey = `genericErrors.${type}.${value}`
    const errorMsg = translate(genericErrorKey)
    // We found the key, return it
    if (errorMsg !== genericErrorKey) {
      return errorMsg
    }
  }
  return value
}

export const parseErrorToErrorObject = (parseError, translate) => {
  const errorKeyToMessage = _errorKeyToMessage(translate)(parseError.message.type)
  if (parseError.message.code === 'INVALID_STATE' || parseError.message.code === 'INVALID_PARAMS') {
    return mapValues(parseError.message.details, errorKeyToMessage)
  } else if (parseError.message.type === 'ValidationError') {
    if (typeof parseError.message.message === 'object') {
      return mapValues(parseError.message.message, errorKeyToMessage)
    } else {
      return { _error: errorKeyToMessage(parseError.message.message) }
    }
  } else if (parseError.message.type === 'CreditError' && parseError.message.explanation) {
    return mapValues(parseError.message.explanation, errorKeyToMessage)
  } else {
    const error = parseError.message.message || parseError.message
    const errorToDisplay = isEmpty(error) ? 'Something went wrong' : error
    return { _error: errorToDisplay }
  }
}

export const parseError = (error) => (error.code === 999 ? error.message : error)

export const formValidation = (error, errorStrings = {}, defaultErrorMessage) => {
  const { message, details } = parseError(error)
  if (details) return mapValues(details, (value) => errorStrings[value] || value)
  else
    return {
      _error: errorStrings[message] || defaultErrorMessage || 'An unexpected error occurred'
    }
}

export const hookFormServerError = ({ t, setError, errorMessagesKey, fallbackErrorMessage }) => (
  error
) => {
  if (error.code !== 999 || !error.message.details) {
    setError('_error', {
      type: '400',
      message: fallbackErrorMessage || t('common:backendError.unexpectedError')
    })
  } else {
    const errorMessages = t(errorMessagesKey || 'common:genericErrors', { returnObjects: true })
    const errors = Object.keys(error.message.details).reduce((errorsAgg, errorKey) => {
      if (errorMessages[error.message.details[errorKey]]) {
        errorsAgg[errorKey] = errorMessages[error.message.details[errorKey]]
      } else {
        console.error('Missing translation for errorKey: ', errorKey)
      }
      return errorsAgg
    }, {})
    if (Object.keys(errors).length === 0) {
      setError('_error', {
        type: '400',
        message: fallbackErrorMessage || t('common:backendError.unexpectedError')
      })
    } else {
      Object.keys(errors).forEach((errorKey) => {
        setError(errorKey, { type: '400', message: errors[errorKey] })
      })
    }
  }
}
