import { useState, useEffect, useRef, useCallback } from 'react'

const useLoading = (initialState = false) => {
  const mounted = useRef(true)
  const [loading, setLoading] = useState(initialState)
  useEffect(() => () => (mounted.current = false), [])
  const load = useCallback((promise) => {
    setLoading(true)
    promise
      .then((result) => {
        if (mounted.current) setLoading(false)
        return result
      })
      .catch((error) => {
        if (mounted.current) setLoading(false)
        return Promise.reject(error)
      })
  }, [])
  return { loading, load }
}

export default useLoading
