import React, { Suspense, useMemo } from 'react'
import { H1 } from '@sellpy/design-system-react-web'
import { Await, useLoaderData } from 'react-router-dom'
import LoadingDots from '../market/src/client/components/LoadingDots.jsx'
import CheckoutItemPaymentsV1 from './CheckoutItemPaymentsV1.jsx'
import AppPaymentErrorElement from './components/AppPaymentErrorElement.jsx'

const CheckoutItem = () => {
  const { userPromise } = useLoaderData()
  const paymentData = useMemo(() => (window.paymentData ? JSON.parse(window.paymentData) : {}), [])

  if (paymentData.version === 0) {
    return (
      <H1>
        Your version of the app is outdated and no longer supports payments. Please update to the
        latest version.
      </H1>
    )
  } else if (paymentData.version === 1) {
    return (
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingDots size='large' />
          </div>
        }
      >
        <Await resolve={userPromise} errorElement={<AppPaymentErrorElement />}>
          <CheckoutItemPaymentsV1 paymentData={paymentData} />
        </Await>
      </Suspense>
    )
  } else {
    return null
  }
}

export default CheckoutItem
