import React from 'react'

const SellpyLogo = ({ color, style }) => {
  return (
    <svg
      width={style && style.width ? style.width : 89}
      height={style && style.height ? style.height : 28}
      viewBox='0 0 89 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'flex', margin: 'auto', height: '100%', ...style }}
    >
      <path
        d='M21.4629 13.9546C21.4629 9.58811 25.145 6.45514 30.2773 6.45514C35.364 6.45514 38.8716 9.5654 38.8716 13.9319C38.8716 14.2951 38.8412 14.8022 38.7881 15.1881H24.8945C25.2893 17.5946 27.9313 19.1914 31.1884 19.1914C33.8836 19.1914 36.3282 18.1546 37.4822 16.7849L39.3651 18.3816C37.9074 20.3719 34.62 21.7946 31.0213 21.7946C25.4791 21.7946 21.4629 18.5178 21.4629 13.9546ZM35.5918 12.8951C35.3109 10.4054 33.094 9.03568 30.2014 9.03568C27.3923 9.03568 25.1754 10.6324 24.8641 12.8951H35.5918Z'
        fill={color}
      />
      <path d='M41.2631 0.575134H44.4062V21.2346H41.2631V0.575134Z' fill={color} />
      <path d='M46.8736 0.575134H50.0168V21.2346H46.8736V0.575134Z' fill={color} />
      <path
        d='M52.4918 7.01513H55.1566L55.5514 9.70162H55.5818C56.7889 7.71135 59.3171 6.4854 62.2628 6.4854C67.2357 6.4854 70.6597 9.59568 70.6597 14.1286C70.6597 18.6616 67.2584 21.7719 62.2932 21.7719C59.3702 21.7719 56.9028 20.5686 55.6653 18.5784H55.6349V27.1146H52.4918V7.01513ZM67.5166 14.1211C67.5166 11.1849 65.0719 9.14162 61.5036 9.14162C57.9657 9.14162 55.521 11.2151 55.521 14.1211C55.521 17.0346 57.9657 19.1006 61.5036 19.1006C65.0719 19.1081 67.5166 17.0346 67.5166 14.1211Z'
        fill={color}
      />
      <path
        d='M70.6901 24.4205L72.4059 22.2638C73.8408 23.8 76.3917 24.9805 79.3678 24.9805C83.217 24.9805 85.6845 23.1038 85.6845 20.107V19.0703H85.6541C84.5608 20.9773 82.1997 22.1503 79.6412 22.1503C75.3137 22.1503 72.5122 19.3503 72.5122 15.2108V7.01513H75.6857V14.9384C75.6857 17.7081 77.485 19.5849 80.3472 19.5849C83.4676 19.5849 85.6845 17.5113 85.6845 14.4086V7.0227H88.8276V20.1751C88.8276 24.7686 85.1758 27.6746 79.4741 27.6746C75.5186 27.6746 72.0946 26.1838 70.6901 24.4205Z'
        fill={color}
      />
      <path
        d='M10.9706 21.9308C7.54655 21.9308 4.38823 20.9773 2.22449 18.9568C1.60953 18.3816 1.12363 17.7686 0.698473 17.0876C0.409973 16.6108 0.159434 16.0811 0 15.453L2.81667 14.1816C3.02925 15.1427 3.81123 16.293 4.66155 17.027C6.83289 18.9114 9.71789 19.5395 12.451 19.0627C15.5258 18.5254 16.7102 17.1103 16.7102 15.4076C16.7102 13.8411 15.4575 12.8422 10.2417 12.6076C7.06825 12.4638 3.5607 12.0778 1.85247 10.0573C0.986973 9.02811 0.599776 7.74162 0.827539 6.08433C1.20714 3.36757 3.68976 1.55135 6.98473 0.961082C9.98361 0.416217 12.8003 0.787028 14.9792 1.92973C16.7785 2.87568 18.092 4.06378 18.7905 5.73622L16.2015 7.14378C15.6397 5.78162 14.4098 4.79027 13.066 4.14703C11.4565 3.3827 9.52809 3.3373 7.81227 3.63243C6.81771 3.80649 4.4186 4.46487 4.30472 6.48541C4.17566 8.74811 5.95221 9.2627 10.386 9.60324C14.1441 9.89081 16.9076 9.87568 18.8816 11.8432C19.8154 12.7741 20.2026 14.1438 20.1039 15.733C19.9217 18.6995 17.2568 21.1816 13.1116 21.7719C12.4207 21.8703 11.6994 21.9308 10.9706 21.9308Z'
        fill={color}
      />
    </svg>
  )
}

export default SellpyLogo
