import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { P } from '@sellpy/design-system-react-web'
import BalanceInfo from '../components/BalanceInfo.jsx'
import CreditConvert from './CreditConvert.jsx'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
  overflow: hidden;
`

const CreditPayout = ({ balance }) => {
  const { t } = useTranslation('balance')
  const theme = useTheme()
  return (
    <>
      <Container>
        <BalanceInfo balance={balance} />
        <CreditConvert balance={balance} />
      </Container>
      <P design='body3' noMargin style={{ color: theme.color.grey.shade3 }}>
        {t('credit.valid.information')}
      </P>
    </>
  )
}

export default CreditPayout
