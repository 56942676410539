import React from 'react'
import styled from 'styled-components'
import { textStyle } from '../../text/styles'

const Input = styled.input`
  outline: none;
  width: 100%;
  border-style: none;
  padding: 8px 0px;
  ${textStyle}
`

const TextInput = (props, ref) => <Input noMargin design='body1' ref={ref} {...props} />

export default React.forwardRef(TextInput)
