import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

const cache = new InMemoryCache({
  dataIdFromObject: (responseObject) => {
    if (responseObject.objectId) {
      return `${responseObject.__typename}:${responseObject.objectId}`
    }
    if (responseObject._id) {
      return `${responseObject.__typename}:${responseObject._id}`
    }
  },
  typePolicies: {
    SimilarBrandsMap: {
      keyFields: (responseObject, { typename }) => `${typename}:${responseObject.brand}`
    },
    Query: {
      fields: {
        getSaleItemsByStatus: relayStylePagination(['saleId', 'statuses']),
        syncReservations: {
          merge: false
        },
        orders: relayStylePagination(['where']),
        getOrdersOverview: relayStylePagination(),
        payOuts: relayStylePagination(['where']),
        bags: relayStylePagination(['where', 'first'])
      }
    }
  }
})

export default cache
