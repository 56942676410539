import { queries } from './queryMapping'

const all = {
  'Nordic Brands': [
    'Acne Studios',
    'Filippa K',
    'Fjällräven',
    'Hope',
    'Samsøe & Samsøe',
    'Nudie Jeans'
  ],
  'MULTINATIONAL BRANDS': [
    'Levi Strauss & Co',
    'H&M',
    'Zara',
    'Ralph Lauren',
    'Uniqlo',
    'H&M Collabs'
  ],
  'Athlethic Brands': ['Adidas', 'Saucony', 'Peak Performance', 'Nike', 'Puma', 'Craft']
}

const women = {
  'Nordic Brands': [
    'Acne Studios',
    'Filippa K',
    'Rodebjer',
    'Ganni',
    'Samsøe & Samsøe',
    'By Malene Birger'
  ],
  'MULTINATIONAL BRANDS': [
    'Levi Strauss & Co',
    'H&M',
    'Tommy Hilfiger',
    'Ralph Lauren',
    'Uniqlo',
    'Zara'
  ],
  'Premium brands': [
    'Michael Kors',
    'Levi Strauss & Co',
    'Desigual',
    'Tommy Hilfiger',
    'Dr. Martens',
    'Ralph Lauren'
  ]
}

const men = {
  'Nordic Brands': [
    'Acne Studios',
    'Our Legacy',
    'Peak Performance',
    'Eton',
    'Samsøe & Samsøe',
    'Nudie Jeans'
  ],
  'MULTINATIONAL BRANDS': [
    'Levi Strauss & Co',
    'H&M',
    'Zara',
    'Ralph Lauren',
    'Tommy Hilfiger',
    'Nike'
  ],
  Tailored: ['Rose & Born', 'Eton', 'Barbour', 'Tiger of Sweden', 'Boomerang', 'J.Lindeberg']
}

const children = {
  'Sport & Outdoor brands': [
    'Adidas',
    'Nike',
    'Didriksons 1913',
    '8848 Altitude',
    'Everest',
    'Converse'
  ],
  'Nordic Brands': [
    'Polarn O. Pyret',
    'Mini Rodini',
    'POMPdeLux	',
    'Villervalla',
    'Livly',
    'Geggamoja'
  ],
  'Affordable brands': ['H&M', 'Zara Kids', 'Åhléns', 'Uniqlo', 'Cubus', 'Lindex Kids']
}

const things = {
  Electronics: ['Sony', 'Philips', 'Nikon', 'Apple', 'Canon'],
  'Home decor': ['Lexington', 'H&M Home', 'Zara Home', 'Lagerhaus', 'Skultuna', 'Ikea'],
  'Glass & tableware': [
    'Rörstrand',
    'Gustavsberg',
    'Kosta Boda',
    'Iittala',
    'Höganäs Keramik',
    'Orrefors'
  ]
}

const hm = {
  'H&M BRANDS': [
    'H&M Modern Classic',
    'H&M Premium Quality',
    'H&M Sport',
    'H&M Studio',
    'H&M Trend',
    'H&M Conscious Exclusive'
  ],
  '': ['H&M+', 'L.O.G.G by H&M', '&Denim by H&M', 'H&M Home', 'H&M Herr', 'H&M Collabs'],
  'H&MS VÄNNER': ['Monki', 'Arket', 'COS', 'Weekday', '& Other Stories']
}

const generateData = (list) => {
  return Object.keys(list).map((type) => {
    const brandList = list[type].map((brand) => {
      return {
        label: brand,
        searchState: queries[brand]
      }
    })
    return { headline: type, link: '/search', items: brandList }
  })
}

export const staticContentBrandSlab = (pathname) => {
  const allMap = generateData(all)
  const womenMap = generateData(women)
  const menMap = generateData(men)
  const childrenMap = generateData(children)
  const thingsMap = generateData(things)
  return (
    {
      '/': allMap,
      '/women': womenMap,
      '/men': menMap,
      '/children': childrenMap,
      '/things': thingsMap
    }[pathname] ?? allMap
  )
}

export const staticContentHmBrandSlab = () => {
  const hmMap = generateData(hm)
  return {
    '/hm': hmMap
  }
}
