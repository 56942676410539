import styled from 'styled-components'
import { A, Grid, P, RouterLink } from '@sellpy/design-system-react-web'
import { ExpandableList } from '../../../../uiComponents'
import { ExpandedContent } from '../../../../uiComponents/lists/ExpandableList.jsx'
import { layoutMaxWidth, mediaQueries } from '../../../../uiComponents/mediaQueries'

export const FooterColumn = styled.div`
  :not(:last-child) div {
    border-bottom: none;
  }
  grid-column: span 2;
  text-align: left;
  ${mediaQueries.desktop} {
    grid-column: span 3;
  }
`
export const FooterColumnContent = styled.div`
  ${mediaQueries.tablet} {
    display: none;
  }
`
export const GridContainer = styled(Grid)`
  margin-inline: auto;
  max-width: ${layoutMaxWidth};
  padding: 3rem 3rem 2rem 3rem;
  ${mediaQueries.tablet} {
    padding: 2rem 0 0 0;
    display: block;
  }
`
export const SocialMediaWrapper = styled.div`
  grid-column: span 3;
  text-align: left;
  ${mediaQueries.desktop} {
    grid-column: span 12;
    padding-bottom: 3rem;
  }
  ${mediaQueries.tablet} {
    grid-column: span 2;
    padding: 0 2rem 1rem 1rem;
  }
  ${mediaQueries.phone} {
    padding: 0 1rem 1rem 1rem;
  }
`
export const SocialMediaLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 12rem;
`
export const SocialMediaIcons = styled(A)`
  cursor: pointer;
  display: flex;
`
export const StyledRouterLink = styled(RouterLink)`
  color: ${({ theme }) => theme.color.grey.shade2};
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5rem;
`

export const StyledP = styled(P)`
  color: ${({ theme }) => theme.color.grey.shade2};
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5rem;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledA = styled(A)`
  color: ${({ theme }) => theme.color.grey.shade2};
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5rem;
`

export const StyledExpandableList = styled(ExpandableList)`
  ${mediaQueries.tablet} {
    min-height: 3.5rem;
    padding: 1rem 2rem 0 2rem;
  }
  ${mediaQueries.phone} {
    padding: 1rem 1rem 0 1rem;
  }
  ${ExpandedContent} {
    padding-bottom: 0.5rem;
  }
`
