import React from 'react'
import styled from 'styled-components'
import { ICONS, Icon, Button } from '@sellpy/design-system-react-web'
import { useTranslation } from 'react-i18next'
import { A, H7, P, SIZES } from '../../uiComponents/index.js'
import { ColoredInfoBox, InfoText } from '../styles.js'

export const StyledA = styled(A)`
  text-decoration: underline;
`
export const ReadMoreA = styled(A)`
  align-self: end;
`

const PrimaryBlueInfoText = styled(InfoText)`
  color: ${({ theme }) => theme.color.blue.default};
`

const DAC7Info = () => {
  const { t } = useTranslation(['balance'])

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <ColoredInfoBox>
          <Icon
            name={ICONS.INFORMATION_CIRCLE}
            style={{ fontSize: '1.5rem', marginRight: '8px' }}
          />
          <PrimaryBlueInfoText design='body3' noMargin>
            {t('balance:balanceOverview.dac7Modal.infoText')}
          </PrimaryBlueInfoText>
        </ColoredInfoBox>
        <A
          href={t('balance:balanceOverview.dac7Modal.readmore.link')}
          target='_blank'
          rel='noopener noreferrer'
          style={{ alignSelf: 'end' }}
        >
          <Button
            rightIcon={<Icon name={ICONS.EXTERNAL_LINK} />}
            label={t('balance:balanceOverview.dac7Modal.readmore.button')}
            variant='text'
            size={SIZES.SMALL}
            color='blue'
          />
        </A>
      </div>
      <H7>{t('balance:balanceOverview.dac7Modal.why.header')}</H7>
      <P design='body3'>{t('balance:balanceOverview.dac7Modal.why.paragraph')}</P>
      <ul style={{ marginBottom: '24px' }}>
        <li>
          <P design='body3'>{t('balance:balanceOverview.dac7Modal.why.listItem1')}</P>
        </li>
        <li>
          <P design='body3'>{t('balance:balanceOverview.dac7Modal.why.listItem2')}</P>
        </li>
      </ul>
      <H7>{t('balance:balanceOverview.dac7Modal.consequences.header')}</H7>
      <P design='body3'>{t('balance:balanceOverview.dac7Modal.consequences.paragraph')}</P>
      <ul>
        <li>
          <P design='body3'>{t('balance:balanceOverview.dac7Modal.consequences.listItem1')}</P>
        </li>
        <li>
          <P design='body3'>{t('balance:balanceOverview.dac7Modal.consequences.listItem2')}</P>
        </li>
      </ul>
      <H7>{t('balance:balanceOverview.dac7Modal.taxes.header')}</H7>
      <P design='body3'>{t('balance:balanceOverview.dac7Modal.taxes.paragraph')}</P>
    </>
  )
}

export default DAC7Info
