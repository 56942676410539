import cache from '../../apollo/cache'

export const fromArrayResult = (array) => (array ? array.map((element) => element.value) : [])

/**
 * @description Converts JS array to graphql array argument to be used in gql templates.
 * @param {Array<unknown>} array
 * @returns
 */
export const asArrayArgument = (array) => {
  if (!array) return '[]'
  const type = typeof array[0]

  if (type === 'object' && !array.every((value) => typeof value === 'object'))
    throw new Error('Array must contain values of the same type')
  if (type === 'number' && !array.every((value) => typeof value === 'number'))
    throw new Error('Array must contain values of the same type')
  if (type === 'string' && !array.every((value) => typeof value === 'string'))
    throw new Error('Array must contain values of the same type')

  if (type === 'object') return `[${array.map((value) => JSON.stringify(value)).join(', ')}]`
  if (type === 'number') return `[${array.join(', ')}]`
  if (type === 'string') return `[${array.map((value) => `"${value}"`).join(', ')}]`

  throw new Error(`Unsupported array item type: ${type}`)
}

export const evictSaleItemsByStatusCache = ({ saleId, statuses }) => {
  statuses.forEach((statusMap) => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getSaleItemsByStatus',
      args: {
        saleId,
        statuses: statusMap
      }
    })
    cache.gc()
  })
}
