import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOwnContainerOrders } from '../../common/containerOrder/actions'
import { ownContainerOrderSelector } from '../../common/entities/reducer'
import useLoading from './useLoading'

const useContainerOrders = () => {
  const dispatch = useDispatch()
  const { loading, load } = useLoading(true)
  useEffect(() => {
    load(dispatch(getOwnContainerOrders()))
  }, [dispatch, load])
  return {
    loading,
    containerOrders: useSelector(ownContainerOrderSelector)
  }
}

export default useContainerOrders
