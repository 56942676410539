import React from 'react'
import styled, { css } from 'styled-components'

// eslint-disable-next-line react/display-name
export const RangeSlider = React.forwardRef(({ value, onChange, min, max, onRelease }, ref) => {
  const progress = ((value - min) / (max - min)) * 100

  return (
    <StyledRangeInput
      ref={ref}
      type='range'
      min={min}
      max={max}
      value={value}
      onChange={(event) => onChange(event.target.valueAsNumber)}
      onMouseUp={onRelease}
      onTouchEnd={onRelease}
      progress={progress}
    />
  )
})

const THUMB_SIZE = 16

const trackStyle = css`
  padding: 16px 0;
  height: 4px;
`
const thumbStyle = css`
  appearance: none;
  height: ${THUMB_SIZE}px;
  width: ${THUMB_SIZE}px;
  margin-top: -${THUMB_SIZE / 2}px;
  background-color: ${({ theme }) => theme.color.white.default};
  border-radius: 50%;
  border: none;
`
const StyledRangeInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  height: 4px;
  border-radius: 999px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.color.white.default} ${({ progress }) => progress}%,
    ${({ theme }) => theme.color.grey.shade5} ${({ progress }) => progress}%
  );

  &::-webkit-slider-runnable-track {
    ${trackStyle}
  }
  &::-moz-range-track {
    ${trackStyle}
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyle}
  }
  &::-moz-range-thumb {
    ${thumbStyle}
  }
`
