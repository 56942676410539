import { useEffect, useRef } from 'react'

// A cancelable promise hook, taken from here:
// https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f

const makeCancelable = (promise) => {
  let isCanceled = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) => {
        return isCanceled ? reject(new Error('isCanceled')) : resolve(val)
      })
      .catch((error) => {
        return isCanceled ? reject(new Error('isCanceled')) : reject(error)
      })
  })
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true
    }
  }
}

export const useCancelablePromise = () => {
  // think of useRef as member variables inside a hook
  // you cannot define promises here as an array because
  // they will get initialized at every render refresh
  const promises = useRef()
  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  // once during unmount
  useEffect(() => {
    promises.current = promises.current || []
    return function cancel() {
      promises.current.forEach((p) => p.cancel())
      promises.current = []
    }
  }, [])

  // cancelablePromise remembers the promises that you
  // have called so far. It returns a wrapped cancelable
  // promise
  function cancelablePromise(p) {
    const cPromise = makeCancelable(p)
    promises.current.push(cPromise)
    return cPromise.promise
  }
  return { cancelablePromise }
}
