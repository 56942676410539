import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import { useQuery } from '../hooks'

const useConsumeQueryParameter = (key) => {
  const { [key]: value, ...query } = useQuery()
  const newSearch = qs.stringify(query)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const consume = useCallback(() => {
    navigate(
      {
        pathname: pathname,
        search: newSearch
      },
      { replace: true }
    )
  }, [navigate, newSearch, pathname])
  return { [key]: value, consume }
}

export default useConsumeQueryParameter
