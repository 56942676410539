import { user as userTools } from '@sellpy/commons'
import Parse from 'parse'
import { defer } from 'react-router-dom'
import { getABTestFraction } from '../../common/analytics/abTest.js'
import { locale } from '../../common/region/locale.js'
import { region } from '../../common/region/region.js'
import { getCreateQueryPreloader } from '../apollo/apolloClientSingleton.js'
import GET_TRACKED_SEARCHES from '../myShopping/pages/trackedSearches/trackedSearches.gql'
import GET_USER_PERSONALISATION_VALUES from './queries/getUserPersonalisationValues.gql'
import getLandingPage from './queries/landingPage.gql'
import getPreviewLandingPage from './queries/previewLanding.gql'

const roundedClientAbTestGroupValue = (Math.round(getABTestFraction() / 0.05) * 0.05).toFixed(2)

export const landingPageLoader = async ({ request }) => {
  const url = new URL(request.url)
  const sanityDraftId = url.searchParams.get('sanityDraftId')
  const segmentValue = url.pathname.split('/')[1] || 'root'
  const clientAbTestGroup = roundedClientAbTestGroupValue

  const abTestMinMax =
    clientAbTestGroup > 1 || clientAbTestGroup < 0 || !Number(clientAbTestGroup)
      ? 0
      : Number(clientAbTestGroup)

  const user = Parse.User.current()

  return defer({
    personalisationValues: getCreateQueryPreloader()(GET_USER_PERSONALISATION_VALUES, {
      variables: { locale }
    }),
    trackedSearches: getCreateQueryPreloader()(GET_TRACKED_SEARCHES, {
      skip: !userTools.userIsFull(user),
      variables: {
        region: region(),
        userId: user?.id
      }
    }),
    sanityData: getCreateQueryPreloader()(sanityDraftId ? getPreviewLandingPage : getLandingPage, {
      variables: {
        ...(sanityDraftId
          ? { id: sanityDraftId, isFullUser: userTools.userIsFull(user) }
          : {
              segment: segmentValue,
              region: region(),
              isFullUser: userTools.userIsFull(user),
              clientAbTestGroup: abTestMinMax
            })
      },
      context: { api: 'sanity', preview: Boolean(sanityDraftId) }
    })
  })
}
