import React, { useEffect, useRef, useState } from 'react'
import preventScroll from 'prevent-scroll'
import { useLocation } from 'react-router-dom'
import useResponsive from '../../../../hooks/useResponsive.js'
import { Cover, StyledSearch } from './Styles.jsx'
import { SuggestionBox } from './suggestionBox/SuggestionBox.jsx'
import { MobileSearchModal } from './components/MobileSearchModal.jsx'
import { useAutocomplete } from './hooks/useAutocomplete.js'
import { SearchInput } from './components/SearchInput.jsx'

const Searchbox = () => {
  const location = useLocation()
  const { isTablet } = useResponsive()
  const { autocomplete, autocompleteState } = useAutocomplete()

  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    if (isFocused && !isTablet) {
      preventScroll.on()

      return () => {
        preventScroll.off()
      }
    }
  }, [isFocused, isTablet])

  const inputRef = useRef(null)

  useEffect(() => {
    return inputRef?.current?.blur()
  }, [location])

  const content = (
    <StyledSearch {...autocomplete.getRootProps({})} id='headerSearchBox' isFocused={isFocused}>
      <SearchInput
        ref={inputRef}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        query={autocompleteState.query}
        autocomplete={autocomplete}
      />
      <SuggestionBox
        query={autocompleteState.query}
        isOpen={isFocused}
        autocomplete={autocomplete}
        collections={autocompleteState.collections}
      />
    </StyledSearch>
  )

  return (
    <>
      <Cover isOpen={isFocused} />
      {isFocused && isTablet ? <MobileSearchModal>{content}</MobileSearchModal> : content}
    </>
  )
}

export default Searchbox
