import { P } from '@sellpy/design-system-react-web'
import React, { useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import useClickOutside from '../../../../hooks/useClickOutside.js'
import SubLabel from '../../components/SubLabel.jsx'
import ExpandIcon from '../components/ExpandIcon.jsx'
import { Options } from '../components/Options.jsx'
import {
  Box,
  Dropdown,
  IconAndValueContainer,
  IconSizing,
  StyledTextInput,
  Wrapper
} from '../styles.js'
import { isEqual } from '../utils.js'

const SingleSelector = ({
  label,
  subLabel,
  options,
  disabled,
  name,
  control,
  required,
  validate,
  noResult,
  defaultValue,
  placeholder,
  className
}) => {
  const inputRef = useRef()
  const {
    field: { value, onChange, onBlur },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: { required, validate }
  })

  const [expanded, setExpanded] = useState(false)

  const wrapperRef = useRef(null)

  useClickOutside(wrapperRef, () => {
    expanded && onBlur()
    setExpanded(false)
  })

  const handleClick = () => {
    !disabled && setExpanded(!expanded)
    inputRef.current.focus()
  }

  const matchingOption = options.find((option) => isEqual(value, option.value))

  return (
    <Wrapper
      ref={wrapperRef}
      erroneous={error}
      disabled={disabled}
      expanded={expanded}
      className={className}
    >
      <P noMargin design='body3'>
        {label}
      </P>
      <Box
        design='body1'
        noMargin
        erroneous={error}
        disabled={disabled}
        onClick={handleClick}
        expanded={expanded}
      >
        <IconAndValueContainer>
          {matchingOption?.icon && <IconSizing>{matchingOption?.icon}</IconSizing>}
          <StyledTextInput
            disabled={disabled}
            noMargin
            type='text'
            onBlur={onBlur}
            readOnly
            defaultValue={defaultValue}
            design='body3'
            ref={inputRef}
            placeholder={
              placeholder ||
              matchingOption?.label ||
              (value !== null && typeof value === 'object' && value[name]) ||
              value
            }
          />
        </IconAndValueContainer>
        <ExpandIcon expanded={expanded} />
        {expanded && (
          <Dropdown>
            <Options
              options={options}
              onChange={onChange}
              value={value}
              inputRef={inputRef}
              noResult={!options.length && noResult}
            />
          </Dropdown>
        )}
      </Box>
      <SubLabel errors={{ [name]: error }} name={name} label={subLabel} disabled={disabled} />
    </Wrapper>
  )
}

export default SingleSelector
