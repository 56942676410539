import Parse from '../../client/lib/parse'
import { resultToMap } from '../lib/parseToImmutable'
import {
  analyticsBagRating,
  analyticsEditedItem,
  analyticsItemChangeRequested,
  analyticsPricedItem
} from '../analytics/actions'
import { cacheEntities } from '../entities/actions'
import {
  getSaleHistoryForOwnItem,
  getSaleFutureForOwnItem,
  getMarketOffers,
  getItemValue
} from '../items/actions'
import { TOAST_TYPE } from '../../client/uiComponents/notifications/Toasts.jsx'
import { showToast } from '../ui/actions'
import { locale } from '../region/locale'

const FETCH_CLAIMED_ITEMS_FROME_SALE_SUCCESS = 'FETCH_CLAIMED_ITEMS_FROME_SALE_SUCCESS'

export const fetchClaimedItemsBySale = (saleId) => (dispatch) => {
  return Parse.Cloud.run('fetchClaimsBySale', { saleId }).then((claims) => {
    dispatch(cacheEntities(claims))
    dispatch({ type: FETCH_CLAIMED_ITEMS_FROME_SALE_SUCCESS })
  })
}

const REQUEST_REEVALUATION_LOADING = 'REQUEST_REEVALUATION_LOADING'
const REQUEST_REEVALUATION_SUCCESS = 'REQUEST_REEVALUATION_SUCCESS'

export function requestReevaluation({ itemId, motivation, returnIfRejected, files }) {
  return (dispatch) => {
    dispatch({ type: REQUEST_REEVALUATION_LOADING, itemId })
    return Parse.Cloud.run('requestReevaluation', {
      itemId,
      motivation,
      returnIfRejected: returnIfRejected === 'true',
      files
    }).then((item) => {
      dispatch(cacheEntities(item))
      dispatch({ type: REQUEST_REEVALUATION_SUCCESS, itemId })
    })
  }
}

export function createItemChangeRequest(itemId, requestInfo) {
  return (dispatch) => {
    return Parse.Cloud.run('createItemChangeRequest', {
      itemId,
      requestInfo
    }).then((result) => {
      dispatch(cacheEntities(result))
      analyticsItemChangeRequested(itemId)
    })
  }
}

export const EDIT_ITEM_LOADING = 'EDIT_ITEM_LOADING'
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS'

export const editItem = (itemId, itemTypeId, metadata) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_ITEM_LOADING })
    return Parse.Cloud.run('customerItemEdit', {
      itemId,
      editInfo: {
        itemTypeId,
        metadata
      },
      sourceLanguage: locale
    })
      .then((item) => {
        dispatch(cacheEntities(item))
        item = resultToMap(item)
        dispatch({ type: EDIT_ITEM_SUCCESS, item })
        analyticsEditedItem(itemId)
        return item
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const PRICE_ITEM_LOADING = 'PRICE_ITEM_LOADING'
export const PRICE_ITEM_SUCCESS = 'PRICE_ITEM_SUCCESS'

export const priceItem = (itemId, value) => {
  return (dispatch) => {
    dispatch({ type: PRICE_ITEM_LOADING })
    return Parse.Cloud.run('customerItemPricing', { itemId, value })
      .then((item) => {
        dispatch(cacheEntities(item))
        dispatch(getMarketOffers(itemId))
        dispatch(getSaleHistoryForOwnItem(itemId))
        dispatch(getSaleFutureForOwnItem(itemId))
        dispatch({ type: PRICE_ITEM_SUCCESS })
        analyticsPricedItem(itemId, value)
        // It would probably make more sense to return the MarketInventory from customerItemPricing,
        // since we could just update the cache right away instead of another network request (getItemValue).
        // But it requires a new API and this is fine for now.
        dispatch(getItemValue(itemId, { cacheBust: true }))
        return resultToMap(item)
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const ADD_FREE_DURATION_LOADING = 'ADD_FREE_DURATION_LOADING'
export const ADD_FREE_DURATION_SUCCESS = 'ADD_FREE_DURATION_SUCCESS'

export const addFreeDuration = (itemId) => {
  return (dispatch) => {
    dispatch({ type: ADD_FREE_DURATION_LOADING })
    return Parse.Cloud.run('addFreeDuration', { itemId })
      .then((sellerItemPricingPeriod) => {
        dispatch(cacheEntities(sellerItemPricingPeriod))
        dispatch({ type: ADD_FREE_DURATION_SUCCESS })
      })
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }
}

export const RATE_BAG_LOADING = 'RATE_BAG_LOADING'
export const RATE_BAG_SUCCESS = 'RATE_BAG_SUCCESS'

export const rateBag = (bagId, t, { score, comment, improvementArea, type }) => {
  return async (dispatch) => {
    analyticsBagRating(score, comment, improvementArea)
    try {
      dispatch({ type: RATE_BAG_LOADING })
      const bagRating = await Parse.Cloud.run('rateBag', {
        bagId,
        score,
        comment,
        improvementArea,
        type
      })
      dispatch(cacheEntities(bagRating))
      dispatch({ type: RATE_BAG_SUCCESS })
      dispatch(
        showToast({
          header: t('rateSale.submitSuccess.header'),
          body: t('rateSale.submitSuccess.body'),
          type: TOAST_TYPE.SUCCESS
        })
      )
    } catch (error) {
      console.error(error)
      dispatch(
        showToast({
          header: t('errorToast.header'),
          body: t('errorToast.body'),
          type: TOAST_TYPE.ERROR
        })
      )
    }
  }
}

export const requestReturnItem = (item) => {
  return async (dispatch) =>
    Parse.Cloud.run('requestReturnBulkReject', { itemId: item.get('objectId') })
      .then((updatedItem) => dispatch(cacheEntities(updatedItem)))
      .catch(console.error)
}
