import React from 'react'
import styled from 'styled-components'
import preventScroll from 'prevent-scroll'
import { mediaQueries } from '../../uiComponents/mediaQueries'

const StyledBurger = styled.button`
  border: unset;
  background: unset;
  padding: 0;
  height: 24px;
  width: 24px;
  margin-right: 24px;
  z-index: 3000;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  ${mediaQueries.phone} {
    margin-right: 16px;
  }
`

const Lines = styled.span`
  width: 24px;
  height: 2px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.grey.shade2};
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  transform: ${({ order, isOpen }) => {
    if (order === 1) return isOpen ? 'rotate(45deg)' : 'rotate(0)'
    if (order === 2) return isOpen ? 'scale(0)' : 'scale(1)'
    if (order === 3) return isOpen ? 'rotate(-45deg)' : 'rotate(0)'
    else return 'none'
  }};
  opacity: ${({ order, isOpen }) => (order !== 2 ? '1' : isOpen ? '0' : '1')};
`

const Burger = ({ setIsOpen, isOpen, menuContentId }) => {
  return (
    <StyledBurger
      aria-haspopup='true'
      aria-label='Open menu'
      aria-expanded={isOpen ? 'true' : 'false'}
      aria-controls={menuContentId}
      tabIndex='0'
      role='button'
      onClick={() => {
        isOpen ? preventScroll.off() : preventScroll.on()
        setIsOpen(!isOpen)
      }}
    >
      <Lines order={1} isOpen={isOpen} />
      <Lines order={2} isOpen={isOpen} />
      <Lines order={3} isOpen={isOpen} />
    </StyledBurger>
  )
}

export default Burger
